import { Field } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, Title, FieldList } from "../../../components/ui";
import { CreditCardIcon } from "@heroicons/react/24/solid";

const BillingAndPaymentConditionsSection = ({
  billingInformation,
  setBillingInformation,
}) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <CreditCardIcon />
            <h3>Billing and Payment Conditions</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="billing_and_payment_conditions"
            label="Conditions"
            onChangeFn={(e) =>
              setBillingInformation({
                ...billingInformation,
                billing_and_payment_conditions: e.target.value,
              })
            }
            input={billingInformation?.billing_and_payment_conditions}
            placeholder="Only cash/credit please"
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { BillingAndPaymentConditionsSection };
