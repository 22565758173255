import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import {
  Bubble,
  BubbleHeader,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { getBankingDetailsApi } from "@/modules/registration/api/banking_details";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";

const BankingDetailsSection = ({ customerId }) => {
  const [bankingDetails, setBankingDetails] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBankingDetails() {
      setLoading(true);
      const bankingDetails = await getBankingDetailsApi(customerId);
      setBankingDetails(bankingDetails);
      setLoading(false);
    }
    getBankingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <Title>
          <BuildingLibraryIcon />
          <h2>Banking Details</h2>
        </Title>
        <Button href="/application/horeca/banking-details">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingLibraryIcon />
            <h3>Bank Details</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Name of Bank"
            value={bankingDetails?.name_of_bank}
          />
          <ReviewField label="Branch" value={bankingDetails?.branch} />
          <ReviewField label="A/C No." value={bankingDetails?.a_c_no} />
          <ReviewField label="Swift Code" value={bankingDetails?.swift_code} />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { BankingDetailsSection };
