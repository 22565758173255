import { UserIcon } from "@heroicons/react/24/solid";
import {
  Bubble,
  BubbleHeader,
  FieldList,
  ReviewField,
  Title,
} from "../../../../components/ui";
import { TwoColumn } from "../../../../components/layouts";

const PrimaryPaymentContact = ({ billingInformation }) => {
  return (
    <Bubble>
      <BubbleHeader>
        <Title>
          <UserIcon />
          <h3>Main contact for payment enquiries</h3>
        </Title>
      </BubbleHeader>
      <FieldList>
        <ReviewField
          label="Name"
          value={billingInformation?.payment_contact_1_name}
        />
        <TwoColumn>
          <ReviewField
            label="Telephone No."
            value={billingInformation?.payment_contact_1_phone_number}
          />
          <ReviewField
            label="Email"
            value={billingInformation?.payment_contact_1_email}
          />
        </TwoColumn>
      </FieldList>
    </Bubble>
  );
};

export { PrimaryPaymentContact };
