import { UserProfilePlaceholder } from "..";
import "./user-profile-placeholder-large.css";

const UserProfilePlaceholderLarge = () => {
  return (
    <div className="user-profile-placeholder-large-wrapper">
      <UserProfilePlaceholder />
    </div>
  );
};

export { UserProfilePlaceholderLarge };
