import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import "./checkout-summary.css";

function CheckoutSummary({ cartItems }) {
  const getOrderPrice = (cartItems) => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item?.STOCKVALUE * item?.quantity;
    });
    return total;
  };
  return (
    <div className="checkout-summary">
      <div className="subtotal">
        <span>Subtotal:</span>
        <span>{formatPriceWithCurrency(getOrderPrice(cartItems))}</span>
      </div>
      <hr className="price-divider" />
      <div className="misc">
        <div className="secondary">
          <span>Shipping:</span>
          <span>Free</span>
        </div>
        <div className="secondary">
          <span>Taxes:</span>
          <span>Free</span>
        </div>
      </div>
      <hr className="price-divider" />
      <div className="primary">
        <span className="total-due">Total:</span>
        <span>{formatPriceWithCurrency(getOrderPrice(cartItems))}</span>
      </div>
    </div>
  );
}

export { CheckoutSummary };
