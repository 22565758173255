import "./index.css";

const AdminPill = ({ isAdmin }) => {
  return isAdmin ? (
    <span className="pill admin">Admin</span>
  ) : (
    <span className="pill user">User</span>
  );
};

export { AdminPill };
