import { Field } from "../../../../common";

function OutletInformation({ outletInformation }) {
  return (
    <section>
      <h3>{outletInformation?.outlet_name}</h3>
      <div className="address">
        <h4>Address</h4>
        <Field
          label="Address Line 1"
          value={outletInformation?.address_line_1}
        />
        <Field
          label="Address Line 2"
          value={outletInformation?.address_line_2}
        />
        <Field label="Postal Code" value={outletInformation?.postal_code} />
        <Field label="City" value={outletInformation?.city} />
        <Field label="State" value={outletInformation?.state} />
        <Field label="Country" value={outletInformation?.country} />
      </div>
      <div className="contact">
        <h4>Contact Details</h4>
        <Field
          label="Person in Charge"
          value={outletInformation?.person_in_charge}
        />
        <Field label="Phone Number" value={outletInformation?.phone_number} />
      </div>
      <div className="type">
        <h4>Business Type</h4>
        <Field
          label="Nature of Business"
          value={outletInformation?.nature_of_business}
        />
        <Field label="Specification" value={outletInformation?.specification} />
      </div>
    </section>
  );
}

export { OutletInformation };
