import { useState, useEffect } from "react";
import { Button } from "@/modules/core/components/ui";
import { HomeModernIcon } from "@heroicons/react/24/solid";
import { RectangleStackIcon } from "@heroicons/react/24/solid";
import { Address } from "@/modules/customer/pages/checkout-page/shipping-address-section/address";
import "./address-selector.css";

const AddressCard = ({
  icon,
  selectedAddressId,
  setSelectedAddressId,
  address,
  setCurrentAddress,
}) => {
  const isSelected = address?.address_id === selectedAddressId;
  const radioClassName = `radio-btn ${isSelected ? "selected" : ""}`;
  const handleAddressClick = () => {
    setSelectedAddressId(address?.address_id);
    setCurrentAddress(address);
  };
  return (
    <div className="address-card">
      <div className={radioClassName} />
      <div
        className={`address-wrapper ${isSelected ? "selected" : ""}`}
        onClick={() => handleAddressClick()}
      >
        <Address
          icon={icon}
          {...address}
          isSelected={isSelected}
          setSelectedAddressId={setSelectedAddressId}
        />
      </div>
    </div>
  );
};
const AddressSelector = ({
  onManageAddressesClick,
  onBackClick,
  onSaveClick,
  onClick,
  addresses,
}) => {
  const [selectedAddressId, setSelectedAddressId] = useState(0);
  const [currentAddress, setCurrentAddress] = useState(null);
  useEffect(() => {
    const setData = () => {
      setSelectedAddressId(addresses[0]?.address_id);
      setCurrentAddress(addresses[0]);
    };
    setData();
  }, [addresses]);

  return (
    <div className="address-selector" onClick={onClick}>
      <div className="header">
        <h2 className="title">Select an address</h2>
        <Button onClick={onManageAddressesClick}>
          <RectangleStackIcon />
          Manage Addresses
        </Button>
      </div>
      <div className="address-list">
        {addresses?.map((address) => (
          <AddressCard
            icon={<HomeModernIcon />}
            selectedAddressId={selectedAddressId}
            setSelectedAddressId={setSelectedAddressId}
            address={address}
            setCurrentAddress={setCurrentAddress}
          />
        ))}
      </div>
      <div className="footer">
        <div className="action-buttons">
          <Button className="back-btn" wide onClick={onBackClick}>
            Back
          </Button>
          <Button
            className="save-btn"
            color="green"
            wide
            onClick={() => onSaveClick(currentAddress)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddressSelector };
