import { supabase } from "@/modules/supabase";

const getDeclarationApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_declaration")
    .select()
    .match({ user_id });
  console.log("get forms_declaration api", data);
  console.log("get forms_declaration api error", error);
  return data?.[0];
};

const insertDeclarationApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_declaration")
    .upsert(keys)
    .select();
  console.log("insert forms_declaration api", data);
  console.log("insert forms_declaration api error", error);
  return data?.[0];
};

export { getDeclarationApi, insertDeclarationApi };
