import { Notification } from "./notification";
import { Divider } from "./divider";
import { Loader } from "./loader";

import "./index.css";

const NotificationCenter = ({ isLoading, notifications }) => {
  return (
    <div className="notification-center">
      <div className="header">
        <h2 className="title">Notification Center</h2>
      </div>
      <div className="list">
        {isLoading ? (
          <Loader />
        ) : (
          notifications?.map((notification, idx) => (
            <>
              <Notification key={idx} {...notification} />
              {idx !== notifications.length - 1 && <Divider />}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export { NotificationCenter };
