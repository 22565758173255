import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import {
  Bubble,
  BubbleHeader,
  Divider,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import { getBusinessRegistrationApi } from "@/modules/registration/api/business_registration";
import { BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { PhoneIcon } from "@heroicons/react/24/solid";

const BusinessRegistrationSection = ({ customerId }) => {
  const [businessRegistration, setBusinessRegistration] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBusinessRegistration() {
      setLoading(true);
      const businessRegistration = await getBusinessRegistrationApi(customerId);
      setBusinessRegistration(businessRegistration);
      setLoading(false);
    }
    getBusinessRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <Title>
          <BuildingOffice2Icon />
          <h2>Business Registration</h2>
        </Title>
        <Button href="/application/horeca/business-registration">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Info</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Business Name"
            value={businessRegistration?.business_name}
          />
          <TwoColumn>
            <ReviewField
              label="Email Address"
              value={businessRegistration?.business_email}
            />
            <ReviewField
              label="Website"
              value={businessRegistration?.website}
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Address</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Address Line 1"
            value={businessRegistration?.address_line_1}
          />
          <ReviewField
            label="Address Line 2"
            value={businessRegistration?.address_line_2}
          />
          <TwoColumn>
            <ReviewField
              label="Postal Code"
              value={businessRegistration?.postal_code}
            />
            <ReviewField label="City" value={businessRegistration?.city} />
          </TwoColumn>
          <TwoColumn>
            <ReviewField label="State" value={businessRegistration?.state} />
            <ReviewField
              label="Country"
              value={businessRegistration?.country}
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Details</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Type of Business"
            value={businessRegistration?.type_of_business}
          />
          <ReviewField
            label="Business Registration No. #"
            value={businessRegistration?.business_registration_number}
          />
          <TwoColumn>
            <ReviewField
              label="Date of Incorporation"
              value={businessRegistration?.date_incorporated}
            />
            <ReviewField
              label="GST Registration No. #"
              value={businessRegistration?.gst_registration_number}
            />
          </TwoColumn>
          <TwoColumn>
            <ReviewField
              label="Authorised Capital in RM"
              value={businessRegistration?.authorised_capital}
            />
            <ReviewField
              label="Paid Up Capital in RM"
              value={businessRegistration?.paid_up_capital}
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <PhoneIcon />
            <h3>Contact Info</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Name of M.D or G.M"
            value={businessRegistration?.name_of_md_or_gm}
          />
          <ReviewField
            label="Name of Purchasing Manager"
            value={businessRegistration?.name_of_purchasing_manager}
          />
          <ReviewField
            label="Email Address"
            value={businessRegistration?.email_of_md_or_gm}
          />
          <TwoColumn>
            <ReviewField
              label="Fax Number"
              value={businessRegistration?.fax_of_md_or_gm}
            />
            <ReviewField
              label="Telephone Number"
              value={businessRegistration?.phone_number_of_md_or_gm}
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { BusinessRegistrationSection };
