import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getBusinessRegistrationApi } from "@/modules/registration/api/business_registration";
import { Field } from "../../../common";

const BusinessRegistration = ({ customerId }) => {
  const [businessRegistration, setBusinessRegistration] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBusinessRegistration() {
      setLoading(true);
      const businessRegistration = await getBusinessRegistrationApi(customerId);
      setBusinessRegistration(businessRegistration);
      setLoading(false);
    }
    getBusinessRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Business Registration</h2>
      <h3>{businessRegistration?.business_name}</h3>
      <div className="address">
        <h4>Address</h4>
        <Field
          label="Address Line 1"
          value={businessRegistration?.address_line_1}
        />
        <Field
          label="Address Line 2"
          value={businessRegistration?.address_line_2}
        />
        <Field label="Postal Code" value={businessRegistration?.postal_code} />
        <Field label="City" value={businessRegistration?.city} />
        <Field label="State" value={businessRegistration?.state} />
        <Field label="Country" value={businessRegistration?.country} />
      </div>
      <div className="contact">
        <h4>Contact Details</h4>
        <Field label="Email" value={businessRegistration?.business_email} />
        <Field label="Website" value={businessRegistration?.website} />
      </div>
      <div className="type">
        <h4>Business Type</h4>
        <Field
          label="Nature of Business"
          value={businessRegistration?.type_of_business}
        />
      </div>
      <div className="details">
        <h4>Business Details</h4>
        <Field
          label="Business Registration Number"
          value={businessRegistration?.business_registration_number}
        />
        <Field
          label="Date of Incorporation"
          value={businessRegistration?.date_incorporated}
        />
        <Field
          label="GST Registration Number"
          value={businessRegistration?.gst_registration_number}
        />
        <Field
          label="Authorised Capital (RM)"
          value={businessRegistration?.authorised_capital}
        />
        <Field
          label="Paid Up Capital (RM)"
          value={businessRegistration?.paid_up_capital}
        />
      </div>
      <div className="contact">
        <Field
          label="Name of Managing Director or General Manager"
          value={businessRegistration?.name_of_md_or_gm}
        />
        <Field
          label="Name of Purchasing Manager"
          value={businessRegistration?.name_of_purchasing_manager}
        />
        <Field
          label="M.D. or G.M. Email"
          value={businessRegistration?.email_of_md_or_gm}
        />
        <Field
          label="M.D. or G.M. Fax"
          value={businessRegistration?.fax_of_md_or_gm}
        />
        <Field
          label="M.D. or G.M. Phone Number"
          value={businessRegistration?.phone_number_of_md_or_gm}
        />
      </div>
    </section>
  );
};

export { BusinessRegistration };
