import { Link } from "react-router-dom";

function getPath(session, profile) {
  let path = "/";
  if (!session) return path;
  if (session && profile?.status === "approved") path = "/home";
  if (session && profile?.status !== "approved") path = "/application";
  return path;
}

function NoMatchPage({ session, profile }) {
  return (
    <div>
      <h2>Nothing found</h2>
      <p>
        <Link to={getPath(session, profile)}>Return to home page</Link>
      </p>
    </div>
  );
}

export { NoMatchPage };
