import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getDataProtectionApi } from "@/modules/registration/api/data_protection";
import { formatDateTime } from "@/modules/core/utils/time";

const DataProtection = ({ customerId }) => {
  const [dataProtection, setDataProtection] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getDataProtection() {
      setLoading(true);
      const dataProtection = await getDataProtectionApi(customerId);
      setDataProtection(dataProtection);
      setLoading(false);
    }
    getDataProtection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2 className="indent">Data Protection</h2>
      <h3>{dataProtection?.is_signed ? "Signed" : "Not Signed"}</h3>
      <p>
        {dataProtection?.is_signed
          ? `Signed on ${formatDateTime(dataProtection?.date_signed)}`
          : "Not Signed"}
      </p>
    </section>
  );
};

export { DataProtection };
