import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, Divider, FormFooter, Title } from "../../../components/ui";
import { Supplier } from "../supplier";
import { insertReferenceInformationApi } from "@/modules/registration/api/reference_information";
import { UsersIcon } from "@heroicons/react/24/solid";

const KEYS = [
  "supplier_1_name",
  "supplier_1_tel",
  "supplier_2_name",
  "supplier_2_tel",
  "supplier_3_name",
  "supplier_3_tel",
];
function ReferenceInformationForm({
  previousPage,
  nextPage,
  referenceInformation,
  setReferenceInformation,
  profile,
}) {
  const navigate = useNavigate();
  const [isSaving, setSaving] = useState(false);
  function onBackClick() {
    navigate(previousPage);
  }
  function handleSubmit(event) {
    event.preventDefault();

    let map = {};
    KEYS.forEach((e) => (map[e] = event.target[e].value));
    map["user_id"] = profile?.id;
    console.log("map", map);

    async function insertReferenceInformation(map) {
      setSaving(true);
      await insertReferenceInformationApi(map);
      setTimeout(() => {
        setSaving(false);
        navigate(nextPage);
      }, 800);
    }
    insertReferenceInformation(map);
  }
  return (
    <form onSubmit={handleSubmit}>
      <Blockquote>Please list some suppliers that you currently use</Blockquote>
      <Divider />
      <Title>
        <UsersIcon />
        <h2>Suppliers</h2>
      </Title>
      <Divider />
      <Supplier
        name_id="supplier_1_name"
        name_label="Name"
        name_onChange={(e) =>
          setReferenceInformation({
            ...referenceInformation,
            supplier_1_name: e.target.value,
          })
        }
        name_input={referenceInformation?.supplier_1_name}
        name_placeholder="Vendor Name"
        tel_id="supplier_1_tel"
        tel_label="Telephone No."
        tel_onChange={(value) =>
          setReferenceInformation({
            ...referenceInformation,
            supplier_1_tel: value,
          })
        }
        tel_value={referenceInformation?.supplier_1_tel}
        required
      />
      <Divider />
      <Supplier
        name_id="supplier_2_name"
        name_label="Name"
        name_onChange={(e) =>
          setReferenceInformation({
            ...referenceInformation,
            supplier_2_name: e.target.value,
          })
        }
        name_input={referenceInformation?.supplier_2_name}
        name_placeholder="Vendor Name"
        tel_id="supplier_2_tel"
        tel_label="Telephone No."
        tel_onChange={(value) =>
          setReferenceInformation({
            ...referenceInformation,
            supplier_2_tel: value,
          })
        }
        tel_value={referenceInformation?.supplier_2_tel}
      />
      <Divider />
      <Supplier
        name_id="supplier_3_name"
        name_label="Name"
        name_onChange={(e) =>
          setReferenceInformation({
            ...referenceInformation,
            supplier_3_name: e.target.value,
          })
        }
        name_input={referenceInformation?.supplier_3_name}
        name_placeholder="Vendor Name"
        tel_id="supplier_3_tel"
        tel_label="Telephone No."
        tel_onChange={(value) =>
          setReferenceInformation({
            ...referenceInformation,
            supplier_3_tel: value,
          })
        }
        tel_value={referenceInformation?.supplier_3_tel}
      />
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
}

export { ReferenceInformationForm };
