import { Outlet } from "react-router-dom";
import NavigationBar from "./navigation-bar";
import "./layout.css";

const PAGES = [
  {
    id: 0,
    href: "/application/introduction",
    text: "Introduction",
  },
  {
    id: 1,
    href: "/application/horeca/business-registration",
    text: "Business Registration",
  },
  {
    id: "1a",
    href: "/application/horeca/business-registration-upload-forms",
    text: "Upload Forms",
    leftPadding: true,
  },
  {
    id: 2,
    href: "/application/horeca/banking-details",
    text: "Banking Details",
  },
  {
    id: 3,
    href: "/application/horeca/billing-information",
    text: "Billing Information",
  },
  {
    id: 4,
    href: "/application/horeca/reference-information",
    text: "Reference Information",
  },
  {
    id: 5,
    href: "/application/horeca/outlet-information",
    text: "Outlet Information",
  },
  {
    id: 6,
    href: "/application/horeca/terms-and-conditions",
    text: "Terms and Conditions",
  },
  {
    id: 7,
    href: "/application/horeca/data-protection",
    text: "Data Protection",
  },
  {
    id: 8,
    href: "/application/horeca/declaration",
    text: "Declaration",
  },
  {
    id: 9,
    href: "/application/horeca/review",
    text: "Review",
  },
];

const HorecaRegistrationLayout = () => {
  return (
    <div className="application-layout-body">
      <NavigationBar pages={PAGES} />
      <Outlet />
    </div>
  );
};

export { HorecaRegistrationLayout };
