import "./index.css";

const AnalyticsWidget = ({ title, description, dateRange, bgColor }) => {
  let className = "analytics-widget";
  if (bgColor) className += ` ${bgColor}`;
  return (
    <div className={className}>
      <div className="header">
        <div className="title">{title}</div>
        <div className="date-range">{dateRange}</div>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export { AnalyticsWidget };
