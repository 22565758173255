import { supabase } from "@/modules/supabase";

const hasExistingRecordsInTables = async (user_id, applicationTables) => {
  const doesRecordExist = async (user_id, table) => {
    const { data } = await supabase.from(table).select().eq("user_id", user_id);
    if (data.length > 0) {
      return true;
    }
    return false;
  };
  const promises = applicationTables.map((table) =>
    doesRecordExist(user_id, table)
  );
  const results = await Promise.all(promises);
  return results.some((res) => res);
};

const hasExistingInitiatedApplication = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_applications")
    .select()
    .eq("user_id", user_id)
    .eq("status", "initiated");

  if (data.length > 0) {
    console.log(
      "User does have an existing application under initiation",
      data
    );
    return true;
  } else {
    console.log(
      "User does not have an existing application under initiation",
      error
    );
    return false;
  }
};

export { hasExistingRecordsInTables, hasExistingInitiatedApplication };
