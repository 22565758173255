import { Field, PhoneNumberField } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { UserIcon } from "@heroicons/react/24/solid";
import { TwoColumn } from "../../../components/layouts";

const PrimaryPaymentContactSection = ({
  billingInformation,
  setBillingInformation,
}) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <UserIcon />
            <h3>Main contact for payment enquiries</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="payment_contact_1_name"
            label="Name"
            onChangeFn={(e) =>
              setBillingInformation({
                ...billingInformation,
                payment_contact_1_name: e.target.value,
              })
            }
            input={billingInformation?.payment_contact_1_name}
            placeholder="Payment Contact 1"
            required
          />
          <TwoColumn>
            <PhoneNumberField
              label="Telephone No."
              name="payment_contact_1_phone_number"
              onChange={(value) =>
                setBillingInformation({
                  ...billingInformation,
                  payment_contact_1_phone_number: value,
                })
              }
              value={billingInformation?.payment_contact_1_phone_number}
              required
            />
            <Field
              type="email"
              name="payment_contact_1_email"
              label="Email"
              onChangeFn={(e) =>
                setBillingInformation({
                  ...billingInformation,
                  payment_contact_1_email: e.target.value,
                })
              }
              input={billingInformation?.payment_contact_1_email}
              placeholder="name1@gmail.com"
              required
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { PrimaryPaymentContactSection };
