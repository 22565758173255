import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCcVisa } from "react-icons/fa";
import { BiReceipt as ReceiptIcon } from "react-icons/bi";
import { BanknotesIcon } from "@heroicons/react/24/solid";
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
  PaymentStatus,
} from "@/modules/core/components/ui";
import { ErrorBoundary } from "@/modules/core/components/gadgets";
import {
  EGHLForm,
  FORM_VALUES,
} from "@/modules/core/components/gadgets/e-ghl-form";
import { CheckoutList } from "@/modules/core/components/gadgets/checkout-list";
import { CheckoutSummary } from "@/modules/core/components/gadgets/checkout-summary";
import { getOrderApi } from "@/modules/core/api/order";
import { getPaymentsApi } from "@/modules/core/api/payment";
import formatPriceWithCurrency, {
  formatPrice,
} from "@/modules/core/utils/formatPriceWithCurrency";
import { formatDateTime } from "@/modules/core/utils/time";
import { getOneShippingAddressByAddressId } from "@/modules/core/api/shipping_addresses";
import "./order-page.css";

function formatOrderNumber(order_number) {
  return `#IVP-${order_number.padStart(5, "0")}`;
}
function getTotalPrice(order) {
  const sum = order.reduce((acc, curr) => {
    acc += Number(curr?.STOCKVALUE);
    return acc;
  }, 0);
  return sum;
}
export function OrderDetails({ order, session }) {
  const [showForm, setForm] = useState(false);
  function handlePayClick() {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to pay ${formatPriceWithCurrency(getTotalPrice(order))} for ${order?.[0]?.invoice_number}?`
      )
    ) {
      setForm(true);
    }
  }
  return (
    <article>
      <div className="order-details-title">
        <h2>Order Details</h2>
        <PaymentStatus paymentStatus={order?.[0]?.payment_status} />
        {order?.[0]?.payment_status === "pending-payment" &&
          (showForm ? (
            <EGHLForm
              {...FORM_VALUES}
              Amount={formatPrice(getTotalPrice(order))}
              orders={[order?.[0]?.order_number]}
              paymentType="credit"
              session={session}
            />
          ) : (
            <div className="pay-button" onClick={handlePayClick}>
              <div className="pay-icon">
                <BanknotesIcon />
              </div>
              Pay Now
            </div>
          ))}
      </div>
      <div className="order-details">
        <CheckoutList cartItems={order || []} type="checkout" />
        <div style={{ margin: "25px 0" }}></div>
        <CheckoutSummary cartItems={order || []} />
      </div>
    </article>
  );
}

export function PaymentSummary({ cart_id, shipping_address_id }) {
  const [payment, setPayment] = useState(null);
  const [orderLocation, setOrderLocation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getData() {
      setLoading(true);
      const payments = await getPaymentsApi(cart_id);
      const successful_payment = payments?.find((payment) =>
        payment?.transaction_string?.includes(`"TxnStatus":"0"`)
      );
      setPayment(successful_payment);
      const orderLocation =
        await getOneShippingAddressByAddressId(shipping_address_id);
      setOrderLocation(orderLocation);
      setLoading(false);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_id, shipping_address_id]);

  function Payment({ payment }) {
    const params = payment ? JSON.parse(payment?.transaction_string) : {};
    function TransactionDetails() {
      const getTransactionColor = (message) => {
        switch (message?.toLowerCase()) {
          case "transaction successful":
            return "transaction-success";
          default:
            return "";
        }
      };
      return (
        <div className="transaction-details">
          <h3>Transaction Details</h3>
          <div>
            <div>
              <span className={getTransactionColor(params["TxnMessage"])}>
                {params["TxnMessage"]}
              </span>
            </div>
            <span>
              {params["TransactionType"]}, {params["PymtMethod"]}
            </span>
            <div>
              <span>{formatDateTime(params["RespTime"])}</span>
            </div>
          </div>
        </div>
      );
    }
    function CardDetails() {
      return (
        <div className="card-details">
          <h3>Card Details</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <b>{params["CardHolder"]}</b>
            <span>{params["CardNoMask"]}</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {params["CardType"] === "VISA" ? (
                  <FaCcVisa size={24} color="hsl(240, 50%, 30%)" />
                ) : (
                  params["CardType"]
                )}
              </span>
              <span style={{ marginLeft: 15 }}></span>
              <span>{params["CardExp"]}</span>
            </div>
          </div>
        </div>
      );
    }
    function ShippingDetails() {
      return (
        <div className="order-details">
          <h3>Shipping Details</h3>
          <p>{orderLocation?.address_line_1}</p>
          {orderLocation?.address_line_2 && (
            <p>{orderLocation?.address_line_2}</p>
          )}
          {orderLocation?.postal_code && orderLocation?.city && (
            <p>{`${orderLocation?.postal_code}, ${orderLocation?.city}`}</p>
          )}
          <p>{orderLocation?.country}</p>
          <div style={{ marginTop: "8px" }}>
            <span>Total: </span>
            <span>
              <b>
                {params["CurrencyCode"]} {params["Amount"]}
              </b>
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="payment-summary">
        <TransactionDetails />
        <CardDetails />
        <ShippingDetails />
      </div>
    );
  }
  return (
    <article className="payment-summary">
      <h2>Payment Summary</h2>
      {isLoading ? (
        "Loading..."
      ) : (
        <ErrorBoundary message="Payment summary is currently unavailable.">
          <Payment payment={payment} />
        </ErrorBoundary>
      )}
    </article>
  );
}

const OrderPage = ({ session }) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getOrder = async () => {
      setLoading(true);
      const order = await getOrderApi(orderId);
      setOrder(order);
      setLoading(false);
    };
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      pathname: "/orders",
      text: "Orders",
      type: "link",
    },
    {
      text: `Order ${formatOrderNumber(orderId)}`,
      type: "text",
    },
  ];
  return (
    <div className="order-page">
      <div className="order-page-header">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1 className="order-page-header-title">
          Order {formatOrderNumber(orderId)}
        </h1>
        <Button href={`/print-invoice/${orderId}`}>
          <div className="view-invoice-btn">
            <div className="receipt-icon">
              <ReceiptIcon />
            </div>
            <span>View/Print Invoice</span>
          </div>
        </Button>
      </div>
      <div className="order-page-body">
        {isLoading ? (
          <LoadingIndicator center />
        ) : (
          <>
            <OrderDetails order={order} session={session} />
            <hr className="divider" />
            <PaymentSummary
              cart_id={order?.[0]?.cart_id}
              shipping_address_id={order?.[0]?.shipping_address_id}
            />
          </>
        )}
      </div>
    </div>
  );
};

export { OrderPage };
