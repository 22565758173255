import { useState, useEffect } from "react";
import { LoadingIndicator, Breadcrumbs } from "@/modules/core/components/ui";
import { getProfilesViewApi } from "@/modules/core/api/users";
import { SearchBar } from "./search-bar";
import { Table } from "./manage-applications-table";
import { Lists } from "./manage-applications-list";
import { cleanUsers, sortUsers, deleteUser } from "./helpers";
import config from "./config.json";
import "./manage-applications-page.css";

const ManageApplicationsPage = () => {
  const [originalUsers, setOriginalUsers] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState(undefined);
  async function getProfiles() {
    setLoading(true);
    const originalUsers = await getProfilesViewApi();
    const formattedUsers = cleanUsers(originalUsers);
    const sortedUsers = sortUsers(formattedUsers);
    setOriginalUsers(sortedUsers);
    setUsers(sortedUsers);
    setLoading(false);
  }
  useEffect(() => {
    getProfiles();
  }, []);
  const { breadcrumbs } = config;
  return (
    <div className="manage-applications-page">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Manage Applications</h1>
      {isLoading ? (
        <LoadingIndicator center />
      ) : (
        <div className="content">
          <SearchBar originalUsers={originalUsers} setUsers={setUsers} />
          <Lists
            users={users}
            deleteUser={deleteUser}
            onDeleteSuccess={getProfiles}
          />
          <Table
            users={users}
            deleteUser={deleteUser}
            onDeleteSuccess={getProfiles}
          />
        </div>
      )}
    </div>
  );
};

export { ManageApplicationsPage };
