import { supabase } from "@/modules/supabase";

const upsertImageToStorage = async (bucketName, fileName, file) => {
  const { error } = await supabase.storage
    .from(bucketName)
    .upload(fileName, file, { upsert: true });
  return { error };
};

const createSignedUrl = async (bucketName, path) => {
  const { data, error } = await supabase.storage
    .from(bucketName)
    .createSignedUrl(path, 60);
  return { data, error };
};

const downloadImageFromStorage = async (bucketName, path, options) => {
  const { data, error } = await supabase.storage
    .from(bucketName)
    .download(path, options);
  return { data, error };
};

export { upsertImageToStorage, createSignedUrl, downloadImageFromStorage };
