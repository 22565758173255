import "./index.css";

const formatStatus = (status) => {
  switch (status) {
    case "pending-review":
      return "Pending Review";
    case "pending-submission":
      return "Pending Submission";
    default:
      return status;
  }
};

const StatusPill = ({ status }) => {
  return (
    <div className={`status-pill uppercase pill ${status}`}>
      {formatStatus(status)}
    </div>
  );
};

export { StatusPill };
