import { supabase } from "@/modules/supabase";

const getReferenceInformationApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_reference_information")
    .select()
    .match({ user_id });
  console.log("get forms_reference_information api", data);
  console.log("get forms_reference_information api error", error);
  return data?.[0];
};

const insertReferenceInformationApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_reference_information")
    .upsert(keys)
    .select();
  console.log("insert forms_reference_information api", data);
  console.log("insert forms_reference_information api error", error);
  return data?.[0];
};

export { getReferenceInformationApi, insertReferenceInformationApi };
