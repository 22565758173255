const formatPriceWithCurrency = (price) => {
  if (!price) return "-";
  return `RM ${Number(price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const formatPrice = (price) => {
  if (!price) return "-";
  return `${Number(price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`.replaceAll(
    ",",
    ""
  );
};

export { formatPrice };
export default formatPriceWithCurrency;
