const config = {
  breadcrumbs: [
    {
      text: "Admin Dashboard",
      type: "text",
    },
  ],
  analytics: {
    widgets: [
      {
        title: "Total Sales",
        description: "RM 56,000",
        dateRange: "over 7 days",
        bgColor: "blue",
      },
      {
        title: "Total Leads",
        description: "5 people",
        dateRange: "over 7 days",
        bgColor: "red",
      },
      {
        title: "Total Users",
        description: "46 people",
        dateRange: "over 7 days",
      },
    ],
  },
  notifications: [
    {
      title: "#IVP-00096 has been created",
      description:
        "An order has been created for customer julianleong@gmail.com",
      time: "17:46",
    },
    {
      title: "#IVP-00025 has been dispatched",
      description:
        "A shipping service has been dispatched for customer tristanng@gmail.com",
      time: "17:34",
    },
    {
      title: "#IVP-00051 has been created",
      description: "An order has been created for customer marktohtk@gmail.com",
      time: "17:27",
    },
  ],
};

export default config;
