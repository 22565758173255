import { memo } from "react";
import { HeroSection } from "./hero-section";
import { CatalogueSection } from "./catalogue-section";
import { FeaturesSection } from "./features-section";
import { ContactUsSection } from "./contact-us-section";
import "./landing-page.css";

function Container({ children }) {
  return <div className="landing-page">{children}</div>;
}

function Page() {
  return (
    <Container>
      <HeroSection />
      <CatalogueSection />
      <FeaturesSection />
      <ContactUsSection />
    </Container>
  );
}

const LandingPage = memo(Page);

export { LandingPage };
