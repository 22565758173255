function CheckboxList({ title, checkboxes, filterState, filterTrigger }) {
  function handleChange(e) {
    console.log("trigger", e.target.value);
    console.log("filterState", filterState);
    const newState = [...filterState];
    newState.forEach((category) => {
      if (category.name === e.target.value) {
        category.value = !category.value;
      }
    });
    filterTrigger(newState);
  }
  return (
    <div className="filter-list">
      <h3 className="filter-list-title">{title}</h3>
      <div className="filter-list-checkboxes">
        {checkboxes?.map((checkbox) => (
          <div className="filter-list-checkbox" key={checkbox.id}>
            <input
              type="checkbox"
              name={checkbox.name}
              id={checkbox.id}
              value={checkbox.value}
              onChange={handleChange}
              checked={filterState.find((e) => e.name === checkbox.value).value}
            />
            <label htmlFor={checkbox.id}>{checkbox.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export { CheckboxList };
