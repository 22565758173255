import { UserIcon } from "@heroicons/react/24/solid";
import {
  Bubble,
  BubbleHeader,
  FieldList,
  ReviewField,
  Title,
} from "../../../../components/ui";
import { TwoColumn } from "../../../../components/layouts";

const SecondaryBillingContact = ({ billingInformation }) => {
  return (
    <Bubble>
      <BubbleHeader>
        <Title>
          <UserIcon />
          <h3>Secondary contact for billing enquiries</h3>
        </Title>
      </BubbleHeader>
      <FieldList>
        <ReviewField
          label="Name"
          value={billingInformation?.billing_contact_2_name}
        />
        <TwoColumn>
          <ReviewField
            label="Telephone No."
            value={billingInformation?.billing_contact_2_phone_number}
          />
          <ReviewField
            label="Email"
            value={billingInformation?.billing_contact_2_email}
          />
        </TwoColumn>
      </FieldList>
    </Bubble>
  );
};

export { SecondaryBillingContact };
