import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { BuildingStorefrontIcon } from "@heroicons/react/24/solid";
import { HomeIcon, PlusIcon } from "@heroicons/react/24/solid";
import { AddressSelector } from "@/modules/core/components/gadgets/address-selector";
import { Overlay, Button } from "@/modules/core/components/ui";
import { Address } from "./address";
import { DeliveryOptions } from "./delivery-options";
import { getAllUserShippingAddressesByUserId } from "@/modules/core/api/shipping_addresses";
import { LOTUS_OFFICE_ADDRESS } from "@/modules/core/utils/constants";

import "./shipping-address-section.css";

const PickUp = () => {
  return (
    <div>
      <p>
        Please visit our Lotus Consulting Group Office at the following address:{" "}
      </p>
      <Address
        {...LOTUS_OFFICE_ADDRESS}
        icon={<BuildingStorefrontIcon />}
        bgColor="red"
      />
    </div>
  );
};
const ShippingAddressSection = ({ session, setSavedAddressId }) => {
  const options = [
    {
      id: "delivery",
      icon: <HomeIcon />,
      label: "Delivery",
    },
    {
      id: "pick-up",
      icon: <BuildingStorefrontIcon />,
      label: "Pick up",
    },
  ];
  const [isSelected, setIsSelected] = useState("delivery");
  const [showAddressSelector, setShowAddressSelector] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const getAddresses = async () => {
      const data = await getAllUserShippingAddressesByUserId(session?.user.id);
      if (data) {
        setAddresses(data);
        setCurrentAddress(data[0]);
        setSavedAddressId(data[0]?.address_id);
      }
    };
    getAddresses();
  }, [session, setSavedAddressId]);
  const handleOnSaveClick = (savedAddress) => {
    setCurrentAddress(savedAddress);
    setSavedAddressId(savedAddress?.address_id);
    setShowAddressSelector(false);
  };
  return (
    <section>
      <DeliveryOptions
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        options={options}
      />
      <div>
        {isSelected === "delivery" ? (
          <div className="delivery-details">
            <h3 className="delivery-option-title">Delivery</h3>
            {currentAddress ? (
              <>
                <p className="delivery-description">
                  Please select a shipping address:
                  <Button onClick={() => setShowAddressSelector(true)}>
                    <PencilSquareIcon />
                    Change Address
                  </Button>
                </p>
                <Address
                  {...currentAddress}
                  icon={<HomeIcon />}
                  bgColor="blue"
                />
                {showAddressSelector && (
                  <Overlay onClick={() => setShowAddressSelector(false)}>
                    <AddressSelector
                      onManageAddressesClick={() => navigate("/addresses")}
                      onBackClick={() => setShowAddressSelector(false)}
                      onSaveClick={handleOnSaveClick}
                      onClick={(e) => e.stopPropagation()}
                      addresses={addresses}
                    />
                  </Overlay>
                )}
              </>
            ) : (
              <>
                <div className="no-addresses-section">
                  <div className="text-container">
                    <blockquote>
                      <p>
                        It looks like you don't have any saved delivery
                        addresses yet.
                      </p>
                      <p>
                        Create a preferred delivery address to ensure your order
                        reaches without any issues.
                      </p>
                    </blockquote>
                  </div>
                  <Button href="/addresses/add">
                    <PlusIcon /> Add Address
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            <h3 className="delivery-option-title">Pick Up</h3>
            <PickUp />
          </div>
        )}
      </div>
    </section>
  );
};

export { ShippingAddressSection };
