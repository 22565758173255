import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getBankingDetailsApi } from "@/modules/registration/api/banking_details";
import { BankingDetailsForm } from "./banking-details-form";
import { PageTitle } from "../../components/ui";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";

const HorecaBankingDetailsPage = ({ profile }) => {
  const [bankingDetails, setBankingDetails] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBankingDetails() {
      setLoading(true);
      const bankingDetails = await getBankingDetailsApi(profile?.id);
      setBankingDetails(bankingDetails);
      setLoading(false);
    }
    getBankingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="registration-page">
      <PageTitle>
        <BuildingLibraryIcon />
        <h1>Banking Details for Direct Deposit / Payment</h1>
      </PageTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <BankingDetailsForm
          bankingDetails={bankingDetails}
          setBankingDetails={setBankingDetails}
          profile={profile}
        />
      )}
    </div>
  );
};

export { HorecaBankingDetailsPage };
