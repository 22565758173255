import { Outlet, Navigate } from "react-router-dom";
import { Toaster } from "sonner";
import { SplashPage } from "@/modules/core/components/pages";
import { Parfait as Wallpaper } from "@/modules/img/auth-pages";
import "./auth-layout.css";

function PublicAuthRouteLayout({ session, profile }) {
  if (session === undefined) return <SplashPage />;
  if (session && profile?.status !== "approved") {
    return <Navigate to="/application" replace />;
  }
  if (session && profile?.status === "approved") {
    return <Navigate to="/home" replace />;
  }
  return (
    <>
      <div className="public-auth-page">
        <div className="public-auth-page-body">
          <div
            className="poster"
            style={{
              backgroundImage: `url(${Wallpaper})`,
              backgroundSize: "cover",
            }}
          />
          <Outlet />
        </div>
      </div>
      <Toaster position="top-center" closeButton />
    </>
  );
}

export { PublicAuthRouteLayout };
