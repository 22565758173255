import { Link } from "react-router-dom";
import { clsx } from "clsx";
import styles from "./button.module.css";

const Button = ({ children, onClick, color, wide, disabled, href, type }) => {
  const className = clsx(
    styles["button"],
    color && styles["green"],
    wide && styles["wide"],
    disabled && styles["disabled"]
  );

  if (href) {
    return (
      <Link className={className} to={href}>
        {children}
      </Link>
    );
  }

  return (
    <button className={className} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

export { Button };
