import { Breadcrumbs } from "@/modules/core/components/ui";
import { AnalyticsSection } from "./analytics-section";
import { NotificationCenterSection } from "./notification-center-section";
import { useNotificationsLoader } from "./hooks/use-notifications-loader";
import config from "./config";
import "./admin-dashboard-page.css";

const AdminDashboardPage = () => {
  const { breadcrumbs, analytics, notifications } = config;
  const isLoadingNotifications = useNotificationsLoader();
  return (
    <div className="admin-dashboard-page">
      <div className="sections">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <AnalyticsSection widgets={analytics?.widgets} />
        <NotificationCenterSection
          isLoading={isLoadingNotifications}
          notifications={notifications}
        />
      </div>
    </div>
  );
};

export { AdminDashboardPage };
