import { Outlet, Navigate } from "react-router-dom";
import { Toaster } from "sonner";
import { SplashPage } from "../../pages";
import { PrivateNavBar } from "../../gadgets";
import { Footer } from "../../sections";
import "./private-route-layout.css";

function PrivateRouteLayout({ session, profile, initializeAuth }) {
  if (session === null) return <Navigate to="/login" replace />;
  if (session === undefined || profile === undefined) return <SplashPage />;
  if (session)
    return (
      <>
        <div className="layout">
          <PrivateNavBar profile={profile} initializeAuth={initializeAuth} />
          <main className="layout-body">
            <Outlet />
          </main>
          <Footer />
        </div>
        <Toaster position="top-right" closeButton />
      </>
    );

  return <Navigate to="/login" replace />;
}

export { PrivateRouteLayout };
