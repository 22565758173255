import styles from "./field-components.module.css";

function EditProfileField({ label, formElement }) {
  return (
    <div className={styles["field"]}>
      <label>{label}</label>
      {formElement}
    </div>
  );
}

export { EditProfileField };
