import { useState, useEffect } from "react";
import { Button } from "@/modules/core/components/ui";
import { useNavigate } from "react-router-dom";
import { getRejectedRemarkApi } from "../../api/rejected-application";
import { updateApplicationStatusApi } from "../../api/application";
import RejectedImage from "@/modules/img/public-pages/rejected-applicant.png";
import "./application-status-rejected-page.css";

const ApplicationStatusRejectedPage = ({ profile, onUpdateStatusCallBack }) => {
  const [rejectedRemark, setRejectedRemark] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function getRemarkInfo() {
      const staffFormTable = getStaffFormTable(profile?.category);
      const { data } = await getRejectedRemarkApi(profile?.id, staffFormTable);
      setRejectedRemark(data?.remark);
    }
    getRemarkInfo();
  }, [profile]);

  const getStaffFormTable = (category) => {
    switch (category) {
      case "horeca":
        return "forms_horeca_staff_form";
      case "retail":
        return "forms_retail_staff_form";
      default:
        return "";
    }
  };

  const handleClickReApply = async (user_id) => {
    await updateApplicationStatusApi(user_id, "re-apply");
    await onUpdateStatusCallBack();
    navigate("/application/introduction");
  };

  return (
    <div className="application-status-rejected-page">
      <div className="text-container">
        <blockquote>
          <h1 className="title">We're Sorry</h1>
          <div>
            <p>Thank you for taking the time to fill up your application.</p>
            <p>Unfortunately, there's some criteria we need fulfilled.</p>
            <p>Reason: {rejectedRemark || "N/A"}</p>
          </div>
        </blockquote>
        <Button onClick={() => handleClickReApply(profile?.id)}>
          Click here to Re-Apply
        </Button>
      </div>
      <div className="image-container">
        <img src={RejectedImage} alt="user-on-laptop-confused"></img>
      </div>
    </div>
  );
};

export { ApplicationStatusRejectedPage };
