import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getOutletsApi } from "@/modules/registration/api/outlet_information";
import { OutletInformation } from "./outlet-information";

const Outlets = ({ customerId }) => {
  const [outlets, setOutlets] = useState(null);
  const [isLoading, setLoading] = useState(false);
  async function getOutlets() {
    setLoading(true);
    const outlets = await getOutletsApi(customerId);
    setOutlets(outlets);
    setLoading(false);
  }
  useEffect(() => {
    getOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section>
      <h2 className="indent">Outlet Information</h2>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="outlets">
          {outlets?.length === 0 ? (
            <div>
              Business registration address is being used as the sole outlet
            </div>
          ) : (
            outlets?.map((outlet) => (
              <OutletInformation
                key={outlet?.outlet_information_id}
                outletInformation={outlet}
              />
            ))
          )}
        </div>
      )}
    </section>
  );
};

export { Outlets };
