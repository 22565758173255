import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { clsx } from "clsx";
import { Breadcrumbs, Button } from "@/modules/core/components/ui";
import { verifyFields } from "@/modules/registration/utils";
import { insertOneShippingAddress } from "@/modules/core/api/shipping_addresses";
import { LoadingIndicator } from "@/modules/core/components/ui";
import styles from "./add-address-page.module.css";
import config from "./config.json";

const INITIAL_VALUES = {
  user_id: "",
  name: "",
  address_line_1: "",
  address_line_2: "",
  postal_code: "",
  city: "",
  state: "",
  country: "",
};

const Field = ({ label, value, onChange, required }) => {
  return (
    <div className={styles["field"]}>
      <label className={clsx(required && styles["required-field"])}>
        {label}
      </label>
      <input className={styles["input"]} value={value} onChange={onChange} />
    </div>
  );
};

const AddAddressPage = ({ profile }) => {
  const [addressMap, setAddressMap] = useState(INITIAL_VALUES);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (column, value) => {
    setAddressMap({ ...addressMap, [column]: value });
  };

  const handleClickSaveAddress = async (user_id) => {
    const isVerified = verifyFields(
      ["name", "address_line_1", "postal_code", "city", "state", "country"],
      addressMap
    );
    if (isVerified) {
      setIsLoading(true);
      addressMap["user_id"] = user_id;
      await insertOneShippingAddress(addressMap);
      setIsLoading(false);
      navigate(-1);
    }
  };

  const navigate = useNavigate();
  return (
    <div className={styles["add-address-page"]}>
      <Breadcrumbs breadcrumbs={config?.breadcrumbs} />
      <h1>Add Address Page</h1>
      <section>
        {isLoading ? (
          <LoadingIndicator center type="primary" offTimeout />
        ) : (
          <>
            <div>
              <div className={styles["field-group"]}>
                <h3>Name of Address</h3>
                <div className={styles["fields"]}>
                  <Field
                    label="Name"
                    value={addressMap.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    required
                  />
                </div>
              </div>
              <hr />
              <div className={styles["field-group"]}>
                <h3>Address</h3>
                <div className={styles["fields"]}>
                  <Field
                    label="Address Line 1"
                    value={addressMap.address_line_1}
                    onChange={(e) =>
                      handleInputChange("address_line_1", e.target.value)
                    }
                    required
                  />
                  <Field
                    label="Address Line 2"
                    value={addressMap.address_line_2}
                    onChange={(e) =>
                      handleInputChange("address_line_2", e.target.value)
                    }
                  />
                  <div className={styles["two-column"]}>
                    <Field
                      label="City"
                      value={addressMap.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                      required
                    />
                    <Field
                      label="Postal Code"
                      value={addressMap.postal_code}
                      onChange={(e) =>
                        handleInputChange("postal_code", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className={styles["two-column"]}>
                    <Field
                      label="State"
                      value={addressMap.state}
                      onChange={(e) =>
                        handleInputChange("state", e.target.value)
                      }
                      required
                    />
                    <Field
                      label="Country"
                      value={addressMap.country}
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["button-list"]}>
              <Button wide onClick={() => navigate(-1)}>
                Back
              </Button>
              <Button
                color="green"
                wide
                onClick={() => handleClickSaveAddress(profile?.id)}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export { AddAddressPage };
