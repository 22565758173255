import { useState, useEffect } from "react";
import { UserProfilePlaceholderLarge } from "@/modules/core/components/ui";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { getOneProfileById } from "@/modules/core/api/users";
import styles from "./profile-page.module.css";

const ProfilePage = ({ session, profile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState(null);
  const ProfileDetail = ({ label, value }) => {
    return (
      <div className={styles["profile-detail-container"]}>
        <span className={styles["profile-detail-label"]}>{label}</span>
        <span className={styles["profile-detail-value"]}>{value}</span>
      </div>
    );
  };
  useEffect(() => {
    async function getProfileDetails() {
      setIsLoading(true);
      const profileDetails = await getOneProfileById(profile?.id);
      setProfileDetails(profileDetails);
      setIsLoading(false);
    }
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingIndicator center />
      ) : (
        <div className={styles["profile-page"]}>
          <div className={styles["profile-page-header"]}>
            <h1>Profile</h1>
            <Button href={"/profile/edit"}>
              <PencilSquareIcon />
              Edit Profile
            </Button>
          </div>
          <div className={styles["profile-page-body"]}>
            <section className={styles["user-image-section"]}>
              <UserProfilePlaceholderLarge />
            </section>
            <ProfileDetail
              label="First Name"
              value={profileDetails?.first_name}
            />
            <ProfileDetail
              label="Last Name"
              value={profileDetails?.last_name}
            />
            <ProfileDetail label="Email" value={profileDetails?.email} />
            <ProfileDetail
              label="Phone"
              value={profileDetails?.personal_phone}
            />
            <ProfileDetail
              label="Category"
              value={profileDetails?.category.toUpperCase()}
            />
            <ProfileDetail
              label="Price Tag"
              value={profileDetails?.price_tag}
            />
            <ProfileDetail label="Term" value={profileDetails?.term} />
            <ProfileDetail
              label="Approved Credit Limit"
              value={profileDetails?.approved_credit_limit}
            />
            <ProfileDetail
              label="Approved Credit Overdue Limit"
              value={profileDetails?.approved_credit_overdue_limit}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: 500,
              }}
            >
              <span
                style={{
                  color: "grey",
                  fontSize: "0.9em",
                  margin: "5px 0",
                  alignSelf: "start",
                }}
              >
                Address
              </span>
              <span style={{ color: "var(--font-grey)", textAlign: "right" }}>
                No. 44, Jalan Desa Melur 4/1,
                <br />
                Taman Bandar Connaught
                <br />
                56000 Cheras,
                <br />
                Kuala Lumpur, Malaysia.
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { ProfilePage };
