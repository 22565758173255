import { RadioList } from "../radio-list";
import { CheckboxList } from "../checkbox-list";
import "./filter-list.css";

function FilterList({ type, title, elements, filterState, filterTrigger }) {
  switch (type) {
    case "radio":
      return (
        <RadioList
          type={type}
          title={title}
          radios={elements}
          filterState={filterState}
          filterTrigger={filterTrigger}
        />
      );
    case "checkbox":
      return (
        <CheckboxList
          type={type}
          title={title}
          checkboxes={elements}
          filterState={filterState}
          filterTrigger={filterTrigger}
        />
      );
    default:
      return null;
  }
}

export { FilterList };
