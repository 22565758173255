const getOrdersPendingPayment = order => order.payment_status === "pending-payment";

const getTotalCreditsSpent = (orders, startingValue) => orders.filter(getOrdersPendingPayment).reduce((acc, order) => acc + order.sum, startingValue);

const terms = {
  "P_Cash": {
    isOrderBeyondTermDate: () => true,
    isExceedingCreditLimit: () => true
  },
  "P_C7 Days": {
    isOrderBeyondTermDate: (orders) => orders.filter(getOrdersPendingPayment).some(order => isOrderPastDueCreditTerm(order.date_created, 7)),
    isExceedingCreditLimit: (orders, creditLimit, newOrderTotal) => getTotalCreditsSpent(orders, newOrderTotal) > creditLimit
  },
  "P_C14 Days": {
    isOrderBeyondTermDate: (orders) => orders.filter(getOrdersPendingPayment).some(order => isOrderPastDueCreditTerm(order.date_created, 14)),
    isExceedingCreditLimit: (orders, creditLimit, newOrderTotal) => getTotalCreditsSpent(orders, newOrderTotal) > creditLimit
  },
  "P_C30 Days": {
    isOrderBeyondTermDate: (orders) => orders.filter(getOrdersPendingPayment).some(order => isOrderPastDue30DayCreditTerm(order.date_created)),
    isExceedingCreditLimit: (orders, creditLimit, newOrderTotal) => getTotalCreditsSpent(orders, newOrderTotal) > creditLimit
  },
}

const getDaysApart = (date1, date2) => {
  const oneDay = 86400000; // 24 * 60 * 60 * 1000
  const diffDays = Math.round(Math.abs((date2 - date1) / oneDay));

  return diffDays;
};

const isOrderPastDueCreditTerm = (orderDate, termLengthInDays) => {
  const daysSinceOrder = getDaysApart(new Date(orderDate), new Date());

  return daysSinceOrder > termLengthInDays;
};

const isOrderPastDue30DayCreditTerm = (orderDateString) => {
  const orderDate = new Date(orderDateString);
  const orderDateMonth = orderDate.getMonth();
  const dueInMonth = (orderDateMonth + 2) % 12;
  const lastDateToPay = new Date(orderDate.getFullYear(), dueInMonth, 1);

  return new Date() >= lastDateToPay;
};

export { terms, getTotalCreditsSpent, getOrdersPendingPayment, isOrderPastDueCreditTerm, isOrderPastDue30DayCreditTerm };