import { Route } from "react-router-dom";
import {
  LandingPageRouteLayout,
  LandingPage,
  AboutUsPage,
  CataloguePage,
  ContactPage,
  ReturnAndRefundPolicyPage,
  ShippingAndFulfilmentPolicyPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
} from "@/modules/public/pages";

const publicRoutes = [
  <Route path="/" element={<LandingPageRouteLayout />}>
    <Route index element={<LandingPage />} />
    <Route path="about-us" element={<AboutUsPage />} />
    <Route path="catalogue" element={<CataloguePage />} />
    <Route path="contact" element={<ContactPage />} />
    <Route
      path="return-and-refund-policy"
      element={<ReturnAndRefundPolicyPage />}
    />
    <Route
      path="shipping-and-fulfilment-policy"
      element={<ShippingAndFulfilmentPolicyPage />}
    />
    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
    <Route path="terms-of-use" element={<TermsOfUsePage />} />
  </Route>,
];

export { publicRoutes };
