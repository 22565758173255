import { Field } from "../field";
import { formatDateTime } from "@/modules/core/utils/time";

const Status = ({ application }) => {
  return (
    <section>
      <h2 className="indent">Status</h2>
      <Field label="Status" value={application?.status?.toUpperCase()} />
      <Field label="Category" value={application?.category?.toUpperCase()} />
      <Field
        label="Date Submitted"
        value={formatDateTime(application?.date_submitted)}
      />
    </section>
  );
};

export { Status };
