import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";

const Carousel = ({ items }) => {
  const settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 500,
  };
  return (
    <div className="carousel-wrapper">
      <Slider className="carousel" {...settings}>
        {items}
      </Slider>
    </div>
  );
};

export { Carousel };
