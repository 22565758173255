import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Field,
  ApplicationLoadingIndicator,
} from "@/modules/core/components/ui";
import {
  Bubble,
  BubbleHeader,
  Title,
  FieldList,
  FormFooter,
} from "../../../components/ui";
import { insertBankingDetailsApi } from "@/modules/registration/api/banking_details";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";

const keys = ["name_of_bank", "branch", "a_c_no", "swift_code"];
function BankingDetailsForm({ bankingDetails, setBankingDetails, profile }) {
  const navigate = useNavigate();
  const [isSaving, setSaving] = useState(false);

  function onBackClick() {
    navigate("/application/horeca/business-registration-upload-forms");
  }
  function handleSubmit(event) {
    event.preventDefault();
    let map = {};
    keys.forEach((e) => (map[e] = event.target[e].value));
    map["user_id"] = profile?.id;
    console.log("map", map);

    async function insertBankingDetails(map) {
      setSaving(true);
      await insertBankingDetailsApi(map);
      setTimeout(() => {
        setSaving(false);
        navigate("/application/horeca/billing-information");
      }, 800);
    }
    insertBankingDetails(map);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingLibraryIcon />
            <h2>Bank Details</h2>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="name_of_bank"
            label="Name of Bank"
            onChangeFn={(e) =>
              setBankingDetails({
                ...bankingDetails,
                name_of_bank: e.target.value,
              })
            }
            input={bankingDetails?.name_of_bank}
            placeholder="Maybank"
            required
          />
          <Field
            name="branch"
            label="Branch"
            onChangeFn={(e) =>
              setBankingDetails({ ...bankingDetails, branch: e.target.value })
            }
            input={bankingDetails?.branch}
            placeholder="Bangsar"
            required
          />
          <Field
            name="a_c_no"
            label="A/C No."
            onChangeFn={(e) =>
              setBankingDetails({ ...bankingDetails, a_c_no: e.target.value })
            }
            input={bankingDetails?.a_c_no}
            placeholder="XXXXXXXXXXX"
            required
          />
          <Field
            name="swift_code"
            label="Swift Code"
            onChangeFn={(e) =>
              setBankingDetails({
                ...bankingDetails,
                swift_code: e.target.value,
              })
            }
            input={bankingDetails?.swift_code}
            placeholder="XXXXXXXXXXX"
            required
          />
        </FieldList>
      </Bubble>
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
}

export { BankingDetailsForm };
