import { Link } from "react-router-dom";
import {
  CircleGauge as DashboardIcon,
  BookText as ApplicationsIcon,
  Cherry as ProductsIcon,
} from "lucide-react";
import styles from "./admin-sidebar.module.css";

const data = [
  {
    icon: <DashboardIcon />,
    label: "Dashboard",
    href: "dashboard",
  },
  {
    icon: <ApplicationsIcon />,
    label: "Applications",
    href: "manage-applications",
  },
  {
    icon: <ProductsIcon />,
    label: "Products",
    href: "manage-products",
  },
];

const AdminSidebar = () => {
  return (
    <div className={styles["admin-sidebar"]}>
      <ul>
        {data?.map((datum) => (
          <Link
            to={datum.href}
            key={datum.label}
            className={styles["list-item"]}
          >
            {datum.icon} {datum.label}
          </Link>
        ))}
      </ul>
    </div>
  );
};

export { AdminSidebar };
