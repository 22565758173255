import { Header, Status, Divider, Footer } from "../common";
import { RetailInformation, RetailStaffForm } from "./components";

const RetailDocument = ({ application, customerId, onFormSubmitCallback }) => {
  return (
    <>
      <Header application={application} />
      <Status application={application} />
      <Divider />
      <RetailInformation user_id={customerId} />
      <Divider />
      <RetailStaffForm
        application={application}
        customerId={customerId}
        onFormSubmitCallback={onFormSubmitCallback}
      />
      <Footer />
    </>
  );
};

export { RetailDocument };
