import { MdSearch as SearchIcon } from "react-icons/md";
import "./search-bar.css";

function SearchBar({ input, setInput }) {
  function handleSubmit(e) {
    e.preventDefault();
  }
  return (
    <div className="search-bar-wrapper">
      <form className="search-bar" onSubmit={handleSubmit}>
        <input value={input} onChange={(e) => setInput(e.target.value)} />
        <SearchIcon />
      </form>
    </div>
  );
}

export { SearchBar };
