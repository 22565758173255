import { Button } from "@/modules/core/components/ui";
import { Blockquote, Divider, PageTitle } from "../../components/ui";
import { UploadList } from "./upload-list";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import "./horeca-business-registration-upload-form-page.css";

const HorecaBusinessRegistrationUploadFormsPage = ({ session }) => {
  return (
    <div className="business-registration-upload-forms-page registration-page">
      <div>
        <PageTitle>
          <CloudArrowUpIcon />
          <h1>Upload Business Forms</h1>
        </PageTitle>
        <Blockquote>
          <InformationCircleIcon />
          Please upload the following files in .pdf format
        </Blockquote>
        <Divider />
        <UploadList session={session} />
      </div>
      <div className="form-footer">
        <Button href="/application/horeca/business-registration">
          Previous
        </Button>
        <Button href="/application/horeca/banking-details" color="green">
          Continue
        </Button>
      </div>
    </div>
  );
};

export { HorecaBusinessRegistrationUploadFormsPage };
