import ResponsiveEmbed from "react-responsive-embed";
import { Contact } from "@/modules/img/public-pages";
import "../(misc)/public-misc.css";
import "./contact-page.css";

const MapDetails = () => {
  return (
    <div className="map-detail">
      <div className="map-detail-item">
        <div className="map-detail-item-title">Address</div>
        <div className="map-detail-item-description">
          No. 35, Jalan PBS 14/2, Taman Perindustrian Bukit Serdang, 43300 Seri
          Kembangan, Selangor Darul Ehsan, Malaysia
        </div>
      </div>
      <div className="map-detail-item">
        <div className="map-detail-item-title">Phone Number</div>
        <div className="map-detail-item-description">
          <a className="tel" href="tel::03-8945 6588">
            03-8945 6588
          </a>
          <br />
          <a className="tel" href="tel::03-8945 8588">
            03-8945 8588
          </a>
        </div>
      </div>
    </div>
  );
};
const Map = () => {
  return (
    <div className="map">
      <ResponsiveEmbed
        src="https://maps.google.com/maps?q=Lotus%20Consulting%20Group%20Malaysia&t=&z=13&ie=UTF8&iwloc=&output=embed"
        ratio="16:9"
      />
    </div>
  );
};
const ContactPage = () => {
  return (
    <div className="contact-page page">
      <div className="page-header">
        <img src={Contact} alt="Contact Us" />
        <h1>Contact Us</h1>
      </div>
      <section>
        <div className="description">
          <p>
            Learn more about our products and services by sending us a message
            at:{" "}
            <a href="mailto:assistme@lotusoxford.com" className="email">
              assistme@lotusoxford.com
            </a>
          </p>
        </div>
        <div className="location">
          <Map />
          <MapDetails />
        </div>
      </section>
    </div>
  );
};

export { ContactPage };
