import { Link } from "react-router-dom";
import Logo from "@/modules/img/logo/lotus-consulting-group-logo-cropped.png";

function HomeButton() {
  return (
    <Link
      to="/"
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        color: "var(--primary-color)",
        fontSize: "0.9em",
        textTransform: "uppercase",
        textDecoration: "none",
      }}
    >
      <img src={Logo} alt="logo" style={{ height: 48 }} />
    </Link>
  );
}

export { HomeButton };
