import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, Divider, PageTitle } from "../../components/ui";
import { BusinessRegistrationForm } from "./business-registration-form";
import { BuildingOffice2Icon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { getBusinessRegistrationApi } from "@/modules/registration/api/business_registration";

const HorecaBusinessRegistrationPage = ({ profile }) => {
  const [businessRegistration, setBusinessRegistration] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBusinessRegistration() {
      setLoading(true);
      const businessRegistration = await getBusinessRegistrationApi(
        profile?.id
      );
      setBusinessRegistration(businessRegistration);
      setLoading(false);
    }
    getBusinessRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="registration-page">
      <div>
        <PageTitle>
          <BuildingOffice2Icon />
          <h1>Business Registration</h1>
        </PageTitle>
        <Blockquote>
          <InformationCircleIcon />
          We require business registration information to allow us to properly
          assess your validity.
        </Blockquote>
        <Divider />
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <BusinessRegistrationForm
            businessRegistration={businessRegistration}
            setBusinessRegistration={setBusinessRegistration}
            profile={profile}
          />
        )}
      </div>
    </div>
  );
};

export { HorecaBusinessRegistrationPage };
