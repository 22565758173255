import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getBankingDetailsApi } from "@/modules/registration/api/banking_details";
import { Field } from "../../../common";

const BankingDetails = ({ customerId }) => {
  const [bankingDetails, setBankingDetails] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBankingDetails() {
      setLoading(true);
      const bankingDetails = await getBankingDetailsApi(customerId);
      setBankingDetails(bankingDetails);
      setLoading(false);
    }
    getBankingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Banking Details</h2>
      <h3>{bankingDetails?.name_of_bank}</h3>
      <div className="bank-details">
        <h4>Bank Details</h4>
        <Field label="Name of Bank" value={bankingDetails?.name_of_bank} />
        <Field label="Branch" value={bankingDetails?.branch} />
        <Field label="A/C No." value={bankingDetails?.a_c_no} />
        <Field label="Swift Code" value={bankingDetails?.swift_code} />
      </div>
    </section>
  );
};

export { BankingDetails };
