import { Link } from "react-router-dom";
import { FiUser as UserIcon } from "react-icons/fi";
import { Date } from "./date";
import { AdminPill } from "./admin-pill";
import { CategoryPill } from "./category-pill";
import { StatusPill } from "./status-pill";
import { DeleteButton } from "./delete-button";

import "./index.css";

function Card({
  id,
  email,
  isAdmin,
  category,
  status,
  date_submitted,
  deleteUser,
  onDeleteSuccess,
}) {
  return (
    <Link className="card" to={id}>
      <div className="icon-wrapper">
        <UserIcon />
      </div>
      <div className="card-info">
        <div className="primary">
          <span className="name">John Doe</span>
          <StatusPill status={status} />
        </div>
        <div className="secondary">
          <span className="email">{email}</span>
        </div>
        <div className="tertiary">
          <AdminPill isAdmin={isAdmin} />
          <CategoryPill category={category} />
        </div>
        <div className="quarternary">
          <Date date_submitted={date_submitted} />
          <DeleteButton
            id={id}
            email={email}
            deleteUser={deleteUser}
            onDeleteSuccess={onDeleteSuccess}
          />
        </div>
      </div>
    </Link>
  );
}

export { Card };
