import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

function formatDate(date) {
  return timeAgo.format(new Date(date));
}

const formatLongDate = (date) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleString("en-UK", options);
};

export { formatDate, formatLongDate };
