import { InfoList } from "../info-list";
import { formatDate, formatLongDate } from "../../helpers";

import "./index.css";

const RichDateInfo = ({ date_submitted }) => {
  return (
    <div className="rich-date-info">
      <InfoList
        primaryText={formatDate(date_submitted)}
        secondaryText={formatLongDate(date_submitted)}
      />
    </div>
  );
};

const NotAvailableText = () => <div className="not-available-text">N/A</div>;

const DateCell = ({ date_submitted }) => {
  return (
    <div className="date-cell">
      {date_submitted ? (
        <RichDateInfo date_submitted={date_submitted} />
      ) : (
        <NotAvailableText />
      )}
    </div>
  );
};

export { DateCell };
