import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
} from "@/modules/core/components/ui";
import { Address } from "../checkout-page/shipping-address-section/address";
import { HomeModernIcon } from "@heroicons/react/24/solid";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  getAllUserShippingAddressesByUserId,
  deleteOneShippingAddressByAddressId,
} from "@/modules/core/api/shipping_addresses";
import styles from "./addresses-page.module.css";
import config from "./config.json";

const AddressCard = ({ address, setAddresses, setIsLoading }) => {
  const handleRemoveClick = async () => {
    // eslint-disable-next-line
    if (confirm(`Are you sure you want to delete ${address?.name}?`)) {
      setIsLoading(true);
      await deleteOneShippingAddressByAddressId(address?.address_id);
      const newAddresses = await getAllUserShippingAddressesByUserId(
        address?.user_id
      );
      setAddresses(newAddresses);
      setIsLoading(false);
    }
  };
  return (
    <div className={styles["address-card"]}>
      <Address icon={<HomeModernIcon />} {...address} />
      <div className={styles["toolbar"]}>
        <Button onClick={() => handleRemoveClick()}>Remove</Button>
      </div>
    </div>
  );
};

const AddressesPage = ({ session, profile }) => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      const data = await getAllUserShippingAddressesByUserId(profile?.id);
      setAddresses(data);
      setIsLoading(false);
    };
    getData();
  }, [profile]);

  return (
    <div className={styles["addresses-page"]}>
      <Breadcrumbs breadcrumbs={config?.breadcrumbs} />
      <div className={styles["header"]}>
        <h2>Manage Addresses</h2>
        <Button href="/addresses/add">
          <PlusIcon /> Add Address
        </Button>
      </div>
      <div className={styles["address-list"]}>
        {isLoading ? (
          <LoadingIndicator center type="primary" offTimeout />
        ) : addresses.length > 0 ? (
          addresses.map((address, idx) => (
            <>
              <AddressCard
                address={address}
                setAddresses={setAddresses}
                setIsLoading={setIsLoading}
              />
              {idx !== addresses.length - 1 && (
                <hr className={styles["divider"]} />
              )}
            </>
          ))
        ) : (
          <>
            <p>No addresses available. Please add an address to continue.</p>
          </>
        )}
      </div>
      <div className={styles["nav-bar"]}>
        <Button onClick={() => navigate(-1)}>Back</Button>
      </div>
    </div>
  );
};

export { AddressesPage };
