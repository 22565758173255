import { TransitionWrapper } from "@/modules/core/components/ui";
import { AnalyticsWidget } from "./widget";
import "./index.css";

const AnalyticsSection = ({ widgets }) => {
  return (
    <section className="analytics-section">
      <div className="list">
        {widgets?.map((widget, idx) => (
          <TransitionWrapper index={idx}>
            <AnalyticsWidget key={idx} {...widget} />
          </TransitionWrapper>
        ))}
      </div>
    </section>
  );
};

export { AnalyticsSection };
