import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, Divider, PageTitle } from "../../components/ui";
import { BusinessRegistrationSection } from "./business-registration-section";
import { BusinessFormsSection } from "./business-forms-section";
import { BankingDetailsSection } from "./banking-details-section";
import { BillingInformationSection } from "./billing-information-section";
import { ReferenceInformationSection } from "./reference-information-section";
import { OutletInformationSection } from "./outlet-information-section";
import { TermsAndConditionsSection } from "./terms-and-conditions-section";
import { DataProtectionSection } from "./data-protection-section";
import { DeclarationSection } from "./declaration-section";
import { updateApplicationStatusApi } from "@/modules/registration/api/application";
import { sendEmail } from "@/modules/core/api/email";

import "../application-review-page.css";

const HorecaReviewPage = ({ profile, onReviewSuccessCallback }) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    async function submitForReview() {
      setSaving(true);
      await updateApplicationStatusApi(profile?.id, "pending-review");
      await sendEmail({ submitterName: profile?.email });
      setSaving(false);
      await onReviewSuccessCallback();
      navigate("/application/pending-approval");
    }
    if (checked) {
      submitForReview();
    } else {
      toast.warning("Please verify the checkbox requirements.");
    }
  };
  return (
    <div className="application-review-page">
      <PageTitle>
        <ScaleIcon />
        <h1>Review</h1>
      </PageTitle>
      <Blockquote>
        <InformationCircleIcon />
        Please review your information and ensure that everything is correct.
        Once submitted, it will be treated as final.
      </Blockquote>
      <Divider />
      <BusinessRegistrationSection customerId={profile?.id} />
      <Divider />
      <BusinessFormsSection customerId={profile?.id} />
      <Divider />
      <BankingDetailsSection customerId={profile?.id} />
      <Divider />
      <BillingInformationSection customerId={profile?.id} />
      <Divider />
      <ReferenceInformationSection customerId={profile?.id} />
      <Divider />
      <OutletInformationSection customerId={profile?.id} />
      <Divider />
      <TermsAndConditionsSection customerId={profile?.id} />
      <Divider />
      <DataProtectionSection customerId={profile?.id} />
      <Divider />
      <DeclarationSection customerId={profile?.id} />
      <Divider />
      <form className="submit-for-review-form" onSubmit={handleSubmit}>
        <div className="terms">
          <label>
            <input type="checkbox" checked={checked} onChange={handleChange} />I
            have carefully read through my information and have verified that it
            is authentic.
          </label>
        </div>
        <div style={{ margin: "25px 0" }}>
          <button type="submit">Submit for Review</button>
        </div>
        {isSaving && <ApplicationLoadingIndicator />}
      </form>
    </div>
  );
};

export { HorecaReviewPage };
