import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BiSolidLockAlt as LockIcon } from "react-icons/bi";
import { PiCoinsFill as CoinIcon } from "react-icons/pi";
import { toast } from "sonner";
import {
  terms,
  getTotalCreditsSpent,
} from "@/modules/core/utils/lotusCreditTerms";
import { Breadcrumbs, LoadingIndicator } from "@/modules/core/components/ui";
import {
  EGHLForm,
  FORM_VALUES,
} from "@/modules/core/components/gadgets/e-ghl-form";
import { CheckoutList } from "@/modules/core/components/gadgets/checkout-list";
import { ShippingAddressSection } from "./shipping-address-section";

import formatPriceWithCurrency, {
  formatPrice,
} from "@/modules/core/utils/formatPriceWithCurrency";
import { sortByAscendingDateOrder } from "@/modules/core/utils/date";

import { getCartItemsViewApi, getActiveCartApi } from "@/modules/core/api/cart";
import {
  createPendingPaymentOrderApi,
  getOrderHistoryApi,
} from "@/modules/core/api/order";
import { getOneProfileById } from "@/modules/core/api/users";

import "./checkout-page.css";

function CheckoutSummary({
  showForm,
  setShowForm,
  cart,
  cartItems,
  session,
  userCreditsRemaining,
  getOrderPrice,
  isTermDateViolation,
  isCreditLimitViolation,
  isCreditInformationLoading,
  savedAddressId,
}) {
  const [isProcessingCreditPayment, setIsProcessingCreditPayment] =
    useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (cartItems.length === 0) {
      toast.warning("No items in cart");
      return;
    }
    if (getOrderPrice() > 10000) {
      toast.warning("Sorry, we can only accept orders up to RM9,999.");
      return;
    }
    if (showForm || isProcessingCreditPayment) {
      return;
    }
    if (!savedAddressId) {
      toast.warning("Please select a shipping address to proceed.");
      return;
    }
    setShowForm(true);
  };
  function handlePayByCredit() {
    if (isProcessingCreditPayment || showForm) {
      return;
    }

    if (cartItems.length === 0) {
      toast.warning("No items in cart");
      return;
    } else if (isTermDateViolation) {
      toast.warning(
        "There exists orders pending payment, which exceed allocated term date. Kindly resolve payment of orders past due date before continuing."
      );
      return;
    } else if (isCreditLimitViolation) {
      toast.warning(
        "The addition of this order causes the credit limit of this account to be exceeded. Kindly resolve payment to free up more credit."
      );
      return;
    } else if (!savedAddressId) {
      toast.warning("Please select a shipping address to proceed.");
      return;
    } else {
      /**
       * Creates an order with the status 'pending-payment'
       * Navigates to the order page
       */
      setIsProcessingCreditPayment(true);
      async function createPendingPaymentOrder() {
        const { order_number } = await createPendingPaymentOrderApi(
          cart?.id,
          session?.user?.id,
          savedAddressId
        );
        navigate(`/orders/${order_number}`);
        setIsProcessingCreditPayment(false);
      }
      createPendingPaymentOrder();
    }
  }
  function PayByCreditButton({
    isProcessingCreditPayment,
    isCreditInformationLoading,
    remainingCredit,
  }) {
    if (isProcessingCreditPayment || isCreditInformationLoading) {
      return (
        <div
          className="payment-button secondary-btn loading-btn"
          style={{ gap: 4 }}
        >
          <div className="payment-button-title">Processing...</div>
          <p className="payment-button-description">
            Checking available credits...
          </p>
        </div>
      );
    }
    return (
      <>
        <div
          className="payment-button secondary-btn"
          onClick={handlePayByCredit}
        >
          <div className="payment-button-icon">
            <CoinIcon />
          </div>
          <div className="payment-button-info">
            <div className="payment-button-title">
              <span>Pay using Credit</span>
            </div>
          </div>
        </div>
        <p className="payment-button-description">
          Use your available credits to purchase now and pay later. (
          {formatPrice(remainingCredit)} available).
        </p>
      </>
    );
  }
  function SubmitButton({ showForm, getOrderPrice }) {
    return showForm ? (
      <EGHLForm
        {...FORM_VALUES}
        Amount={formatPrice(getOrderPrice())}
        cart={cart}
        session={session}
        paymentType="pay-now"
        savedAddressId={savedAddressId}
      />
    ) : (
      <>
        <div className="payment-button primary-btn" onClick={handleSubmit}>
          <div className="payment-button-icon">
            <LockIcon fill="white" />
          </div>
          <div className="payment-button-info">
            <div className="payment-button-title">
              <span>Pay now</span>
            </div>
          </div>
        </div>
        <p className="payment-button-description">
          Pay using your bank account or other digital mediums for an instant
          transaction.
        </p>
      </>
    );
  }
  return (
    <div className="checkout-summary">
      <div className="subtotal">
        <span>Subtotal:</span>
        <span>{formatPriceWithCurrency(getOrderPrice())}</span>
      </div>
      <hr className="price-divider" />
      <div className="misc">
        <div className="secondary">
          <span>Shipping:</span>
          <span>Free</span>
        </div>
        <div className="secondary">
          <span>Taxes:</span>
          <span>Free</span>
        </div>
      </div>
      <hr className="price-divider" />
      <div className="primary">
        <span className="total-due">Total due:</span>
        <span>{formatPriceWithCurrency(getOrderPrice())}</span>
      </div>
      <div className="payment-section">
        <SubmitButton
          showForm={showForm}
          cart={cart}
          session={session}
          getOrderPrice={getOrderPrice}
        />
        <hr className="divider" />
        <PayByCreditButton
          isProcessingCreditPayment={isProcessingCreditPayment}
          isCreditInformationLoading={isCreditInformationLoading}
          remainingCredit={userCreditsRemaining}
        />
      </div>
    </div>
  );
}

const CheckoutPage = ({ session }) => {
  const [cart, setCart] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [orders, setOrders] = useState(false);
  const [profile, setProfile] = useState(false);
  const [isCreditInformationLoading, setIsCreditInformationLoading] =
    useState(false);
  const [savedAddressId, setSavedAddressId] = useState(null);

  const getOrderPrice = useCallback(() => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item?.STOCKVALUE * item?.quantity;
    });
    return total;
  }, [cartItems]);

  const userCreditRules = useMemo(() => {
    return terms[profile?.term];
  }, [profile]);

  const userCreditsRemaining = useMemo(() => {
    if (!userCreditRules) {
      return 0;
    }

    return (
      profile.approved_credit_overdue_limit - getTotalCreditsSpent(orders, 0)
    );
  }, [userCreditRules, orders, profile]);

  const isTermDateViolation = useMemo(() => {
    if (!userCreditRules) {
      return true;
    }

    const { isOrderBeyondTermDate } = userCreditRules;
    return isOrderBeyondTermDate(orders);
  }, [userCreditRules, orders]);

  const isCreditLimitViolation = useMemo(() => {
    if (!userCreditRules) {
      return true;
    }

    const { isExceedingCreditLimit } = userCreditRules;
    return isExceedingCreditLimit(
      orders,
      profile.approved_credit_overdue_limit,
      getOrderPrice()
    );
  }, [userCreditRules, profile, orders, getOrderPrice]);

  const getCart = async () => {
    setLoading(true);
    const cart = await getActiveCartApi(session?.user?.id);
    setCart(cart);
    const cartItems = await getCartItemsViewApi(cart?.id);
    setCartItems(sortByAscendingDateOrder(cartItems));
    setLoading(false);
  };

  const getCreditInformation = async () => {
    setIsCreditInformationLoading(true);

    const orders = await getOrderHistoryApi(session?.user?.id);
    const profile = await getOneProfileById(session?.user?.id);
    setOrders(orders);
    setProfile(profile);

    setIsCreditInformationLoading(false);
  };

  useEffect(() => {
    getCart();
    getCreditInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function CheckoutListWrapper({ isLoading, cartItems }) {
    return isLoading ? (
      <div style={{ height: "35vh" }}>
        <LoadingIndicator center />
      </div>
    ) : (
      <CheckoutList cartItems={cartItems} type="checkout" />
    );
  }

  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      text: "Checkout",
      type: "text",
    },
  ];

  return (
    <div className="checkout-page">
      <div className="checkout-page-header">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Proceed to payment</h1>
      </div>
      <div className="checkout-page-body">
        <div>
          <ShippingAddressSection
            session={session}
            setSavedAddressId={setSavedAddressId}
          />
          <section>
            <h3>Order List</h3>
            <CheckoutListWrapper isLoading={isLoading} cartItems={cartItems} />
          </section>
        </div>
        <div>
          <CheckoutSummary
            showForm={showForm}
            setShowForm={setShowForm}
            cart={cart}
            cartItems={cartItems}
            session={session}
            getOrderPrice={getOrderPrice}
            userCreditsRemaining={userCreditsRemaining}
            isTermDateViolation={isTermDateViolation}
            isCreditLimitViolation={isCreditLimitViolation}
            isCreditInformationLoading={isCreditInformationLoading}
            savedAddressId={savedAddressId}
          />
        </div>
      </div>
    </div>
  );
};

export { CheckoutPage };
