import { Route, Navigate } from "react-router-dom";
import {
  LoginPage,
  SignUpPage,
  ForgotPasswordPage,
} from "@/modules/auth/pages";
import { PublicAuthRouteLayout } from "../layouts";

const authRoutes = ({ auth, loginCallback }) => {
  return [
    <Route
      path="/"
      element={
        <PublicAuthRouteLayout
          session={auth?.session}
          profile={auth?.profile}
        />
      }
    >
      <Route index element={<Navigate to="/login" replace />} />
      <Route
        path="login"
        element={<LoginPage loginCallback={loginCallback} />}
      />
      <Route path="signup" element={<SignUpPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
    </Route>,
  ];
};
export { authRoutes };
