import { Fragment } from "react";
import { Link } from "react-router-dom";
import "./breadcrumbs.css";

const Delimiter = () => {
  return <span className="delimiter">↠</span>;
};
const Element = ({ breadcrumb }) => {
  switch (breadcrumb?.type) {
    case "link":
      return <Link to={breadcrumb?.pathname}>{breadcrumb?.text}</Link>;
    case "text":
      return <span className="breadcrumb-text">{breadcrumb?.text}</span>;
    default:
      return null;
  }
};
const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="breadcrumbs">
      {breadcrumbs?.map((breadcrumb, idx) => {
        return (
          <Fragment key={idx}>
            {idx > 0 && <Delimiter />}
            <Element breadcrumb={breadcrumb} />
          </Fragment>
        );
      })}
    </div>
  );
};

export { Breadcrumbs };
