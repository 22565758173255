import { supabase } from "@/modules/supabase";

const getApplicationApi = async (user_id, category) => {
  const { data, error } = await supabase
    .from("forms_applications")
    .select()
    .match({ user_id, category });
  console.log("get forms_applications api", data);
  console.log("get forms_applications error", error);
  return data?.[0];
};

const getApplicationViewApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_applications_view")
    .select()
    .match({ user_id });
  console.log("get forms_applications_view api", data);
  console.log("get forms_applications_view api error", error);
  return data?.[0];
};

const getAllApplicationsViewApi = async () => {
  const { data, error } = await supabase
    .from("forms_applications_view")
    .select();
  console.log("get all forms_applications_view api", data);
  console.log("get all forms_applications_view api error", error);
  return data;
};

const insertApplicationApi = async (user_id, category, status) => {
  const { data, error } = await supabase
    .from("forms_applications")
    .upsert({
      user_id,
      category,
      status,
      date_submitted: new Date(),
    })
    .select();
  console.log("insert forms_applications api", data);
  console.log("insert forms_applications api error", error);
  return data?.[0];
};

const updateApplicationCategoryApi = async (user_id, category) => {
  const { data, error } = await supabase
    .from("forms_applications")
    .update({ category: category })
    .eq("user_id", user_id)
    .select();
  if (data.length > 0) {
    console.log("updated user to", category, data);
  } else {
    console.log("error updating user to", category, error);
  }
};

const updateApplicationStatusApi = async (user_id, status) => {
  const { data, error } = await supabase
    .from("forms_applications")
    .update({ status: status })
    .eq("user_id", user_id)
    .select();
  if (data.length > 0) {
    console.log("updated user status", data);
  } else {
    console.log("Could not update user status.", error);
  }
};

export {
  getApplicationApi,
  getApplicationViewApi,
  getAllApplicationsViewApi,
  insertApplicationApi,
  updateApplicationCategoryApi,
  updateApplicationStatusApi,
};
