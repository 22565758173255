const sortByAscendingDateOrder = (data) => {
  if (!data) return [];
  return data.sort(
    (a, b) => new Date(a.date_created) - new Date(b.date_created),
  );
};

const sortByDescendingDateOrder = (data) => {
  if (!data) return [];
  return data.sort(
    (a, b) => new Date(b.date_created) - new Date(a.date_created),
  );
};

export { sortByAscendingDateOrder, sortByDescendingDateOrder };
