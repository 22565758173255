import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { Divider, Title } from "../../../components/ui";
import { HomeIcon } from "@heroicons/react/24/solid";
import { ReviewDeliveryAddressSection } from "../review-delivery-address-section";
import { ReviewContactInfoSection } from "../review-contact-info-section";
import { ReviewBusinessTypeSection } from "../review-business-type-section";
import { getRetailInformationApi } from "@/modules/registration/api/retail_information";

const RetailInformationReviewForm = ({ user_id }) => {
  const [retailInformation, setRetailInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getRetailInformation() {
      setLoading(true);
      const retailInformation = await getRetailInformationApi(user_id);
      setRetailInformation(retailInformation);
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    }
    getRetailInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <div className="heading">
        <Title>
          <HomeIcon />
          <h2>Retail Information</h2>
        </Title>
        <Button href="/application/retail/retail-information">
          <EditIcon />
          Edit Information
        </Button>
      </div>
      <ReviewDeliveryAddressSection retailInformation={retailInformation} />
      <Divider />
      <ReviewContactInfoSection retailInformation={retailInformation} />
      <Divider />
      <ReviewBusinessTypeSection retailInformation={retailInformation} />
    </div>
  );
};

export { RetailInformationReviewForm };
