import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getRetailInformationApi } from "@/modules/registration/api/retail_information";
import { Field } from "../../../common";

function RetailInformation({ user_id }) {
  const [retailInformation, setRetailInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getRetailInformation() {
      setLoading(true);
      const retailInformation = await getRetailInformationApi(user_id);
      setRetailInformation(retailInformation);
      setLoading(false);
    }
    getRetailInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Retail Information</h2>
      <div>
        <h4>Person In Charge</h4>
        <Field label="Name" value={retailInformation?.person_in_charge} />
      </div>
      <div className="address">
        <h4>Address</h4>
        <Field
          label="Address Line 1"
          value={retailInformation?.address_line_1}
        />
        <Field
          label="Address Line 2"
          value={retailInformation?.address_line_2}
        />
        <Field label="Postal Code" value={retailInformation?.postal_code} />
        <Field label="City" value={retailInformation?.city} />
        <Field label="State" value={retailInformation?.state} />
        <Field label="Country" value={retailInformation?.country} />
      </div>
      <div className="contact">
        <h4>Contact Details</h4>
        <Field label="Phone Number" value={retailInformation?.phone_number} />
      </div>
      <div className="type">
        <h4>Business Type</h4>
        <Field
          label="Nature of Business"
          value={retailInformation?.nature_of_business}
        />
        <Field label="Specify" value={retailInformation?.specification} />
      </div>
    </section>
  );
}

export { RetailInformation };
