import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getTermsAndConditionsApi } from "@/modules/registration/api/terms_and_conditions";
import { formatDateTime } from "@/modules/core/utils/time";

const TermsAndConditions = ({ customerId }) => {
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getTermsAndConditions() {
      setLoading(true);
      const termsAndConditions = await getTermsAndConditionsApi(customerId);
      setTermsAndConditions(termsAndConditions);
      setLoading(false);
    }
    getTermsAndConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Terms and Conditions</h2>
      <h3>{termsAndConditions?.is_signed ? "Signed" : "Not Signed"}</h3>
      <p>
        {termsAndConditions?.is_signed
          ? `Signed on ${formatDateTime(termsAndConditions?.date_signed)}`
          : "Not Signed"}
      </p>
    </section>
  );
};

export { TermsAndConditions };
