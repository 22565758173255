import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, Divider, PageTitle } from "../../components/ui";
import { updateApplicationStatusApi } from "@/modules/registration/api/application";
import { sendEmail } from "@/modules/core/api/email";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { RetailInformationReviewForm } from "./retail-information-review-form";

import "../application-review-page.css";

function RetailReviewPage({ profile, onReviewSuccessCallback }) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isSaving, setSaving] = useState(false);

  function handleChange() {
    setChecked(!checked);
  }

  function handleSubmit(event) {
    event.preventDefault();
    async function submitForReview() {
      setSaving(true);
      await updateApplicationStatusApi(profile?.id, "pending-review");
      await sendEmail({ submitterName: profile?.email });
      setTimeout(async () => {
        setSaving(false);
        await onReviewSuccessCallback();
        navigate("/application/pending-approval");
      }, 1200);
    }
    if (checked) {
      submitForReview();
    } else {
      toast.warning("Please verify the checkbox requirements.");
    }
  }
  return (
    <div className="application-review-page">
      <PageTitle>
        <ScaleIcon />
        <h1>Review</h1>
      </PageTitle>
      <Blockquote>
        <InformationCircleIcon />
        Please review your information and ensure that everything is correct.
        Once submitted, it will be treated as final.
      </Blockquote>
      <Divider />
      <RetailInformationReviewForm user_id={profile?.id} />
      <Divider />
      <form className="submit-for-review-form" onSubmit={handleSubmit}>
        <div className="terms">
          <label>
            <input type="checkbox" checked={checked} onChange={handleChange} />I
            have carefully read through my information and have verified that it
            is authentic.
          </label>
        </div>
        <div>
          <button type="submit">Submit for Review</button>
        </div>
        {isSaving && <ApplicationLoadingIndicator />}
      </form>
    </div>
  );
}

export { RetailReviewPage };
