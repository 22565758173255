import { useState, useEffect } from "react";

const useNotificationsLoader = () => {
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoadingNotifications(false), 1500);
  }, []);
  return isLoadingNotifications;
};

export { useNotificationsLoader };
