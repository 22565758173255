import { useState, useEffect } from "react";
import { toast } from "sonner";
import { Button } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { supabase } from "@/modules/supabase";
import {
  createSignedUrl,
  upsertImageToStorage,
} from "@/modules/core/api/storage";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { CloudArrowDownIcon } from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/solid";

function UploadComponent({ title, id, userId, storageBucketName, tableName }) {
  const [form, setForm] = useState(null);
  const [isGettingSignedUrl, setIsGettingSignedUrl] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    console.log("useEffect", userId, storageBucketName, tableName);
    getForm(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieves the picture URL from the product images table.
   * Then, uses the URL to download the image into the user's browser.
   * The local path is then used to display the image.
   *
   * @param {Integer} id The unique identifier for the product.
   */
  async function getForm(id) {
    const getFormUrl = async () => {
      const { data, error } = await supabase
        .from(tableName)
        .select()
        .eq("user_id", userId);

      console.log("formurl data", data);
      if (error) throw error;
      setForm(data?.[0]);
    };
    try {
      setIsDownloading(true);
      await getFormUrl();
    } catch (error) {
      console.log("Error retrieving row: ", error);
    } finally {
      setIsDownloading(false);
    }
  }

  /**
   * Upsert file and filepath to Supabase Storage
   * Upsert product ID and filepath combination to Supabase Database table
   *
   * @param {Object} event The browser event
   */
  async function uploadImage(event) {
    const getFileInfo = (event) => {
      const file = event.target.files[0];
      const fileName = `${userId}_${file.name}`;
      const filePath = `${fileName}`;
      return [filePath, file];
    };
    const upsertToStorage = async (filePath, file) => {
      const { error } = await upsertImageToStorage(
        storageBucketName,
        filePath,
        file
      );
      if (error) {
        throw error;
      }
    };
    const upsertToDatabase = async (filePath) => {
      const { data, error } = await supabase.from(tableName).upsert([
        {
          user_id: userId,
          form_url: filePath,
          modified_at: new Date().toISOString(),
        },
      ]);
      console.log("upsertToDatabase success", data);
      console.log("upsertToDatabase error", error);
    };
    try {
      setIsUploading(true);
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }
      const [filePath, file] = getFileInfo(event);
      console.log("filePath", file);
      console.log("file", file);
      console.log("storageBucketName", storageBucketName);
      console.log("userId", userId);
      console.log("tableName", tableName);
      await upsertToStorage(filePath, file);
      await upsertToDatabase(filePath);
    } catch (error) {
      toast.error("Sorry, there was a problem with the upload.");
      console.error(error);
    } finally {
      setIsUploading(false);
      event.target.value = null;
      await getForm();
    }
  }

  async function removeFile() {
    try {
      setIsRemoving(true);
      const { data, error } = await supabase
        .from(tableName)
        .delete()
        .eq("user_id", userId);
      console.log("tableName", tableName);
      console.log("onDeleteSuccessData", data);
      if (error) console.error(error);
    } catch (error) {
    } finally {
      await getForm();
      setIsRemoving(false);
    }
  }

  async function downloadForm(formName, storageBucketName) {
    try {
      setIsGettingSignedUrl(true);
      const { data, error } = await createSignedUrl(
        storageBucketName,
        formName
      );
      console.log("downloadForm success", data);
      console.log("downloadForm error", error);
      window.open(data.signedUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGettingSignedUrl(false);
    }
  }
  console.log("form", form);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <section className="upload-component">
      <Bubble>
        <BubbleHeader>
          <Title>
            <DocumentTextIcon />
            <h3>{title}</h3>
          </Title>
        </BubbleHeader>
        <div style={{ padding: "0 16px" }}>
          <FieldList>
            <div className="file-details">
              {form ? (
                <div>
                  <b>File Name:</b> <span>{form?.form_url.split("_")[1]}</span>
                </div>
              ) : null}
              {form ? (
                <div>
                  <b>Submitted:</b>{" "}
                  <span>
                    {new Date(form?.modified_at).toLocaleString(
                      "en-US",
                      options
                    )}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="file-actions">
              <Button className="upload-button">
                <CloudArrowUpIcon />
                <label className="button primary block" htmlFor={id}>
                  <div>{isUploading ? "Uploading..." : "Upload File"}</div>
                </label>
                <input
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                  }}
                  type="file"
                  id={id}
                  accept="application/pdf, image/*"
                  onChange={uploadImage}
                  disabled={isUploading || isDownloading}
                  required
                />
              </Button>
              {form ? (
                <Button
                  onClick={
                    isGettingSignedUrl
                      ? null
                      : () => downloadForm(form?.form_url, storageBucketName)
                  }
                >
                  <CloudArrowDownIcon />
                  {isGettingSignedUrl ? "Downloading..." : <>Download File</>}
                </Button>
              ) : null}
              {form ? (
                <Button onClick={isRemoving ? null : removeFile}>
                  <XCircleIcon />
                  {isRemoving ? "Removing..." : <>Remove File</>}
                </Button>
              ) : null}
            </div>
          </FieldList>
        </div>
      </Bubble>
    </section>
  );
}

export { UploadComponent };
