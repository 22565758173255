import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getReferenceInformationApi } from "@/modules/registration/api/reference_information";
import { Field } from "../../../common";

const ReferenceInformation = ({ customerId }) => {
  const [referenceInformation, setReferenceInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getReferenceInformation() {
      setLoading(true);
      const referenceInformation = await getReferenceInformationApi(customerId);
      setReferenceInformation(referenceInformation);
      setLoading(false);
    }
    getReferenceInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Reference Information</h2>
      <h3>Suppliers that are currently in-use</h3>
      <h4>Supplier 1</h4>
      <Field label="Name" value={referenceInformation?.supplier_1_name} />
      <Field label="Telephone" value={referenceInformation?.supplier_1_tel} />
      <h4>Supplier 2</h4>
      <Field label="Name" value={referenceInformation?.supplier_2_name} />
      <Field label="Telephone" value={referenceInformation?.supplier_2_tel} />
      <h4>Supplier 3</h4>
      <Field label="Name" value={referenceInformation?.supplier_3_name} />
      <Field label="Telephone" value={referenceInformation?.supplier_3_tel} />
    </section>
  );
};

export { ReferenceInformation };
