import { Link } from "react-router-dom";
import { Product } from "@/modules/core/components/gadgets";
import { BannerSection } from "./banner-section";
import { CarouselSection } from "./carousel-section";
import {
  BananaSpringRoll,
  BananaFritters,
  CocktailKaripap,
  Samosa,
} from "@/modules/img/food/snacks";
import {
  PandanChicken,
  ChickenLobak,
  DurianMochi,
  DimSumPau,
} from "@/modules/img/food/general";
import { TomatoSoup, PannaCotta, DimSum } from "@/modules/img/food/unsplash";
import { Campbell, Haco } from "@/modules/img/brands";
import "./home-page.css";

function Dashboard() {
  return (
    <div className="shop-panel">
      <div className="jumbotron">
        <BannerSection />
        <CarouselSection />
      </div>
      <div className="category-list">
        <section className="categories-section">
          <h2>Explore Popular Categories</h2>
          <ul className="list">
            <Link className="thumbnail" to="/market">
              <img className="responsive-image" src={TomatoSoup} alt="Soup" />
              <div className="thumbnail-text">Soups</div>
            </Link>
            <Link className="thumbnail" to="/market">
              <img
                className="responsive-image"
                src={PannaCotta}
                alt="Dessert"
              />
              <div className="thumbnail-text">Desserts</div>
            </Link>
            <Link className="thumbnail" to="/market">
              <img className="responsive-image" src={DimSum} alt="Snacks" />
              <div className="thumbnail-text">Snacks</div>
            </Link>
          </ul>
        </section>
        <section>
          <h2>Discover Trending Delights</h2>
          <div className="hot-items-list">
            <Link to="/market">
              <Product
                img={BananaSpringRoll}
                title="Spring Roll"
                description="Banana Spring Roll"
              />
            </Link>
            <Link to="/market">
              <Product
                img={CocktailKaripap}
                title="Karipap"
                description="Cocktail Karipap"
              />
            </Link>
            <Link to="/market">
              <Product img={Samosa} title="Samosa" description="Samosa" />
            </Link>
            <Link to="/market">
              <Product
                img={BananaFritters}
                title="Banana Fritters"
                description="Goreng Pisang"
              />
            </Link>
            <Link to="/market">
              <Product
                img={DurianMochi}
                title="Mochi"
                description="Durian Mochi"
              />
            </Link>
          </div>
        </section>
        <section className="categories-section">
          <div>
            <h2>
              <Link to="/categories" style={{ color: "inherit" }}>
                Explore by Categories
              </Link>
            </h2>
          </div>
          <div className="list">
            <Link className="thumbnail" to="/categories">
              <img
                className="responsive-image"
                src={PandanChicken}
                alt="Chicken"
              />
              <div className="thumbnail-text">Chicken</div>
            </Link>
            <Link className="thumbnail" to="/categories">
              <img
                className="responsive-image"
                src={ChickenLobak}
                alt="Local"
              />
              <div className="thumbnail-text">Local</div>
            </Link>
            <Link className="thumbnail" to="/categories">
              <img className="responsive-image" src={DimSumPau} alt="Buns" />
              <div className="thumbnail-text">Buns</div>
            </Link>
          </div>
        </section>
        <section className="brands-section">
          <h2>Browse by Brands</h2>
          <div className="list">
            <Link className="thumbnail" to="/brands">
              <img
                className="responsive-image"
                src={Campbell}
                alt="Campbell's"
              />
            </Link>
            <Link className="thumbnail" to="/brands">
              <img className="responsive-image" src={Haco} alt="HACO" />
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}
const HomePage = () => {
  return (
    <div className="home-page">
      <div className="home-page-body">
        <Dashboard />
      </div>
    </div>
  );
};

export { HomePage };
