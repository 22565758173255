const getCategorizedItems = (items, categoryMode) => {
  const selectedCategories = categoryMode.filter(
    (category) => category.value === true
  );
  const hasSelectedCategories = selectedCategories?.length > 0;
  if (hasSelectedCategories) {
    return items.filter((product) =>
      selectedCategories.some((category) =>
        product.STOCKGROUP?.toLowerCase().includes(category.name.toLowerCase())
      )
    );
  }
  return items;
};

const getSearchFilteredItems = (items, searchFilter) => {
  const hasSearchFilter = searchFilter?.length > 0;
  if (hasSearchFilter) {
    return items.filter((product) =>
      product?.DESCRIPTION?.toLowerCase().includes(searchFilter.toLowerCase())
    );
  }
  return items;
};

function filter(items, categoryMode, searchFilter) {
  const categorizedItems = getCategorizedItems(items, categoryMode);
  const searchFilteredItems = getSearchFilteredItems(
    categorizedItems,
    searchFilter
  );
  return searchFilteredItems;
}

export { filter };
