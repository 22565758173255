import { useSearchInput } from "./hooks";

import "./index.css";

const SearchBar = ({ originalUsers, setUsers }) => {
  const [searchInput, setSearchInput] = useSearchInput({
    originalUsers,
    setUsers,
  });
  function onSearchInputChange(e) {
    setSearchInput(e.target.value);
  }
  return (
    <input
      className="input"
      placeholder="Search by email..."
      value={searchInput}
      onChange={onSearchInputChange}
    />
  );
};

export { SearchBar };
