import { Outlet } from "react-router-dom";
import { AdminSidebar } from "../components/admin-sidebar";
import styles from "./admin-route-layout.module.css";

const AdminRouteLayout = () => {
  return (
    <>
      <div className={styles["admin-layout"]}>
        <AdminSidebar />
        <div className={styles["admin-display"]}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export { AdminRouteLayout };
