/**
 * Returns a list of stock group state objects.
 * Example:
 * {
 *  name: 'S_02_BEEF/DUCK/LAMB',
 *  value: false
 * }
 * @param {Array} stockgroups An array of stockgroups
 * @returns A stock group state object
 */
function initializeStockGroupState(stockgroups) {
  return stockgroups.map((e) => ({ name: e.STOCKGROUP, value: false }));
}

export { initializeStockGroupState };
