import { Form } from "./form";

const FormList = ({ customerId }) => {
  return (
    <section>
      <h2>Forms</h2>
      <h3>Submitted Documents</h3>
      <div
        style={{
          margin: "5px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <span>Form 9</span>
        <Form
          userId={customerId}
          storageBucketName="form-9"
          tableName="forms_form9"
        />
      </div>
      <div
        style={{
          margin: "5px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <span>Form 24</span>
        <Form
          userId={customerId}
          storageBucketName="form-24"
          tableName="forms_form24"
        />
      </div>
      <div
        style={{
          margin: "5px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <span>Form 49</span>
        <Form
          userId={customerId}
          storageBucketName="form-49"
          tableName="forms_form49"
        />
      </div>
    </section>
  );
};

export { FormList };
