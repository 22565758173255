import { useState } from "react";
import { toast } from "sonner";
import {
  Field,
  UncontrolledFieldGroup,
  PhoneNumberField,
  ApplicationLoadingIndicator,
} from "@/modules/core/components/ui";
import {
  Bubble,
  BubbleHeader,
  Divider,
  FieldList,
  Title,
} from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import {
  upsertOutletInformationApi,
  deleteOutletInformationApi,
} from "@/modules/registration/api/outlet_information";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { PhoneIcon } from "@heroicons/react/24/solid";

const KEYS = [
  "outlet_name",
  "address_line_1",
  "address_line_2",
  "postal_code",
  "city",
  "state",
  "country",
  "person_in_charge",
  "phone_number",
  "nature_of_business",
  "specification",
];
const OutletInformationForm = ({
  profile,
  outletInformation,
  setOutletInformation,
  onDeleteSuccess,
  disableDelete,
}) => {
  const [isSaving, setSaving] = useState(false);
  async function deleteOutlet() {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to delete ${outletInformation?.outlet_name || "Unnamed Outlet"}?`
      )
    ) {
      setSaving(true);
      await deleteOutletInformationApi({
        outlet_information_id: outletInformation?.outlet_information_id,
      });
      setTimeout(() => {
        setSaving(false);
        onDeleteSuccess();
        toast.success(
          "Successfully deleted outlet. Add a new outlet or continue below when ready."
        );
      }, 800);
    }
  }
  function handleSubmit(event) {
    event.preventDefault();

    let map = {};
    KEYS.forEach((e) => (map[e] = event.target[e].value));
    map["outlet_information_id"] = outletInformation?.outlet_information_id;
    map["user_id"] = profile?.id;
    console.log("map", map);
    async function insertOutlet(map) {
      setSaving(true);
      await upsertOutletInformationApi(map);
      setTimeout(() => {
        setSaving(false);
        toast.success("Outlet has been saved. Continue below when ready.");
      }, 800);
    }
    insertOutlet(map);
  }
  return (
    <form onSubmit={handleSubmit}>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Delivery Address</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <UncontrolledFieldGroup
            label="Outlet Name"
            formElement={
              <input
                name="outlet_name"
                onChange={(e) =>
                  setOutletInformation({
                    ...outletInformation,
                    outlet_name: e.target.value,
                  })
                }
                value={outletInformation?.outlet_name}
                placeholder="The Best Bakery"
                required
              />
            }
          />
          <Field
            name="address_line_1"
            label="Address Line 1"
            onChangeFn={(e) =>
              setOutletInformation({
                ...outletInformation,
                address_line_1: e.target.value,
              })
            }
            input={outletInformation?.address_line_1}
            placeholder="No. 44, Jalan Desa Melur 4/1"
            required
          />
          <Field
            name="address_line_2"
            label="Address Line 2"
            onChangeFn={(e) =>
              setOutletInformation({
                ...outletInformation,
                address_line_2: e.target.value,
              })
            }
            input={outletInformation?.address_line_2}
            placeholder="Taman Bandar Connaught"
          />
          <TwoColumn>
            <Field
              name="postal_code"
              label="Postal Code"
              onChangeFn={(e) =>
                setOutletInformation({
                  ...outletInformation,
                  postal_code: e.target.value,
                })
              }
              input={outletInformation?.postal_code}
              placeholder="56000"
              required
            />
            <Field
              name="city"
              label="City"
              onChangeFn={(e) =>
                setOutletInformation({
                  ...outletInformation,
                  city: e.target.value,
                })
              }
              input={outletInformation?.city}
              placeholder="Cheras"
              required
            />
          </TwoColumn>
          <TwoColumn>
            <Field
              name="state"
              label="State"
              onChangeFn={(e) =>
                setOutletInformation({
                  ...outletInformation,
                  state: e.target.value,
                })
              }
              input={outletInformation?.state}
              placeholder="Kuala Lumpur"
              required
            />
            <Field
              name="country"
              label="Country"
              onChangeFn={(e) =>
                setOutletInformation({
                  ...outletInformation,
                  country: e.target.value,
                })
              }
              input={outletInformation?.country}
              placeholder="Malaysia"
              required
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <PhoneIcon />
            <h3>Contact Info</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <UncontrolledFieldGroup
            label="Person in Charge"
            formElement={
              <input
                name="person_in_charge"
                onChange={(e) =>
                  setOutletInformation({
                    ...outletInformation,
                    person_in_charge: e.target.value,
                  })
                }
                value={outletInformation?.person_in_charge}
                placeholder="Adam Smith"
                required
              />
            }
          />
          <PhoneNumberField
            label="Mobile Number"
            name="phone_number"
            onChange={(value) =>
              setOutletInformation({
                ...outletInformation,
                phone_number: value,
              })
            }
            value={outletInformation?.phone_number}
            required
          />
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Type</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <UncontrolledFieldGroup
            label="Category"
            formElement={
              <select
                name="nature_of_business"
                value={outletInformation?.nature_of_business}
                onChange={(e) =>
                  setOutletInformation({
                    ...outletInformation,
                    nature_of_business: e.target.value,
                  })
                }
              >
                <option>Hotel/Restaurant/Cafe</option>
                <option>Caterer</option>
                <option>Bakery/Central Kitchen</option>
                <option>Online Seller</option>
                <option>Burger Boy</option>
                <option>Dist/W-saler/Mini Market</option>
                <option>Others</option>
              </select>
            }
          />
          <UncontrolledFieldGroup
            label="If 'Others', Please Describe"
            formElement={
              <input
                name="specification"
                onChange={(e) =>
                  setOutletInformation({
                    ...outletInformation,
                    specification: e.target.value,
                  })
                }
                value={outletInformation?.specification}
                placeholder="e.g. School, Canteen"
              />
            }
          />
        </FieldList>
      </Bubble>
      <div className="form-footer">
        {!disableDelete && (
          <button type="button" onClick={deleteOutlet}>
            Delete
          </button>
        )}
        <button type="submit">Save</button>
      </div>
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
};

export { OutletInformationForm };
