import { Croissant, Shield, Wheat } from "lucide-react";
import "./features-section.css";

const features = [
  {
    icon: <Croissant />,
    label: "Fresh Aisles, Fresh Smiles!",
    description:
      "Welcome to our grocery haven, where clean aisles and fresh produce await. Shop with a smile, knowing hygiene is our top priority.",
  },
  {
    icon: <Shield />,
    label: "Hygiene and Safety Standards",
    description:
      "Our commitment to hygiene and safety standards ensures every bite is not just delicious but also a testament to our unwavering dedication to your well-being.",
  },
  {
    icon: <Wheat />,
    label: "Shelf Confidence, No Compromise!",
    description:
      "Stocking shelves with confidence, no compromise on cleanliness. Every product is placed with care, assuring you a shopping experience that's as clean as it is convenient.",
  },
];

const Feature = ({ icon, label, description }) => {
  return (
    <div className="feature">
      <div className="icon-wrapper">{icon}</div>
      <div>
        <p className="label">{label}</p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
const FeaturesSection = () => {
  return (
    <section className="features-section" id="features">
      {features?.map((feature) => (
        <Feature
          icon={feature?.icon}
          label={feature?.label}
          description={feature?.description}
        />
      ))}
    </section>
  );
};

export { FeaturesSection };
