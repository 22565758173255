import "./index.css";

const InfoList = ({ primaryText, secondaryText }) => {
  return (
    <div className="info-list">
      <div className="primary">{primaryText}</div>
      <div className="secondary">{secondaryText}</div>
    </div>
  );
};

export { InfoList };
