import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { Divider, FormFooter } from "../../../components/ui";
import { BusinessInfoSection } from "../business-info-section";
import { BusinessAddressSection } from "../business-address-section";
import { BusinessDetailsSection } from "../business-details-section";
import { ContactInformationSection } from "../contact-information-section";
import { insertBusinessRegistrationApi } from "@/modules/registration/api/business_registration";
import { throwErrorWarning } from "@/modules/registration/utils";

const KEYS = [
  "business_name",
  "address_line_1",
  "address_line_2",
  "postal_code",
  "city",
  "state",
  "country",

  "business_email",
  "website",
  "type_of_business",
  "business_registration_number",
  "date_incorporated",

  "gst_registration_number",
  "authorised_capital",
  "paid_up_capital",

  "name_of_md_or_gm",
  "name_of_purchasing_manager",
  "email_of_md_or_gm",
  "fax_of_md_or_gm",
  "phone_number_of_md_or_gm",
];
const BusinessRegistrationForm = ({
  businessRegistration,
  setBusinessRegistration,
  profile,
}) => {
  const navigate = useNavigate();
  const [isSaving, setSaving] = useState(false);
  function onBackClick() {
    navigate("/application/introduction");
  }
  function handleSubmit(event) {
    event.preventDefault();
    let map = {};
    KEYS.forEach((e) => (map[e] = event.target[e].value));
    map["user_id"] = profile?.id;
    console.log("map", map);

    async function insertBusinessRegistration(map) {
      setSaving(true);
      const { error } = await insertBusinessRegistrationApi(map);
      if (error) {
        throwErrorWarning(error.code);
        setSaving(false);
        return;
      }
      setTimeout(() => {
        setSaving(false);
        navigate("/application/horeca/business-registration-upload-forms");
      }, 800);
    }
    insertBusinessRegistration(map);
  }
  return (
    <form onSubmit={handleSubmit}>
      <BusinessInfoSection
        businessRegistration={businessRegistration}
        setBusinessRegistration={setBusinessRegistration}
      />
      <Divider />
      <BusinessAddressSection
        businessRegistration={businessRegistration}
        setBusinessRegistration={setBusinessRegistration}
      />
      <Divider />
      <BusinessDetailsSection
        businessRegistration={businessRegistration}
        setBusinessRegistration={setBusinessRegistration}
      />
      <Divider />
      <ContactInformationSection
        businessRegistration={businessRegistration}
        setBusinessRegistration={setBusinessRegistration}
      />
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
};

export { BusinessRegistrationForm };
