import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getDeclarationApi } from "@/modules/registration/api/declaration";

const Declaration = ({ customerId }) => {
  const [declaration, setDeclaration] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getDeclaration() {
      setLoading(true);
      const declaration = await getDeclarationApi(customerId);
      setDeclaration(declaration);
      setLoading(false);
    }
    getDeclaration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Declaration</h2>
      <h3>{declaration?.name}</h3>
      <p>
        {declaration?.position}, signed at {declaration?.date}
      </p>
    </section>
  );
};

export { Declaration };
