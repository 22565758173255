import { FiEdit3 as EditIcon } from "react-icons/fi";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { Button } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { BusinessForm } from "./business-form";

const BusinessFormDetails = ({ title, children }) => {
  return (
    <Bubble>
      <BubbleHeader>
        <Title>
          <DocumentTextIcon />
          <h4>{title}</h4>
        </Title>
      </BubbleHeader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          fontWeight: 600,
        }}
      >
        {children}
      </div>
    </Bubble>
  );
};

const BusinessFormsSection = ({ customerId }) => {
  return (
    <section>
      <div className="heading">
        <Title>
          <CloudArrowUpIcon />
          <h2>Forms</h2>
        </Title>
        <Button href="/application/horeca/business-registration-upload-forms">
          <EditIcon />
          Edit
        </Button>
      </div>
      <FieldList>
        <BusinessFormDetails title="Form 9">
          <BusinessForm
            userId={customerId}
            storageBucketName="form-9"
            tableName="forms_form9"
          />
        </BusinessFormDetails>
        <BusinessFormDetails title="Form 24">
          <BusinessForm
            userId={customerId}
            storageBucketName="form-24"
            tableName="forms_form24"
          />
        </BusinessFormDetails>
        <BusinessFormDetails title="Form 49">
          <BusinessForm
            userId={customerId}
            storageBucketName="form-49"
            tableName="forms_form49"
          />
        </BusinessFormDetails>
      </FieldList>
    </section>
  );
};

export { BusinessFormsSection };
