import { TransitionWrapper } from "@/modules/core/components/ui";
import { Card } from "./card";

import "./index.css";

function List({ users, deleteUser, onDeleteSuccess }) {
  return (
    <div className="list">
      {users?.map((user, idx) => (
        <TransitionWrapper index={idx}>
          <Card
            key={user?.id}
            {...user}
            deleteUser={deleteUser}
            onDeleteSuccess={onDeleteSuccess}
          />
          <hr />
        </TransitionWrapper>
      ))}
    </div>
  );
}
const Lists = ({ users, deleteUser, onDeleteSuccess }) => {
  return (
    <List
      users={users}
      deleteUser={deleteUser}
      onDeleteSuccess={onDeleteSuccess}
    />
  );
};

export { Lists };
