import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ApplicationLoadingIndicator,
  LoadingIndicator,
  Field as FormField,
} from "@/modules/core/components/ui";
import {
  getRetailStaffFormApi,
  insertRetailStaffFormApi,
} from "@/modules/registration/api/retail_staff_form";
import { updateApplicationStatusApi } from "@/modules/registration/api/application";
import {
  getSQLAccountingTerms,
  getSQLAccountingAgents,
  getSQLAccountingPriceTags,
} from "@/modules/registration/api/horeca_staff_form";
import {
  verifyFields,
  verifyRemarkField,
  createMap,
  getPlaceholder,
  getRemarkPlaceholder,
} from "@/modules/registration/utils";
import { INITIAL_VALUE } from "./config";

const RetailStaffForm = ({ application, customerId, onFormSubmitCallback }) => {
  const KEYS = Object.keys(INITIAL_VALUE);
  // eslint-disable-next-line no-sequences
  const [staffForm, setStaffForm] = useState(INITIAL_VALUE);
  const [terms, setTerms] = useState([]);
  const [agents, setAgents] = useState([]);
  const [priceTags, setPriceTags] = useState([]);
  const [isStaffFormLoading, setIsStaffFormLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isTermsLoading, setIsTermsLoading] = useState(false);
  const [isAgentsLoading, setIsAgentsLoading] = useState(false);
  const [isPriceTagsLoading, setIsPriceTagsLoading] = useState(false);

  async function getTerms() {
    setIsTermsLoading(true);
    const terms = await getSQLAccountingTerms();
    setTerms(terms);
    setIsTermsLoading(false);
  }

  async function getAgents() {
    setIsAgentsLoading(true);
    const agents = await getSQLAccountingAgents();
    setAgents(agents);
    setIsAgentsLoading(false);
  }

  async function getPriceTags() {
    setIsPriceTagsLoading(true);
    const priceTags = await getSQLAccountingPriceTags();
    setPriceTags(priceTags);
    setIsPriceTagsLoading(false);
  }

  async function getStaffForm() {
    setIsStaffFormLoading(true);
    const staffForm = await getRetailStaffFormApi(customerId);
    setStaffForm({ ...INITIAL_VALUE, ...staffForm });
    setIsStaffFormLoading(false);
  }

  useEffect(() => {
    getStaffForm();
    getTerms();
    getAgents();
    getPriceTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles form submission
   * @param {*} event Event
   * @param {*} status 'pending-review' || 'approved' || 'rejected'
   */
  async function handleSubmit(event, status) {
    event.preventDefault();
    /**
     * If approve, populate map with existing values.
     * If disapprove, populate map with empty values.
     * @param {*} type The type of status
     * @returns
     */

    /* Return rejected applicant form values based on whether a column is nullable or not. */
    const getRejectedRetailMapValues = (key, staffForm) => {
      if (key === "remark") return staffForm?.remark;
      else if (["price_tag", "agent", "term"].includes(key)) return null;
      return "";
    };

    if (status === "approved") {
      // eslint-disable-next-line
      if (confirm("Are you sure you want to approve this application?")) {
        const map = createMap(
          "approved",
          KEYS,
          staffForm,
          customerId,
          getRejectedRetailMapValues
        );
        const isVerified = verifyFields(KEYS, staffForm);
        if (isVerified) {
          setSaving(true);
          await Promise.all([
            insertRetailStaffFormApi(map),
            updateApplicationStatusApi(customerId, "approved"),
          ]);
          setSaving(false);
          // getStaffForm();
          onFormSubmitCallback();
        }
      }
    } else if (status === "rejected") {
      // eslint-disable-next-line
      if (confirm("Are you sure you want to reject this application?")) {
        const map = createMap(
          "rejected",
          KEYS,
          staffForm,
          customerId,
          getRejectedRetailMapValues
        );
        const hasRemark = verifyRemarkField(staffForm);
        if (hasRemark) {
          setSaving(true);
          await Promise.all([
            insertRetailStaffFormApi(map),
            updateApplicationStatusApi(customerId, "rejected"),
          ]);
          setSaving(false);
          // getStaffForm();
          onFormSubmitCallback();
        }
      }
    } else if (status === "pending-review") {
      if (
        // eslint-disable-next-line
        confirm("Are you sure you want to revert this application to pending?")
      ) {
        const map = createMap(
          "pending-review",
          KEYS,
          staffForm,
          customerId,
          getRejectedRetailMapValues
        );
        setSaving(true);
        await Promise.all([
          insertRetailStaffFormApi(map),
          updateApplicationStatusApi(customerId, "pending-review"),
        ]);
        setSaving(false);
        // getStaffForm();
        onFormSubmitCallback();
      }
    }
  }

  function Buttons() {
    switch (application?.status) {
      case "pending-review":
        return (
          <div className="action-buttons">
            <button
              className="staff-form-btn"
              style={{ backgroundColor: "var(--font-grey)" }}
              type="btn"
              onClick={(e) => handleSubmit(e, "rejected")}
            >
              Reject
            </button>
            <button
              className="staff-form-btn"
              type="btn"
              onClick={(e) => handleSubmit(e, "approved")}
            >
              Approve
            </button>
          </div>
        );
      case "approved":
        return (
          <div className="action-buttons">
            <button
              className="staff-form-btn"
              style={{ backgroundColor: "var(--font-grey)" }}
              type="btn"
              onClick={(e) => handleSubmit(e, "pending-review")}
            >
              Undo Approval
            </button>
            <button
              className="staff-form-btn"
              style={{ backgroundColor: "var(--font-grey)" }}
              type="btn"
              onClick={(e) => handleSubmit(e, "rejected")}
            >
              Reject
            </button>
          </div>
        );
      case "rejected":
        return (
          <div>
            <button
              className="staff-form-btn"
              style={{ backgroundColor: "var(--font-grey)" }}
              type="btn"
              onClick={(e) => handleSubmit(e, "pending-review")}
            >
              Undo Rejection
            </button>
            <button
              className="staff-form-btn"
              type="btn"
              onClick={(e) => handleSubmit(e, "approved")}
            >
              Approve
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  const disableField =
    application?.status === "approved" || application?.status === "rejected";
  const isLoading =
    isStaffFormLoading ||
    isTermsLoading ||
    isAgentsLoading ||
    isPriceTagsLoading;

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section className="retail-staff-form-section">
      <h2>For Staff Use</h2>
      <blockquote>
        If all the information is adequate, please proceed to fill out the
        following criteria.
      </blockquote>
      <form>
        <FormField
          name="account_number"
          label="Account Number"
          onChangeFn={(e) =>
            setStaffForm({ ...staffForm, account_number: e.target.value })
          }
          input={staffForm?.account_number}
          placeholder={getPlaceholder(application?.status, "001234234")}
          required
          disabled={disableField}
        />
        <FormField
          name="sales_person_code"
          label="Sales Person's Code"
          onChangeFn={(e) =>
            setStaffForm({ ...staffForm, sales_person_code: e.target.value })
          }
          input={staffForm?.sales_person_code}
          placeholder={getPlaceholder(application?.status, "555")}
          required
          disabled={disableField}
        />
        <FormField
          name="submitted_by"
          label="Submitted by"
          onChangeFn={(e) =>
            setStaffForm({ ...staffForm, submitted_by: e.target.value })
          }
          input={staffForm?.submitted_by}
          placeholder={getPlaceholder(application?.status, "Adam Smith")}
          required
          disabled={disableField}
        />
        <FormField
          name="approved_by"
          label="Approved by"
          onChangeFn={(e) =>
            setStaffForm({ ...staffForm, approved_by: e.target.value })
          }
          input={staffForm?.approved_by}
          placeholder={getPlaceholder(application?.status, "Mr. Staff")}
          required
          disabled={disableField}
        />
        <FormField
          name="date_submitted"
          label="Date"
          onChangeFn={(e) =>
            setStaffForm({ ...staffForm, date_submitted: e.target.value })
          }
          input={staffForm?.date_submitted}
          placeholder={getPlaceholder(
            application?.status,
            new Date().toLocaleDateString()
          )}
          required
          disabled={disableField}
        />
        <FormField
          name="remark"
          label="Remark"
          onChangeFn={(e) =>
            setStaffForm({ ...staffForm, remark: e.target.value })
          }
          input={staffForm?.remark}
          placeholder={getRemarkPlaceholder(
            application?.status,
            staffForm,
            "Has good credit history."
          )}
          required
          disabled={disableField}
        />
        <div className="field">
          <label>Price Tag</label>
          <select
            required
            name="price_tag"
            value={staffForm?.price_tag}
            onChange={(e) =>
              setStaffForm({ ...staffForm, price_tag: e.target.value })
            }
            disabled={disableField}
          >
            <option value="">Please select</option>
            {priceTags
              .filter((priceTag) => priceTag.CODE.substring(0, 2) === "P_")
              .map((priceTag) => (
                <option value={priceTag.CODE}>{priceTag.CODE}</option>
              ))}
          </select>
        </div>
        <div className="field">
          <label>Agent</label>
          <select
            required
            name="agent"
            value={staffForm?.agent}
            onChange={(e) =>
              setStaffForm({ ...staffForm, agent: e.target.value })
            }
            disabled={disableField}
          >
            <option value="">Please select</option>
            {agents
              .filter((agent) => agent.CODE.substring(0, 2) === "P_")
              .map((agent) => (
                <option value={agent.CODE}>{agent.CODE}</option>
              ))}
          </select>
        </div>
        <div className="field">
          <label>Credit Terms</label>
          <select
            required
            name="term"
            value={staffForm?.term}
            onChange={(e) =>
              setStaffForm({ ...staffForm, term: e.target.value })
            }
            disabled={disableField}
          >
            <option value="">Please select</option>
            {terms
              .filter((term) => term.CODE.substring(0, 2) === "P_")
              .map((term) => (
                <option value={term.CODE}>{term.CODE}</option>
              ))}
          </select>
        </div>
        <div className="staff-form-footer">
          <Link to="/manage-applications">Back</Link>
          <Buttons />
        </div>
        {isSaving && <ApplicationLoadingIndicator />}
      </form>
    </section>
  );
};

export { RetailStaffForm };
