import { Field, PhoneNumberField } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { UserIcon } from "@heroicons/react/24/solid";

function Supplier({
  name_id,
  name_label,
  name_onChange,
  name_input,
  name_placeholder,
  tel_id,
  tel_label,
  tel_onChange,
  tel_value,
  required,
}) {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <UserIcon />
            <h3>Supplier</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name={name_id}
            label={name_label}
            onChangeFn={name_onChange}
            input={name_input}
            placeholder={name_placeholder}
            required={required}
          />
          <PhoneNumberField
            label={tel_label}
            name={tel_id}
            onChange={tel_onChange}
            value={tel_value}
            required={required}
          />
        </FieldList>
      </Bubble>
    </section>
  );
}

export { Supplier };
