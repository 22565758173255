import { LoadingIndicator } from "@/modules/core/components/ui";
import "./index.css";

const Loader = () => {
  return (
    <div className="loader">
      <LoadingIndicator center type="small" />
    </div>
  );
};

export { Loader };
