import { GiKnifeFork as FoodIcon } from "react-icons/gi";
import "./food-placeholder.css";

const FoodPlaceholder = ({ size }) => {
  switch (size) {
    case "small":
      size = 72;
      break;
    case "large":
    default:
      size = 128;
      break;
  }
  return (
    <div className="food-placeholder">
      <FoodIcon size={size} />
    </div>
  );
};

export { FoodPlaceholder };
