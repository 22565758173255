import Logo from "@/modules/img/logo/lotus-consulting-group-logo-cropped.png";
import "./splash-page.css";

function SplashPage() {
  return (
    <div className="splash-page">
      <img className="logo" src={Logo} alt="logo" />
      <p>delivering quality products near you</p>
    </div>
  );
}

export { SplashPage };
