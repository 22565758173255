import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRouteLayout } from "@/modules/core/components/layouts";
import { NoMatchPage } from "@/modules/core/components/pages";
import { AddressSelector } from "@/modules/core/components/gadgets/address-selector";
import { PrintInvoicePage, UpdatePasswordPage } from "@/modules/customer/pages";
import {
  authRoutes,
  publicRoutes,
  registrationRoutes,
  adminRoutes,
  customerRoutes,
} from "./routes";
import { getOneProfileById } from "../core/api/users";
import { getCurrentSession } from "../core/api/auth";
import { useAnalytics } from "@/modules/analytics";
import { useScrollToTop } from "../core/hooks";
import "./app.css";

function App() {
  const [auth, setAuth] = useState(undefined);
  async function initializeAuth({ byPassTimeout } = { byPassTimeout: false }) {
    const session = await getCurrentSession();
    const profile = await getOneProfileById(session?.user?.id);
    const auth = { session, profile };
    console.log("auth", auth);
    if (byPassTimeout) {
      setAuth(auth);
    } else {
      setTimeout(() => {
        setAuth(auth);
      }, 800);
    }
  }
  function ReRoute() {
    if (auth?.profile?.isAdmin)
      return <Navigate to="/admin/dashboard" replace />;
    switch (auth?.profile?.status) {
      case "pending-review":
        return <Navigate to="/application/pending-approval" replace />;
      case "approved":
        return <Navigate to="/home" replace />;
      case "rejected":
        return <Navigate to="/application/rejected" replace />;
      default:
        return <Navigate to="/application/introduction" replace />;
    }
  }
  function getAccountType({ auth }) {
    const { profile } = auth || {};
    const { status, isAdmin } = profile || {};
    if (status === "approved" && isAdmin === false) return "APPROVED_CUSTOMER";
    if (isAdmin) return "ADMIN";
    return null;
  }

  /**
   * On entry of app, initialize a session with supabase. Once initialized, load user profile.
   * Once session and user profile has been established, load routes.
   */
  useEffect(() => {
    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useAnalytics();
  useScrollToTop();

  return (
    <Routes>
      {publicRoutes}
      {authRoutes({ auth, loginCallback: initializeAuth })}
      <Route
        path="/"
        element={
          <PrivateRouteLayout
            session={auth?.session}
            profile={auth?.profile}
            initializeAuth={initializeAuth}
          />
        }
      >
        {getAccountType({ auth }) === "APPROVED_CUSTOMER" &&
          customerRoutes({ auth })}
        {getAccountType({ auth }) === "ADMIN" && adminRoutes({ auth })}
        {registrationRoutes({ auth, initializeAuth, ReRoute })}
        <Route path="print-invoice">
          <Route
            path=":orderId"
            element={<PrintInvoicePage profile={auth?.profile} />}
          />
        </Route>
        <Route
          path="/profile/update-password"
          element={<UpdatePasswordPage />}
        />
        <Route path="*" element={<ReRoute />} />
      </Route>
      <Route
        path="playground"
        element={
          <div style={{ padding: 24 }}>
            <AddressSelector
              onManageAddressesClick={() => alert("/addresses")}
              onBackClick={() => alert("back")}
              onSaveClick={(id) => alert(id)}
            />
          </div>
        }
      />
      <Route
        path="*"
        element={
          <NoMatchPage session={auth?.session} profile={auth?.profile} />
        }
      />
    </Routes>
  );
}

export { App };
