import { Breadcrumbs } from "@/modules/core/components/ui";
import { ProductsInterface } from "@/modules/core/components/gadgets";
import "./manage-products-page.css";

function ManageProductsPage({ profile }) {
  const breadcrumbs = [
    {
      text: "Manage Products",
      type: "text",
    },
  ];
  return (
    <div className="manage-products-page">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ProductsInterface profile={profile} managementMode="admin" />
    </div>
  );
}

export { ManageProductsPage };
