import { supabase } from "@/modules/supabase";

const getPaymentsApi = async (cart_id) => {
  const { data, error } = await supabase
    .from("payments")
    .select()
    .match({ cart_id });
  console.log("get payments api", data);
  console.log("get payments api error", error);
  return data;
};

/**
 * Inserts a payment of type 'pay-now'
 * A payment of type 'pay-now' is defined as having a customer_id, cart_id, and type of 'pay-now'
 * @param {UUID} customer_id The identity of the customer
 * @param {UUID} cart_id The identity of the cart
 * @returns
 */
const insertPaymentPayNowApi = async (customer_id, cart_id) => {
  const { data, error } = await supabase
    .from("payments")
    .insert({ customer_id, cart_id, type: "pay-now" })
    .select();
  console.log("insertPaymentPayNowApi", data);
  console.log("insertPaymentPayNowApi error", error);
  return data?.[0];
};

/**
 * Inserts a payment of type 'credit'.
 * A payment of type 'credit' is defined as having customer_id, orders[], and type of 'credit'.
 * @param {UUID} customer_id The identity of the customer
 * @param {Array} orders Array of orders
 * @returns
 */
const insertPaymentCreditApi = async (customer_id, orders) => {
  const { data, error } = await supabase
    .from("payments")
    .insert({ customer_id, orders, type: "credit" })
    .select();
  console.log("insertPaymentCreditApi", data);
  console.log("insertPaymentCreditApi error", error);
  return data?.[0];
};

export { getPaymentsApi, insertPaymentPayNowApi, insertPaymentCreditApi };
