import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FiMenu as MenuIcon } from "react-icons/fi";
import { ShoppingBagIcon as CartIcon } from "@heroicons/react/24/solid";
import { Product, DropDownMenu } from "..";
import { LogoTransparent as Logo } from "@/modules/img/logo";
import { DurianMochi } from "@/modules/img/food/general";
import {
  BananaFritters,
  BananaSpringRoll,
  CocktailKaripap,
  Samosa,
} from "@/modules/img/food/snacks";
import { DimSum, PannaCotta, TomatoSoup } from "@/modules/img/food/unsplash";
import "./private-nav-bar.css";

const CartButton = () => {
  return (
    <div className="cart-button">
      <div className="cart-icon">
        <CartIcon />
      </div>
    </div>
  );
};

function useClickEffect(menuRef, isOpen, setOpen) {
  useEffect(() => {
    /**
     * If drop down menu is closed, click on the profile icon to open it.
     * Otherwise, if drop down menu is open, click the close button
     * or anywhere outside the menu
     */
    function handleClickOutside(event) {
      const isMenuRefClicked = menuRef?.current.contains(event.target);
      if (!isMenuRefClicked) setOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuRef, isOpen, setOpen]);
}

function ShopPanel({ setOpenMarket }) {
  return (
    <div className="shop-panel" onMouseLeave={() => setOpenMarket(false)}>
      <section>
        <h2>Explore Popular Categories</h2>
        <ul className="category-list">
          <Link
            className="category-thumbnail"
            to="/market"
            onClick={() => setOpenMarket(false)}
          >
            <img className="responsive-image" src={TomatoSoup} alt="Soup" />
            <div className="category-thumbnail-text">Soups</div>
          </Link>
          <Link
            className="category-thumbnail"
            to="/market"
            onClick={() => setOpenMarket(false)}
          >
            <img className="responsive-image" src={PannaCotta} alt="Dessert" />
            <div className="category-thumbnail-text">Desserts</div>
          </Link>
          <Link
            className="category-thumbnail"
            to="/market"
            onClick={() => setOpenMarket(false)}
          >
            <img className="responsive-image" src={DimSum} alt="Snacks" />
            <div className="category-thumbnail-text">Snacks</div>
          </Link>
        </ul>
      </section>
      <section>
        <h2>Discover Trending Delights</h2>
        <div className="hot-items-list">
          <Link to="/market" onClick={() => setOpenMarket(false)}>
            <Product
              img={BananaSpringRoll}
              title="Spring Roll"
              description="Banana Spring Roll"
            />
          </Link>
          <Link to="/market" onClick={() => setOpenMarket(false)}>
            <Product
              img={CocktailKaripap}
              title="Karipap"
              description="Cocktail Karipap"
            />
          </Link>
          <Link to="/market" onClick={() => setOpenMarket(false)}>
            <Product img={Samosa} title="Samosa" description="Samosa" />
          </Link>
          <Link to="/market" onClick={() => setOpenMarket(false)}>
            <Product
              img={BananaFritters}
              title="Banana Fritters"
              description="Goreng Pisang"
            />
          </Link>
          <Link to="/market" onClick={() => setOpenMarket(false)}>
            <Product
              img={DurianMochi}
              title="Mochi"
              description="Durian Mochi"
            />
          </Link>
        </div>
      </section>
      <div className="all-products">
        <Link to="/market" onClick={() => setOpenMarket(false)}>
          See all products
        </Link>
      </div>
    </div>
  );
}
function PrivateNavBar({ profile, initializeAuth }) {
  const [isOpen, setOpen] = useState(false);
  const [openMarket, setOpenMarket] = useState(false);
  const menuRef = useRef(null);
  useClickEffect(menuRef, isOpen, setOpen);

  return (
    <nav className="private-nav-bar">
      <ul>
        <Link to="/home" className="header-logo">
          <img className="logo" src={Logo} alt="logo" />
        </Link>
      </ul>
      {profile?.status === "approved" && !profile?.isAdmin && (
        <ul className="private-nav-bar-menu">
          <li onClick={() => setOpenMarket(false)}>
            <Link to="/discover">Discover</Link>
          </li>
          <li
            // onMouseEnter={() => setOpenMarket(true)}
            onClick={() => setOpenMarket(false)}
          >
            <Link to="/market">Shop</Link>
          </li>
          <li onClick={() => setOpenMarket(false)}>
            <Link to="/categories">Categories</Link>
          </li>
          <li onClick={() => setOpenMarket(false)}>
            <Link to="/brands">Brands</Link>
          </li>
        </ul>
      )}
      <ul
        className="private-nav-bar-buttons-list"
        style={{ position: "relative" }}
      >
        {profile?.status === "approved" && !profile?.isAdmin && (
          <li className="private-nav-bar-buttons-list-item">
            <Link to="/cart">
              <CartButton />
            </Link>
          </li>
        )}
        <li
          className="private-nav-bar-buttons-list-item"
          onClick={() => setOpen(true)}
        >
          <div className="menu-button">
            <MenuIcon className="menu-icon" />
          </div>
        </li>
        <DropDownMenu
          profile={profile}
          isOpen={isOpen}
          setOpen={setOpen}
          menuRef={menuRef}
          initializeAuth={initializeAuth}
        />
      </ul>
      {openMarket && <ShopPanel setOpenMarket={setOpenMarket} />}
    </nav>
  );
}

export { PrivateNavBar };
