import { Link, useLocation } from "react-router-dom";
import styles from "./navigation-bar.module.css";
import { clsx } from "clsx";

const NavigationBar = ({ pages }) => {
  const location = useLocation();
  return (
    <nav className={styles["navigation-bar"]}>
      <ul>
        {pages.map((page, idx) => (
          <li
            key={page.text}
            className={clsx(
              location.pathname === page.href && styles["highlight"]
            )}
            style={{ marginLeft: page.leftPadding ? 5 : null }}
          >
            <Link to={page.href}>
              <span className="sequence-number">{page.id || idx}.</span>{" "}
              <span className="text">{page.text}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationBar;
