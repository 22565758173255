import { toast } from "sonner";
import { SUPABASE_ERROR_CODES } from "@/modules/supabase";

const throwErrorWarning = (errorCode) => {
  switch (errorCode) {
    case SUPABASE_ERROR_CODES.INVALID_DATA_TYPE:
      toast.warning("Data failed to save.", {
        description: "Please ensure all fields contain valid data types.",
        position: "top-center",
        duration: 6000,
      });
      break;
    default:
      toast.warning("Error occurred", {
        position: "top-center",
        duration: 6000,
      });
  }
};

export { throwErrorWarning };
