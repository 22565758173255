import { supabase } from "@/modules/supabase";

const getTermsAndConditionsApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_terms_and_conditions")
    .select()
    .match({ user_id });
  console.log("get forms_terms_and_conditions api", data);
  console.log("get forms_terms_and_conditions api error", error);
  return data?.[0];
};

const insertTermsAndConditionsApi = async (user_id, is_signed) => {
  const { data, error } = await supabase
    .from("forms_terms_and_conditions")
    .upsert({ user_id, is_signed })
    .select();
  console.log("insert forms_terms_and_conditions api", data);
  console.log("insert forms_terms_and_conditions api error", error);
  return data?.[0];
};

export { getTermsAndConditionsApi, insertTermsAndConditionsApi };
