import {
  Bubble,
  BubbleHeader,
  Divider,
  FieldList,
  ReviewField,
  Title,
} from "../../../../components/ui";
import { TwoColumn } from "@/modules/registration/components/layouts";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { PhoneIcon } from "@heroicons/react/24/solid";

const OutletInformation = ({ outletInformation }) => {
  return (
    <div>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Delivery Address</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField label="Name" value={outletInformation?.outlet_name} />
          <ReviewField
            label="Address Line 1"
            value={outletInformation?.address_line_1}
          />
          <ReviewField
            label="Address Line 2"
            value={outletInformation?.address_line_2}
          />
          <TwoColumn>
            <ReviewField
              label="Postal Code"
              value={outletInformation?.postal_code}
            />
            <ReviewField label="City" value={outletInformation?.city} />
          </TwoColumn>
          <TwoColumn>
            <ReviewField label="State" value={outletInformation?.state} />
            <ReviewField label="Country" value={outletInformation?.country} />
          </TwoColumn>
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <PhoneIcon />
            <h3>Contact Info</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Person in Charge"
            value={outletInformation?.person_in_charge}
          />
          <ReviewField
            label="Phone Number"
            value={outletInformation?.phone_number}
          />
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Type</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Category"
            value={outletInformation?.nature_of_business}
          />
          <ReviewField
            label="Specification"
            value={outletInformation?.specification}
          />
        </FieldList>
      </Bubble>
    </div>
  );
};

export { OutletInformation };
