import "./transition-wrapper.css";

const TransitionWrapper = ({ children, index }) => {
  return (
    <div
      className="transition-wrapper"
      style={{
        animationDuration: index > 10 ? "1.0s" : `${0.2 + index * 0.1}s`,
      }}
    >
      {children}
    </div>
  );
};

export { TransitionWrapper };
