import { supabase } from "@/modules/supabase";

const getOutletsApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_outlet_information")
    .select()
    .match({ user_id })
    .order("outlet_information_id", { ascending: true });
  console.log("getOutletsApi", data);
  console.log("getOutletsApi error", error);
  return data;
};

const getOutletApi = async (outlet_information_id) => {
  const { data, error } = await supabase
    .from("forms_outlet_information")
    .select()
    .match({ outlet_information_id });
  console.log("getOutletApi api", data);
  console.log("getOutletApi api error", error);
  return data?.[0];
};

const insertOutletInformationApi = async ({
  outlet_information_id,
  outlet_name = "",
  address_line_1 = "",
  address_line_2 = "",
  postal_code = "",
  city = "",
  state = "",
  country = "",
  person_in_charge = "",
  phone_number = "",
  nature_of_business = "",
  specification = "",
  user_id,
}) => {
  const { data, error } = await supabase
    .from("forms_outlet_information")
    .insert({
      outlet_information_id,
      outlet_name,
      address_line_1,
      address_line_2,
      postal_code,
      city,
      state,
      country,
      person_in_charge,
      phone_number,
      nature_of_business,
      specification,
      user_id,
      date_modified: new Date(),
    })
    .select();
  console.log("insertOutletInformationApi", data);
  console.log("insertOutletInformationApi error", error);
  return data?.[0];
};

const upsertOutletInformationApi = async ({
  outlet_information_id,
  outlet_name = "",
  address_line_1 = "",
  address_line_2 = "",
  postal_code = "",
  city = "",
  state = "",
  country = "",
  person_in_charge = "",
  phone_number = "",
  nature_of_business = "",
  specification = "",
  user_id,
}) => {
  const { data, error } = await supabase
    .from("forms_outlet_information")
    .upsert({
      outlet_information_id,
      outlet_name,
      address_line_1,
      address_line_2,
      postal_code,
      city,
      state,
      country,
      person_in_charge,
      phone_number,
      nature_of_business,
      specification,
      user_id,
      date_modified: new Date(),
    })
    .select();
  console.log("upsertOutletInformationApi", data);
  console.log("upsertOutletInformationApi error", error);
  return data?.[0];
};

const deleteOutletInformationApi = async ({ outlet_information_id }) => {
  const { error } = await supabase
    .from("forms_outlet_information")
    .delete()
    .match({ outlet_information_id });
  console.log("deleteOutletInformationApi error", error);
};

const deleteAllOutletInformationForUser = async (user_id) => {
  const { error } = await supabase
    .from("forms_outlet_information")
    .delete()
    .match({ user_id });
  console.log("deleteOutletInformationApi error", error);
};

export {
  getOutletApi,
  getOutletsApi,
  insertOutletInformationApi,
  upsertOutletInformationApi,
  deleteOutletInformationApi,
  deleteAllOutletInformationForUser,
};
