import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, LoadingIndicator } from "@/modules/core/components/ui";
import { CheckoutList } from "@/modules/core/components/gadgets/checkout-list";
import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import { sortByAscendingDateOrder } from "@/modules/core/utils/date";
import {
  getCartItemsViewApi,
  getActiveCartApi,
  setCartItemsApi,
  deleteCartItemsApi,
} from "@/modules/core/api/cart";
import "./cart-page.css";

const CartPage = ({ session }) => {
  const [cart, setCart] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const getOrderPrice = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item?.STOCKVALUE * item.quantity;
    });
    return total;
  };

  const getCart = async () => {
    setLoading(true);
    const cart = await getActiveCartApi(session?.user?.id);
    setCart(cart);
    const cartItems = await getCartItemsViewApi(cart?.id);
    setCartItems(sortByAscendingDateOrder(cartItems));
    setLoading(false);
  };

  useEffect(() => {
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      text: "Cart",
      type: "text",
    },
  ];

  return (
    <div className="cart-page">
      <div className="cart-page-header">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Review your items</h1>
      </div>
      <div className="cart-page-body">
        <>
          {isLoading ? (
            <div style={{ height: "35vh" }}>
              <LoadingIndicator center />
            </div>
          ) : (
            <section>
              <h3>Order List</h3>
              <CheckoutList
                cartId={cart?.id}
                cartItems={cartItems}
                incrementCartItem={setCartItemsApi}
                decrementCartItem={setCartItemsApi}
                deleteCartItem={deleteCartItemsApi}
                reloadCart={getCart}
                type="cart"
              />
            </section>
          )}
          <div className="cart-summary">
            <div className="primary">
              <span className="estimated-total">Estimated total:</span>
              <span>{formatPriceWithCurrency(getOrderPrice())}</span>
            </div>
            <div className="secondary">
              <span>
                Shipping, taxes, and discounts will be calculated at checkout.
              </span>
            </div>
            <div>
              <Link to="/checkout" className="go-to-checkout-button">
                Go to Checkout
              </Link>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export { CartPage };
