import "./index.css";

const Notification = ({ title, description, time }) => {
  return (
    <div className="notification">
      <div className="title">{title}</div>
      <div className="info">
        <div className="description">{description}</div>
        <div className="time">{time}</div>
      </div>
    </div>
  );
};

export { Notification };
