import config from "../config.json";

function sort(items, sortMode) {
  const SORT_MODE = config?.MODES?.SORT_MODE;
  switch (sortMode) {
    case SORT_MODE.ALPHABETICAL_A_Z:
      return items.sort((a, b) =>
        a?.DESCRIPTION?.localeCompare(b?.DESCRIPTION)
      );
    case SORT_MODE.ALPHABETICAL_Z_A:
      return items.sort((a, b) =>
        b?.DESCRIPTION?.localeCompare(a?.DESCRIPTION)
      );
    case SORT_MODE.PRICE_LOW_TO_HIGH:
      return items.sort(
        (a, b) => parseFloat(a?.STOCKVALUE) - parseFloat(b?.STOCKVALUE)
      );
    case SORT_MODE.PRICE_HIGH_TO_LOW:
      return items.sort(
        (a, b) => parseFloat(b?.STOCKVALUE) - parseFloat(a?.STOCKVALUE)
      );
    default:
      return items;
  }
}

export { sort };
