import { useNavigate } from "react-router-dom";
import { FiTrash as DeleteIcon } from "react-icons/fi";
import { UserCell } from "./user-cell";
import { DateCell } from "./date-cell";

import "./index.css";

const Row = ({
  index,
  id,
  email,
  isAdmin,
  category,
  status,
  date_submitted,
  deleteUser,
  onDeleteSuccess,
}) => {
  const formatStatus = (status) => {
    if (status === "pending-review") return "Pending Review";
    if (status === "approved") return "Approved";
    if (status === "re-apply") return "Re-Apply";
    return status;
  };
  const navigate = useNavigate();
  return (
    <tr onClick={() => navigate(id)}>
      <td className="seq">{index}</td>
      <td className="email">
        <UserCell email={email} />
      </td>
      <td className="role">
        {isAdmin ? (
          <span className="pill admin">Admin</span>
        ) : (
          <span className="pill user">User</span>
        )}
      </td>
      <td className="category">
        <div className={`uppercase pill ${category}`}>{category}</div>
      </td>
      <td className={`status ${status} pill`}>{formatStatus(status)}</td>
      <td>
        <DateCell date_submitted={date_submitted} />
      </td>
      <td>
        <div
          className="action"
          onClick={(event) => deleteUser(event, id, email, onDeleteSuccess)}
        >
          <DeleteIcon />
          <span>Delete</span>
        </div>
      </td>
    </tr>
  );
};
const Table = ({ users, deleteUser, onDeleteSuccess }) => {
  return (
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th>Role</th>
            <th>Application</th>
            <th>Status</th>
            <th>Date Submitted</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, idx) => (
            <Row
              key={user?.id}
              index={idx}
              {...user}
              deleteUser={deleteUser}
              onDeleteSuccess={onDeleteSuccess}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { Table };
