import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, PageTitle } from "../../components/ui";
import { getDeclarationApi } from "@/modules/registration/api/declaration";
import { DeclarationForm } from "./declaration-form";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";
import config from "./config.json";

const HorecaDeclarationPage = ({ profile }) => {
  const [declaration, setDeclaration] = useState(
    // eslint-disable-next-line no-sequences
    config?.keys.reduce((acc, curr) => ((acc[curr] = ""), acc), {})
  );
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getBankingDetails() {
      setLoading(true);
      const declaration = await getDeclarationApi(profile?.id);
      setDeclaration(declaration);
      setLoading(false);
    }
    getBankingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="registration-page">
      <PageTitle>
        <BuildingLibraryIcon />
        <h1>Declaration</h1>
      </PageTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="legal-page">
          <Blockquote>
            I/ We hereby agree to accept the terms and conditions as printed on
            this Application Form in respect of all present and future business
            transactions between us and certify that all information given is
            true and correct.
          </Blockquote>
          <DeclarationForm
            declaration={declaration}
            setDeclaration={setDeclaration}
            profile={profile}
          />
        </div>
      )}
    </div>
  );
};

export { HorecaDeclarationPage };
