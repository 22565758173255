import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProductPicture } from "@/modules/core/components/gadgets";
import { LoadingIndicator, Breadcrumbs } from "@/modules/core/components/ui";
import { getProductApi } from "@/modules/core/api/products";
import "@/modules/customer/pages/product-page/product-page.css";

const PLACEHOLDERS = {
  DESCRIPTION: "Organic Avocado Oil (AI-generated text)",
  DESCRIPTION2: "CTN X 15PKT X 1KG (AI-generated text)",
  REMARK1:
    "Cook and drizzle with the goodness of our Organic Avocado Oil. Cold-pressed from ripe avocados, this oil is not only delicious but also rich in heart-healthy monounsaturated fats. (AI-generated text)",
  REMARK2: "Ceremonial Grade (AI-generated text)",
};

const EditProductPage = () => {
  const { productCode } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    const getProductDetails = async () => {
      setLoading(true);
      const product = await getProductApi(productCode);
      setProduct(product);
      setLoading(false);
    };
    getProductDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const breadcrumbs = [
    {
      pathname: "/admin/manage-products",
      text: "Manage Products",
      type: "link",
    },
    {
      text: product?.DESCRIPTION,
      type: "text",
    },
  ];
  return (
    <div className="product-page">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="product-page-body">
        {isLoading ? (
          <>
            <LoadingIndicator center />
          </>
        ) : (
          <>
            <div className="product-page-item">
              <div className="product-page-item-picture">
                <div className="gallery">
                  <div className="square">L</div>
                  <div className="square">O</div>
                  <div className="square">T</div>
                  <div className="square">U</div>
                  <div className="square">S</div>
                </div>
                <ProductPicture productCode={productCode} allowUpload />
              </div>
              <div className="product-page-item-details">
                <div className="product-info">
                  <div className="product-info-title">
                    <h3>{product?.DESCRIPTION || PLACEHOLDERS.DESCRIPTION}</h3>
                  </div>
                  <section className="product-info-dimensions">
                    <h4>Dimensions</h4>
                    <p>
                      <span className="dimension">
                        {product?.DESCRIPTION2 || PLACEHOLDERS.DESCRIPTION2}
                      </span>
                    </p>
                  </section>
                  <section className="product-info-description">
                    <h4>Description</h4>
                    <div className="product-info-description-text">
                      <p>{product?.REMARK1 || PLACEHOLDERS.REMARK1}</p>
                      <p>{product?.REMARK2 || PLACEHOLDERS.REMARK2}</p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="product-page-footer" style={{ padding: 50 }} />
    </div>
  );
};

export { EditProductPage };
