import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { Divider, Title } from "../../../components/ui";
import { OutletInformation } from "./outlet-information";
import { getOutletsApi } from "@/modules/registration/api/outlet_information";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";

const OutletInformationSection = ({ customerId }) => {
  const [outlets, setOutlets] = useState(null);
  const [isLoading, setLoading] = useState(false);
  async function getOutlets() {
    setLoading(true);
    const outlets = await getOutletsApi(customerId);
    setOutlets(outlets);
    setLoading(false);
  }
  useEffect(() => {
    getOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section>
      <div className="heading">
        <h2>Outlet Information</h2>
        <Button href="/application/horeca/outlet-information">
          <EditIcon />
          Edit
        </Button>
      </div>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="outlets">
          {outlets?.length === 0 ? (
            <div>
              Business registration address is being used as the sole outlet
            </div>
          ) : (
            outlets?.map((outlet, idx) => (
              <>
                <Title>
                  <BuildingOfficeIcon />
                  <h2>Outlet #{idx + 1}</h2>
                </Title>
                <OutletInformation
                  key={outlet?.outlet_information_id}
                  outletInformation={outlet}
                />
                {idx !== outlets?.length - 1 && <Divider />}
              </>
            ))
          )}
        </div>
      )}
    </section>
  );
};

export { OutletInformationSection };
