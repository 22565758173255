import {
  FiAlertCircle as OutOfStockIcon,
  FiHeart as FavoriteIcon,
} from "react-icons/fi";
import { FoodPlaceholder } from "../../ui";
import "./product.css";

function Product({
  img,
  title,
  description,
  descriptionTwo,
  price,
  isFavorite,
  isOutOfStock,
}) {
  function Title({ title }) {
    return <span className="product-title">{title}</span>;
  }
  function Description({ description }) {
    return <span className="product-description">{description}</span>;
  }
  function Price({ price }) {
    return <span className="product-price">{price}</span>;
  }
  return (
    <div className="product">
      <div className="product-image-container">
        {img ? (
          <img
            className="product-image"
            src={img}
            alt={title}
            style={{ visibility: img ? "visible" : "hidden" }}
          />
        ) : (
          <FoodPlaceholder size="small" />
        )}
        {isFavorite && <FavoriteIcon className="favorite-icon" />}
      </div>
      {title && <Title title={title} />}
      {isOutOfStock && (
        <div className="out-of-stock-label">
          <OutOfStockIcon />
          <span className="out-of-stock-text">Out Of Stock</span>
        </div>
      )}
      {description && <Description description={description} />}
      {descriptionTwo && <Description description={descriptionTwo} />}
      {price && <Price price={price} />}
    </div>
  );
}

export { Product };
