const LOTUS_OFFICE_ADDRESS = {
  name: "Lotus Consulting Group Office",
  address_line_1: "No. 35, Jalan PBS 14/2",
  address_line_2: "Taman Perindustrian Bukit Serdang",
  postal_code: "43300",
  city: "Seri Kembangan",
  state: "Selangor Darul Ehsan",
  country: "Malaysia",
};

export { LOTUS_OFFICE_ADDRESS };
