import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import {
  Bubble,
  BubbleHeader,
  Divider,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { UsersIcon } from "@heroicons/react/24/solid";
import { UserIcon } from "@heroicons/react/24/solid";
import { getReferenceInformationApi } from "@/modules/registration/api/reference_information";

const ReferenceInformationSection = ({ customerId }) => {
  const [referenceInformation, setReferenceInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getReferenceInformation() {
      setLoading(true);
      const referenceInformation = await getReferenceInformationApi(customerId);
      setReferenceInformation(referenceInformation);
      setLoading(false);
    }
    getReferenceInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <h2>Reference Information</h2>
        <Button href="/application/horeca/reference-information">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Title>
        <UsersIcon />
        <h3>Suppliers that are currently in-use</h3>
      </Title>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <UserIcon />
            <h4>Supplier 1</h4>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Name"
            value={referenceInformation?.supplier_1_name}
          />
          <ReviewField
            label="Telephone"
            value={referenceInformation?.supplier_1_tel}
          />
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <UserIcon />
            <h4>Supplier 2</h4>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Name"
            value={referenceInformation?.supplier_2_name}
          />
          <ReviewField
            label="Telephone"
            value={referenceInformation?.supplier_2_tel}
          />
        </FieldList>
      </Bubble>
      <Divider />
      <Bubble>
        <BubbleHeader>
          <Title>
            <UserIcon />
            <h4>Supplier 3</h4>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Name"
            value={referenceInformation?.supplier_3_name}
          />
          <ReviewField
            label="Telephone"
            value={referenceInformation?.supplier_3_tel}
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { ReferenceInformationSection };
