import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "@/modules/core/components/gadgets";
import { Button } from "@/modules/core/components/ui";
import { PaymentSummary } from "../order-page";
import { getOrderApi } from "@/modules/core/api/order";
import { formatDateTime, formatDate } from "@/modules/core/utils/time";
import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import { LOTUS_OFFICE_ADDRESS } from "@/modules/core/utils/constants";
import "./print-invoice-page.css";

function OrderDetailsSummary({ cartItems }) {
  const getOrderPrice = () => {
    let total = 0;
    cartItems?.forEach((item) => {
      total += item?.STOCKVALUE * item?.quantity;
    });
    return total;
  };
  return (
    <div
      className="order-details-summary"
      style={{
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        fontSize: "0.7rem",
      }}
    >
      <div
        className="primary"
        style={{
          minWidth: 200,
          margin: "2px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <span>Subtotal:</span>
        <span style={{ textAlign: "right" }}>
          {formatPriceWithCurrency(getOrderPrice())}
        </span>
      </div>
      <div
        className="primary"
        style={{
          minWidth: 200,
          margin: "2px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <span>Shipping:</span>
        <span style={{ textAlign: "right" }}>Free</span>
      </div>
      <div
        className="primary"
        style={{
          minWidth: 200,
          margin: "2px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <span>Taxes:</span>
        <span style={{ textAlign: "right" }}>Free</span>
      </div>
      <div
        className="primary"
        style={{
          minWidth: 200,
          margin: "2px 0",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <span>Total:</span>
        <span style={{ textAlign: "right" }}>
          {formatPriceWithCurrency(getOrderPrice())}
        </span>
      </div>
    </div>
  );
}

function OrderDetailsTable({ cartItems }) {
  function CartItems({ cartItems }) {
    return cartItems?.map((item) => {
      return (
        <tr key={item?.DTLKEY}>
          <td>{item?.DTLKEY}</td>
          <td>{item?.CODE}</td>
          <td>{item?.DESCRIPTION}</td>
          <td>{item?.UOM}</td>
          <td>{formatPriceWithCurrency(item?.STOCKVALUE)}</td>
          <td>{item?.quantity}</td>
          <td style={{ textAlign: "right" }}>
            {formatPriceWithCurrency(item?.STOCKVALUE * item?.quantity)}
          </td>
        </tr>
      );
    });
  }
  return (
    <table
      className="order-details-table"
      style={{ width: "100%", borderCollapse: "collapse" }}
    >
      <thead style={{ borderBottom: "1px solid var(--font-grey)" }}>
        <tr style={{ fontWeight: "bold" }}>
          <th style={{ textAlign: "left" }}>ID</th>
          <th style={{ textAlign: "left" }}>Product Code</th>
          <th style={{ textAlign: "left" }}>Product Name</th>
          <th style={{ textAlign: "left" }}>UOM</th>
          <th style={{ textAlign: "left" }}>Item Price</th>
          <th style={{ textAlign: "left" }}>Quantity Ordered</th>
          <th style={{ textAlign: "right" }}>Total Price</th>
        </tr>
      </thead>
      <tbody>
        <CartItems cartItems={cartItems} />
      </tbody>
    </table>
  );
}
function formatOrderNumber(order_number) {
  return `#IVP-${order_number.padStart(5, "0")}`;
}

const SimplePrintInvoicePage = ({ profile }) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getOrder = async () => {
      setLoading(true);
      const order = await getOrderApi(orderId);
      setOrder(order);
      setLoading(false);
    };
    getOrder();
  }, [orderId]);

  if (isLoading || profile === undefined) return null;
  if (!profile?.status === "approved") return <div>Nothing here</div>;

  return (
    <div className="print-invoice-page">
      <div
        className="buttons no-print"
        style={{ position: "fixed", top: 100, right: "10vw" }}
      >
        <Button href={`/orders/${orderId}`}>Back</Button>
        <Button onClick={() => window.print()} color="green">
          Print
        </Button>
      </div>
      <h1>Lotus Consulting Group</h1>
      <h2>Invoice Receipt</h2>
      <section style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h3>Order Number:</h3>
          <p>{formatOrderNumber(orderId)}</p>
        </div>
      </section>
      <section style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h3>Order Date:</h3>
          <p>{formatDateTime(order?.[0]?.date_created)}</p>
        </div>
        <div>
          <h3>Invoice Date:</h3>
          <p>{formatDate(order?.[0]?.invoice_date)}</p>
        </div>
      </section>
      <section style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h3>Sold To:</h3>
        </div>
        <div>
          <h3>Location:</h3>
          <p>{LOTUS_OFFICE_ADDRESS.address_line_1}</p>
          <p>{LOTUS_OFFICE_ADDRESS.address_line_2}</p>
          <p>{`${LOTUS_OFFICE_ADDRESS.postal_code}, ${LOTUS_OFFICE_ADDRESS.city}`}</p>
          <p>{LOTUS_OFFICE_ADDRESS.country}</p>
        </div>
      </section>
      <section>
        <span>Customer No:</span>
        <span>{profile?.id}</span>
      </section>
      <section>
        <h2>Order Details</h2>
        <OrderDetailsTable cartItems={order} />
        <OrderDetailsSummary cartItems={order} />
      </section>
      <section>
        <PaymentSummary
          cart_id={order?.[0]?.cart_id}
          shipping_address_id={order?.[0]?.shipping_address_id}
        />
      </section>
      <hr />
      <aside>
        <p>
          This order is subject to Lotus Consulting Group's Exchange and Refund
          Policies.
        </p>
      </aside>
    </div>
  );
};

function PrintInvoicePage({ profile }) {
  return (
    <ErrorBoundary message="Not available at the moment">
      <SimplePrintInvoicePage profile={profile} />
    </ErrorBoundary>
  );
}

export { PrintInvoicePage };
