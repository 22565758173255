import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MdCheckBoxOutlineBlank as EmptyCheckbox,
  MdCheckBox as FilledCheckbox,
} from "react-icons/md";
import { BiSolidLockAlt as LockIcon } from "react-icons/bi";
import { toast } from "sonner";
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
  PaymentStatus,
} from "@/modules/core/components/ui";
import {
  EGHLForm,
  FORM_VALUES,
} from "@/modules/core/components/gadgets/e-ghl-form";
import { getOrderHistoryApi } from "@/modules/core/api/order";
import { sortByDescendingDateOrder } from "@/modules/core/utils/date";
import formatPriceWithCurrency, {
  formatPrice,
} from "@/modules/core/utils/formatPriceWithCurrency";
import "../orders-page/orders-page.css";

function OutstandingOrders({ orders, selected, handleCheckboxClick }) {
  function Row({ order }) {
    const isSelected = selected?.some(
      (selected) => selected?.number === order?.number
    );
    return (
      <tr>
        <td>
          <div className="checkbox" onClick={() => handleCheckboxClick(order)}>
            {isSelected ? <FilledCheckbox /> : <EmptyCheckbox />}
          </div>
        </td>
        <td style={{ textAlign: "left" }}>
          <Link to={`/orders/${order?.number.toString()}`}>
            {order?.invoice_number}
          </Link>
        </td>
        <td style={{ textAlign: "center" }}>
          <PaymentStatus paymentStatus={order?.payment_status} />
        </td>
        <td className="total" style={{ textAlign: "right" }}>
          {formatPriceWithCurrency(order?.sum)}
        </td>
        <td style={{ textAlign: "right" }}>
          {new Date(order?.date_created).toLocaleString("en-UK", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })}
        </td>
      </tr>
    );
  }
  return (
    <table style={{ margin: "auto", width: "100%" }}>
      <thead>
        <tr>
          <th style={{ textAlign: "left", width: 40 }}>
            {selected?.length > 0 ? (
              <span className="number-indicator">{selected?.length}</span>
            ) : null}
          </th>
          <th style={{ textAlign: "left", padding: "10px 0px" }}>
            Invoice Number
          </th>
          <th style={{ textAlign: "center" }}>Status</th>
          <th style={{ textAlign: "right" }}>Total</th>
          <th style={{ textAlign: "right" }}>Date Created</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order) => (
          <Row key={order?.number} order={order} />
        ))}
      </tbody>
    </table>
  );
}

const OrdersOutstandingPage = ({ session }) => {
  const [orders, setOrders] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    const getOrderHistory = async () => {
      setLoading(true);
      const orders = await getOrderHistoryApi(session?.user?.id);
      setOrders(
        sortByDescendingDateOrder(
          orders.filter((order) => order.payment_status === "pending-payment")
        )
      );
      setLoading(false);
    };
    getOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleCheckboxClick(order) {
    console.log("selected before", selected);
    let newList = [...selected];
    const index = newList?.findIndex((e) => e.number === order?.number);
    if (index >= 0) {
      newList = newList.filter((e) => e?.number !== order?.number);
    } else {
      if (selected.length === 8) {
        toast.warning(
          "The maximum allowed is 8 orders per checkout. Please try again."
        );
        return;
      }
      newList?.push(order);
    }
    console.log("selected after", newList);
    setSelected(newList);
  }
  function handleCheckout() {
    if (selected?.length === 0) {
      // eslint-disable-next-line no-restricted-globals
      toast.warning("No orders selected.");
    } else {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          `Are you sure you want to checkout the following orders? ${selected?.map((e) => e.number).sort()}`
        )
      ) {
        setShowForm(true);
      }
    }
    console.log(selected);
  }
  function getTotalPrice() {
    const sum = selected.reduce((acc, curr) => {
      acc += curr?.sum;
      return acc;
    }, 0);
    return sum;
  }
  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      pathname: "/orders",
      text: "Orders",
      type: "link",
    },
    {
      text: "Outstanding Orders",
      type: "text",
    },
  ];
  return (
    <div className="orders-page">
      <h1>Outstanding Orders</h1>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className="action-buttons">
        {selected?.length > 0 && (
          <div>Total: {formatPriceWithCurrency(getTotalPrice())}</div>
        )}
        {showForm ? (
          <EGHLForm
            {...FORM_VALUES}
            Amount={formatPrice(getTotalPrice())}
            orders={selected?.map((e) => e.number).sort()}
            paymentType="credit"
            session={session}
          />
        ) : (
          <Button
            className="payment-button"
            color="green"
            onClick={handleCheckout}
          >
            <LockIcon />
            Checkout Orders
          </Button>
        )}
      </div>
      <div className="orders-page-body">
        {isLoading ? (
          <LoadingIndicator center />
        ) : (
          <OutstandingOrders
            selected={selected}
            handleCheckboxClick={handleCheckboxClick}
            orders={orders}
          />
        )}
      </div>
    </div>
  );
};

export { OrdersOutstandingPage };
