import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <div className="footer-column">
          <div className="footer-title">
            <Link to="/">Inside Lotus Consulting Group</Link>
          </div>
          <div className="footer-list">
            <Link to="/about-us">About Us</Link>
            <Link to="/catalogue">Catalogue</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-title">Customer Care</div>
          <div className="footer-list">
            <Link to="/return-and-refund-policy">Return & Refund Policy</Link>
            <Link to="/shipping-and-fulfilment-policy">
              Shipping & Fulfilment Policy
            </Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
          </div>
        </div>
        <div className="footer-column" id="my-account">
          <div className="footer-title">My Account</div>
          <div className="footer-list">
            <Link to="/sign-in">Sign In</Link>
            <Link to="/signup">Sign Up</Link>
          </div>
        </div>
      </div>
      <aside className="footer-aside">
        <div>© 2023 Mumbo Solutions. All Rights Reserved.</div>
      </aside>
    </footer>
  );
}

export { Footer };
