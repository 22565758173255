import { supabase } from "@/modules/supabase";

const getBankingDetailsApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_banking_details")
    .select()
    .match({ user_id });
  console.log("get forms_banking_details api", data);
  console.log("get forms_banking_details api error", error);
  return data?.[0];
};

const insertBankingDetailsApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_banking_details")
    .upsert(keys)
    .select();
  console.log("insert forms_banking_details api", data);
  console.log("insert forms_banking_details api error", error);
  return data?.[0];
};

export { getBankingDetailsApi, insertBankingDetailsApi };
