import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { formatDateTime } from "@/modules/core/utils/time";
import { updateCurrentUser } from "@/modules/core/api/auth";
import "./update-password-page.css";

const UpdatePasswordPage = () => {
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [timeUpdated, setTimeUpdated] = useState(null);
  async function updatePassword(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data, error } = await updateCurrentUser(password);
      if (error) {
        throw new Error(error);
      } else {
        console.log(data);
        setTimeUpdated(new Date());
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setPassword("");
      setLoading(false);
    }
  }
  return (
    <div className="update-password-page">
      <h1>Update Password</h1>
      {isLoading ? (
        <LoadingIndicator offTimeout />
      ) : (
        <div>
          {timeUpdated ? (
            <div>
              <h4>Password successfully updated</h4>
              <div className="notification">
                <time className="time">{formatDateTime(timeUpdated)}</time>
                <span className="message">
                  You may use your new password to log in next time.
                </span>
              </div>
              <div className="back-to-home-btn">
                <Link to="/home">Back to Home</Link>
              </div>
            </div>
          ) : (
            <form className="update-password-form" onSubmit={updatePassword}>
              <div className="field">
                <label>New Password</label>
                <input
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <button>Update Password</button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export { UpdatePasswordPage };
