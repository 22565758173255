/**
 * Keeps only the smallest UOM for each product
 *
 * @param {Array]} products An array of products
 * @returns An array of products without larger UOMs
 */
const getSmallestUOMProducts = (products) => {
  let result = [];
  const grouped = Object.groupBy(products, ({ CODE }) => CODE);
  for (const property in grouped) {
    const smallestUOM = grouped[property].sort(
      (a, b) => Number(a.STOCK_VALUE) - Number(b.STOCK_VALUE)
    )?.[0];
    result.push(smallestUOM);
  }
  return result;
};

export { getSmallestUOMProducts };
