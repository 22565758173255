import { UncontrolledFieldGroup } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";

const BusinessTypeSection = ({ retailInformation, setRetailInformation }) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Type</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <UncontrolledFieldGroup
            label="Category"
            formElement={
              <select
                name="nature_of_business"
                value={retailInformation?.nature_of_business}
                onChange={(e) =>
                  setRetailInformation({
                    ...retailInformation,
                    nature_of_business: e.target.value,
                  })
                }
              >
                <option>Hotel/Restaurant/Cafe</option>
                <option>Caterer</option>
                <option>Bakery/Central Kitchen</option>
                <option>Online Seller</option>
                <option>Burger Boy</option>
                <option>Dist/W-saler/Mini Market</option>
                <option>Others</option>
              </select>
            }
          />
          <UncontrolledFieldGroup
            label="If 'Others', Please Describe"
            formElement={
              <input
                name="specification"
                onChange={(e) =>
                  setRetailInformation({
                    ...retailInformation,
                    specification: e.target.value,
                  })
                }
                value={retailInformation?.specification}
                placeholder="e.g. Homecooked food"
              />
            }
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { BusinessTypeSection };
