import { supabase } from "@/modules/supabase";

const getDataProtectionApi = async (user_id, type) => {
  const { data, error } = await supabase
    .from("forms_data_protection")
    .select()
    .match({ user_id });
  console.log("get forms_data_protection api", data);
  console.log("get forms_data_protection api error", error);
  return data?.[0];
};

const insertDataProtectionApi = async (user_id, is_signed) => {
  const { data, error } = await supabase
    .from("forms_data_protection")
    .upsert({ user_id, is_signed })
    .select();
  console.log("insert forms_data_protection api", data);
  console.log("insert forms_data_protection api error", error);
  return data?.[0];
};

export { getDataProtectionApi, insertDataProtectionApi };
