import { NotificationCenter } from "./notification-center";

import "./index.css";

const NotificationCenterSection = ({ isLoading, notifications }) => {
  return (
    <section className="notification-center-section">
      <NotificationCenter isLoading={isLoading} notifications={notifications} />
    </section>
  );
};

export { NotificationCenterSection };
