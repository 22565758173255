import { Link } from "react-router-dom";
import { Plant } from "@/modules/img/customer-pages";
import BRANDS from "./config.json";
import "./brands-page.css";

const BrandsPage = () => {
  function List({ alphabet, brands }) {
    return (
      <div className="brands-list-alphabet">
        <h3>{alphabet}</h3>
        <div className="brands-list-alphabet-items">
          {brands?.map((brand) => (
            <span key={brand}>
              <Link to={`/brands/${brand}`}>{brand}</Link>
            </span>
          ))}
        </div>
      </div>
    );
  }
  return (
    <section className="brands-page">
      <div className="brands-page-header">
        <img src={Plant} alt="Plant" />
        <h1>brands</h1>
      </div>
      <div className="brands-page-body">
        <div className="brands-list">
          {Object.keys(BRANDS).map((e) => (
            <List key={e} alphabet={e} brands={BRANDS[e]} />
          ))}
        </div>
      </div>
    </section>
  );
};

export { BrandsPage };
