import { getStockGroupDisplayName } from "./get-stock-group-display-name";

/**
 * Returns a list of filter elements
 *
 * @param {Array} stockgroups An array of stockgroups
 * @returns An array of elements that represents filter options
 */
function initializeCategoryFilterElements(stockgroups) {
  return stockgroups.map((e) => ({
    name: "category",
    id: e.STOCKGROUP,
    value: e.STOCKGROUP,
    label: getStockGroupDisplayName(e.STOCKGROUP),
  }));
}

export { initializeCategoryFilterElements };
