import { useState, useEffect } from "react";
import { filterUsers } from "./helper";

const useSearchInput = ({ originalUsers, setUsers }) => {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (searchInput === "") {
      setUsers(originalUsers);
      return;
    }
    const filteredUsers = filterUsers(originalUsers, searchInput);
    setUsers(filteredUsers);
  }, [searchInput]); // eslint-disable-line react-hooks/exhaustive-deps

  return [searchInput, setSearchInput];
};

export { useSearchInput };
