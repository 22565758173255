import { supabase } from "@/modules/supabase";

const getAllStatementOfAccountsApi = async (customer_id) => {
  const { data, error } = await supabase
    .from("statement_of_accounts_view")
    .select()
    .match({ customer_id });
  console.log("get all statement_of_accounts", data);
  console.log("get all statement_of_accounts error", error);
  return data;
};

const getStatementOfAccountsByMonthApi = async (
  customer_id,
  month_issued,
  year_issued
) => {
  const { data, error } = await supabase
    .from("statement_of_accounts_by_month_view")
    .select()
    .match({ customer_id, month_issued, year_issued });
  console.log("get statement_of_accounts_by_month_view", data);
  console.log("get statement_of_accounts_by_month_view error", error);
  return data;
};

export { getStatementOfAccountsByMonthApi, getAllStatementOfAccountsApi };
