import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FiMenu as MenuIcon, FiX as CloseIcon } from "react-icons/fi";
import Logo from "@/modules/img/logo/lotus-consulting-group-logo-transparent.png";
import "./public-nav-bar.css";

function PublicNavBar() {
  const [isOpen, setOpen] = useState(false);
  function useClickEffect(menuRef, isOpen, setOpen) {
    useEffect(() => {
      /**
       * If drop down menu is closed, click on the profile icon to open it.
       * Otherwise, if drop down menu is open, click the close button
       * or anywhere outside the menu
       */
      function handleClickOutside(event) {
        const isMenuRefClicked = menuRef?.current.contains(event.target);
        if (!isMenuRefClicked) setOpen(false);
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [menuRef, isOpen, setOpen]);
  }
  const menuRef = useRef(null);
  useClickEffect(menuRef, isOpen, setOpen);

  return (
    <header className="public-nav-bar">
      <nav className="public-nav-bar-nav">
        <Link to="/" className="public-nav-bar-nav-logo">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="site-map">
          <Link to="/about-us">About Us</Link>
          <Link to="/catalogue">Catalogue</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
        <div className="public-auth-links">
          <Link to="/signup" className="secondary">
            Sign up
          </Link>
          <Link to="/login" className="primary">
            Log in
          </Link>
        </div>
        <div>
          <MenuIcon
            className="menu-button"
            size={30}
            onClick={() => setOpen(!isOpen)}
          />
        </div>
        <ul
          className="public-nav-bar-drop-down-menu"
          style={isOpen ? { width: "30vw" } : { width: 0, minWidth: 0 }}
          ref={menuRef}
        >
          <div className="close-button" onClick={() => setOpen(false)}>
            <CloseIcon
              className="close-button-icon"
              size={30}
              color="var(--font-grey)"
            />
          </div>
          <div className="action-buttons">
            <li>
              <Link to="/login" className="primary">
                Log in
              </Link>
            </li>
            <li>
              <Link to="/signup" className="secondary">
                Sign up
              </Link>
            </li>
          </div>
          <hr />
          <li
            className="public-nav-bar-drop-down-menu-item"
            onClick={() => setOpen(false)}
          >
            <Link to="/about-us">About Us</Link>
          </li>
          <li
            className="public-nav-bar-drop-down-menu-item"
            onClick={() => setOpen(false)}
          >
            <Link to="/catalogue">Catalogue</Link>
          </li>
          <li
            className="public-nav-bar-drop-down-menu-item"
            onClick={() => setOpen(false)}
          >
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export { PublicNavBar };
