import { Button } from "@/modules/core/components/ui";
import styles from "./form-footer.module.css";

const FormFooter = ({ onBackClick }) => {
  return (
    <div className={styles["form-footer"]}>
      <Button className="prev-button" type="button" onClick={onBackClick}>
        Previous
      </Button>
      <Button className="next-button" type="submit" color="green">
        Continue
      </Button>
    </div>
  );
};

export { FormFooter };
