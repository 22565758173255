import { Route } from "react-router-dom";
import {
  HomePage,
  MarketPage,
  CartPage,
  CheckoutPage,
  OrderPage,
  OrdersPage,
  OrdersOutstandingPage,
  ProductPage,
  ProfilePage,
  EditProfilePage,
  AddressesPage,
  AddAddressPage,
  EditAddressPage,
  StatementOfAccountPage,
  StatementOfAccountsPage,
  BrandsPage,
  CategoriesPage,
} from "@/modules/customer/pages";

const customerRoutes = ({ auth }) => {
  return [
    <>
      <Route path="home" element={<HomePage />} />
      <Route path="market">
        <Route
          index
          element={
            <MarketPage session={auth?.session} profile={auth?.profile} />
          }
        />
        <Route
          path=":productCode"
          element={
            <ProductPage session={auth?.session} profile={auth?.profile} />
          }
        />
      </Route>
      <Route path="categories" element={<CategoriesPage />} />
      <Route path="brands" element={<BrandsPage />} />
      <Route path="cart" element={<CartPage session={auth?.session} />} />
      <Route
        path="checkout"
        element={<CheckoutPage session={auth?.session} />}
      />
      <Route path="orders">
        <Route index element={<OrdersPage session={auth?.session} />} />
        <Route
          path="outstanding"
          element={<OrdersOutstandingPage session={auth?.session} />}
        />
        <Route
          path=":orderId"
          element={<OrderPage session={auth?.session} />}
        />
      </Route>
      <Route path="statement-of-accounts">
        <Route
          index
          element={<StatementOfAccountsPage session={auth?.session} />}
        />
        <Route
          path=":statementOfAccountId"
          element={<StatementOfAccountPage session={auth?.session} />}
        />
      </Route>
      <Route path="profile">
        <Route
          index
          element={
            <ProfilePage session={auth?.session} profile={auth?.profile} />
          }
        />
        <Route
          path="edit"
          element={
            <EditProfilePage session={auth?.session} profile={auth?.profile} />
          }
        />
      </Route>
      <Route path="addresses">
        <Route
          index
          element={
            <AddressesPage session={auth?.session} profile={auth?.profile} />
          }
        />
        <Route
          path="add"
          element={
            <AddAddressPage session={auth?.session} profile={auth?.profile} />
          }
        />
        <Route
          path=":addressId"
          element={
            <EditAddressPage session={auth?.session} profile={auth?.profile} />
          }
        />
      </Route>
    </>,
  ];
};
export { customerRoutes };
