import { Link } from "react-router-dom";
import { Product } from "@/modules/core/components/gadgets";
import { getUrl, getPrice } from "../helpers";

function ProductLink({ product, managementMode }) {
  return (
    <Link to={getUrl(product, managementMode)}>
      <Product
        img={product.img}
        title={product.DESCRIPTION}
        description={product.DESCRIPTION2}
        price={getPrice(product.STOCKVALUE, managementMode)}
        isFavorite={product.is_favorite}
        isOutOfStock={product.is_out_of_stock}
      />
    </Link>
  );
}

export { ProductLink };
