import { FiUser as UserIcon } from "react-icons/fi";
import "./user-profile-placeholder.css";

const UserProfilePlaceholder = () => {
  return (
    <div className="user-profile-placeholder">
      <UserIcon />
    </div>
  );
};

export { UserProfilePlaceholder };
