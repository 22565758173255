import { Link } from "react-router-dom";
import { Carousel } from "@/modules/core/components/gadgets";
import {
  BreakfastCocktailChickenSausage,
  PandanChicken,
  ChickenLobak,
} from "@/modules/img/food/general";
import "./carousel-section.css";

const carouselData = [
  {
    bgImg: {
      src: PandanChicken,
      alt: "Pandan Chicken",
    },
    title: "Summer Collection",
    primaryButtonText: "Shop Food",
    secondaryButtonText: "See more",
  },
  {
    bgImg: {
      src: ChickenLobak,
      alt: "Lobak",
    },
    title: "Summer Collection",
    primaryButtonText: "Shop Food",
    secondaryButtonText: "See more",
  },
  {
    bgImg: {
      src: BreakfastCocktailChickenSausage,
      alt: "Breakfast Cocktail",
    },
    title: "Summer Collection",
    primaryButtonText: "Shop Food",
    secondaryButtonText: "See more",
  },
];

const CarouselItem = ({
  bgImg,
  title,
  primaryButtonText,
  secondaryButtonText,
}) => {
  return (
    <div className="carousel-item">
      <img className="responsive-image" src={bgImg?.src} alt={bgImg?.alt} />
      <div className="overlay">
        <div className="content">
          <p>{title}</p>
          <div className="buttons">
            <Link to="/market" className="btn primary-btn">
              {primaryButtonText}
            </Link>
            <Link to="/market" className="btn secondary-btn">
              {secondaryButtonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const CarouselSection = () => {
  const carouselItems = carouselData?.map((e, idx) => (
    <CarouselItem key={idx} {...e} />
  ));
  return (
    <section>
      <Carousel items={carouselItems} />
    </section>
  );
};

export { CarouselSection };
