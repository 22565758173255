import { useState, useEffect } from "react";
import { supabase } from "@/modules/supabase";
import { createSignedUrl } from "@/modules/core/api/storage";

const Form = ({ userId, storageBucketName, tableName }) => {
  const [form, setForm] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isGettingSignedUrl, setIsGettingSignedUrl] = useState(false);

  useEffect(() => {
    getForm(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieves the picture URL from the product images table.
   * Then, uses the URL to download the image into the user's browser.
   * The local path is then used to display the image.
   *
   * @param {Integer} id The unique identifier for the product.
   */
  async function getForm(id) {
    const getFormUrl = async () => {
      const { data, error } = await supabase
        .from(tableName)
        .select()
        .eq("user_id", userId);

      if (error) throw error;
      if (data?.length > 0) {
        setForm(data[0]);
      }
    };
    try {
      setLoading(true);
      await getFormUrl();
    } catch (error) {
      console.log("Error retrieving row: ", error);
    } finally {
      setLoading(false);
    }
  }

  async function downloadForm(formName, storageBucketName) {
    try {
      setIsGettingSignedUrl(true);
      const { data, error } = await createSignedUrl(
        storageBucketName,
        formName
      );
      console.log("downloadForm success", data);
      console.log("downloadForm error", error);
      window.open(data.signedUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGettingSignedUrl(false);
    }
  }

  return isLoading ? (
    "..."
  ) : (
    <>
      {form ? (
        <span style={{ padding: 5 }}>{form?.form_url.split("_")[1]}</span>
      ) : null}
      {form ? (
        <span
          style={{
            padding: 5,
            color: form ? "#3182bd" : "#ccc",
            cursor: "pointer",
          }}
          onClick={
            isGettingSignedUrl
              ? null
              : () => downloadForm(form?.form_url, storageBucketName)
          }
        >
          {isGettingSignedUrl ? "Downloading..." : <>Download</>}
        </span>
      ) : null}
    </>
  );
};

export { Form };
