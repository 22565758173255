import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { PageTitle } from "../../components/ui";
import { getBillingInformationApi } from "@/modules/registration/api/billing_information";
import { HorecaBillingInformationForm } from "./horeca-billing-information-form";
import { CreditCardIcon } from "@heroicons/react/24/solid";
import config from "./config.json";

const HorecaBillingInformationPage = ({ profile }) => {
  const [billingInformation, setBillingInformation] = useState(
    // eslint-disable-next-line no-sequences
    config?.keys.reduce((acc, curr) => ((acc[curr] = ""), acc), {})
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getBillingInformation() {
      setLoading(true);
      const billingInformation = await getBillingInformationApi(profile?.id);
      setBillingInformation(billingInformation);
      setLoading(false);
    }
    getBillingInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="registration-page">
      <PageTitle>
        <CreditCardIcon />
        <h1>Billing Information</h1>
      </PageTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <HorecaBillingInformationForm
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
          profile={profile}
        />
      )}
    </div>
  );
};

export { HorecaBillingInformationPage };
