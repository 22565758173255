import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { PageTitle } from "../../components/ui";
import { getReferenceInformationApi } from "@/modules/registration/api/reference_information";
import { ReferenceInformationForm } from "./reference-information-form";
import { UsersIcon } from "@heroicons/react/24/solid";

const HorecaReferenceInformationPage = ({
  previousPage,
  nextPage,
  type,
  profile,
}) => {
  const [referenceInformation, setReferenceInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getReferenceInformation() {
      setLoading(true);
      const referenceInformation = await getReferenceInformationApi(
        profile?.id
      );
      setReferenceInformation(referenceInformation);
      setLoading(false);
    }
    getReferenceInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="registration-page">
      <PageTitle>
        <UsersIcon />
        <h1>Reference Information</h1>
      </PageTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <ReferenceInformationForm
          previousPage={previousPage}
          nextPage={nextPage}
          referenceInformation={referenceInformation}
          setReferenceInformation={setReferenceInformation}
          type={type}
          profile={profile}
        />
      )}
    </div>
  );
};

export { HorecaReferenceInformationPage };
