import { ReturnAndRefundPolicy } from "@/modules/img/public-pages";
import "../public-misc.css";

const ReturnAndRefundPolicyPage = () => {
  return (
    <article className="page">
      <div className="page-header">
        <img src={ReturnAndRefundPolicy} alt="Return and Refund Policy" />
        <h1>Return & Refund Policy</h1>
      </div>
      <section>
        <h2>Return & Refund Policy</h2>
        <ul>
          <li>All goods sold are not returnable</li>
          <li>
            Should there be an incorrect item delivered, kindly keep in the
            freezer (-18c) and reach out to our customer service agent.{" "}
          </li>
        </ul>
      </section>
    </article>
  );
};

export { ReturnAndRefundPolicyPage };
