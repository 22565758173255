import { useGA } from "../ga-4";

/**
 * Parent function to host analytics from all service providers.
 */
const useAnalytics = () => {
  useGA();
};

export { useAnalytics };
