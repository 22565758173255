import { useState, useEffect } from "react";
import { SpinnerCircular, SpinnerInfinity } from "spinners-react";
import { TransitionWrapper } from "../";
import "./loading-indicator.css";

function Circle() {
  return (
    <SpinnerCircular
      size={48}
      thickness={100}
      speed={100}
      color="var(--primary-color)"
      secondaryColor="rgba(0, 0, 0, 0.1)"
    />
  );
}

function SmallCircle() {
  return (
    <SpinnerCircular
      size={30}
      thickness={100}
      speed={100}
      color="var(--primary-color)"
      secondaryColor="rgba(0, 0, 0, 0.1)"
    />
  );
}

function BiggerCircle() {
  return (
    <SpinnerCircular
      size={130}
      thickness={130}
      speed={100}
      color="var(--primary-color)"
      secondaryColor="rgba(0, 0, 0, 0.1)"
    />
  );
}

// eslint-disable-next-line no-shadow-restricted-names
function Infinity() {
  return (
    <SpinnerInfinity
      size={48}
      thickness={30}
      speed={50}
      color="var(--primary-color)"
      secondaryColor="rgba(0, 0, 0, 0.1)"
    />
  );
}

function AuthCircle() {
  return (
    <SpinnerCircular
      size={24}
      thickness={200}
      speed={150}
      color="#fff"
      secondaryColor="rgba(255, 255, 255, 0.25)"
    />
  );
}

const getShape = (type) => {
  switch (type) {
    case "primary":
      return <Circle />;
    case "small":
      return <SmallCircle />;
    case "secondary":
      return <Infinity />;
    case "auth":
      return <AuthCircle />;
    case "tertiary":
      return <BiggerCircle />;
    case "message":
      return (
        <div className="message-loader">
          <Circle />
          <div className="message">
            <p>
              Our loader is on a mission to pick the freshest, happiest
              ingredients for your order.
            </p>
            <p>Get ready to savor the goodness!</p>
          </div>
        </div>
      );
    default:
      return <Circle />;
  }
};

function LoadingIndicator({ center, type, offTimeout, start }) {
  const [isWaiting, setWaiting] = useState(true);
  useEffect(() => {
    setTimeout(
      () => {
        setWaiting(false);
      },
      offTimeout ? 0 : 800
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let className = "loading-indicator-wrapper";
  if (isWaiting) return null;
  if (center) className += " center";
  if (start) className += " start";
  return (
    <TransitionWrapper>
      <div className={className}>{getShape(type)}</div>
    </TransitionWrapper>
  );
}

export { LoadingIndicator };
