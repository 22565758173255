import "./payment-status.css";

/**
 * A pill indicator denoting the payment status of the current invoice.
 * @param {String} paymentStatus 'pending-payment' || 'paid'
 * @returns
 */
function PaymentStatus({ paymentStatus }) {
  function formatPaymentStatus(paymentStatus) {
    paymentStatus =
      paymentStatus === "pending-payment" ? "pending" : paymentStatus;
    return paymentStatus?.split("-").join(" ");
  }
  if (paymentStatus) {
    return (
      <span className={`payment-status ${paymentStatus}`}>
        {formatPaymentStatus(paymentStatus)}
      </span>
    );
  }
  return null;
}

export { PaymentStatus };
