import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import {
  Button,
  LoadingIndicator,
  HomeButton,
} from "@/modules/core/components/ui";
import { resetPasswordForEmail } from "@/modules/core/api/auth";
import "../index.css";

const ForgotPasswordPage = () => {
  const emailInput = useRef(null);
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      emailInput.current.focus();
      return;
    }
    async function resetPassword() {
      try {
        setLoading(true);
        const { data, error } = await resetPasswordForEmail(email);
        console.log(data);
        if (error) {
          throw new Error();
        } else {
          toast.info(
            "A link to reset your password has been sent to your email if your email already exists."
          );
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
    await resetPassword();
  };
  return (
    <div className="login-document-container">
      <div className="login-document">
        <HomeButton />
        <h1>Forgot Password</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            ref={emailInput}
            type="email"
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          ></input>
          <div className="actions">
            <Button wide disabled={isLoading}>
              {isLoading ? (
                <LoadingIndicator offTimeout center type="auth" />
              ) : (
                "Reset Password"
              )}
            </Button>
            <div className="nav-container">
              <Link className="nav-btn secondary" to="/login">
                Log in
              </Link>
              <Link className="nav-btn tertiary" to="/signup">
                Sign up
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ForgotPasswordPage };
