import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { OutletInformationForm } from "../outlet-information-form";
import { getOutletApi } from "@/modules/registration/api/outlet_information";

const OutletInformation = ({
  outletId,
  nextPage,
  profile,
  onDeleteSuccess,
  disableDelete,
}) => {
  const [outletInformation, setOutletInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getOutlets() {
      setLoading(true);
      const outletInformation = await getOutletApi(outletId);
      setOutletInformation(outletInformation);
      setLoading(false);
    }
    getOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <OutletInformationForm
      nextPage={nextPage}
      profile={profile}
      outletInformation={outletInformation}
      setOutletInformation={setOutletInformation}
      onDeleteSuccess={onDeleteSuccess}
      disableDelete={disableDelete}
    />
  );
};

export { OutletInformation };
