import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import { formatDateTime } from "@/modules/core/utils/time";
import { getDataProtectionApi } from "@/modules/registration/api/data_protection";

const DataProtectionSection = ({ customerId }) => {
  const [dataProtection, setDataProtection] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getDataProtection() {
      setLoading(true);
      const dataProtection = await getDataProtectionApi(customerId);
      setDataProtection(dataProtection);
      setLoading(false);
    }
    getDataProtection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <Title>
          <BuildingLibraryIcon />
          <h2>Data Protection</h2>
        </Title>
        <Button href="/application/horeca/data-protection">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Bubble>
        <BubbleHeader>
          <Title>
            <PencilIcon />
            <h3>Signee Details</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <p style={{ paddingLeft: 12 }}>
            {dataProtection?.is_signed
              ? `Signed on ${formatDateTime(dataProtection?.date_signed)}`
              : "Not Signed"}
          </p>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { DataProtectionSection };
