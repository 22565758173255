import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
  PaymentStatus,
  TransitionWrapper,
} from "@/modules/core/components/ui";
import { getOrderHistoryApi } from "@/modules/core/api/order";
import { sortByDescendingDateOrder } from "@/modules/core/utils/date";
import { formatDateTime, getMonthFromNumber } from "@/modules/core/utils/time";
import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import "./orders-page.css";

export function OrdersList({ orders }) {
  function Order({ order }) {
    return (
      <Link to={order?.number.toString()} style={{ textDecoration: "none" }}>
        <div className="order">
          <div className="order-image">
            <p>L</p>
          </div>
          <div className="order-info-wrapper">
            <time className="order-date" dateTime={order.date_created}>
              {formatDateTime(order.date_created)}
            </time>
            <div className="order-info">
              <div className="order-info-detailed">
                <span className="invoice-number">{order.invoice_number}</span>
                <PaymentStatus paymentStatus={order?.payment_status} />
              </div>
              <div className="total">{formatPriceWithCurrency(order.sum)}</div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
  function OrdersInAYear({ year }) {
    const monthsInThisYear = Array.from(
      new Set(
        orders
          ?.filter((order) => order.year === year)
          ?.reduce((acc, curr) => [...acc, curr.month], [])
      )
    ).sort((a, b) => b - a);
    return (
      <div className="list-of-orders-in-a-year">
        {monthsInThisYear.map((month) => {
          const ordersInThisMonth = orders?.filter(
            (order) => order.year === year && order.month === month
          );
          return (
            <div className="months" key={`${month}-${year}`}>
              <h3 className="month-timestamp">
                {getMonthFromNumber(month)}, {year}
              </h3>
              {ordersInThisMonth?.map((order, idx) => (
                <TransitionWrapper index={idx}>
                  <Order key={order?.order_id} order={order} />
                </TransitionWrapper>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
  function Orders() {
    let years = Array.from(new Set(orders?.map((e) => e.year)));
    if (orders?.length === 0) return <div>No orders available</div>;
    return years?.map((year, idx) => (
      <ul className="year" key={year}>
        {idx > 0 && <hr />}
        <OrdersInAYear year={year} />
      </ul>
    ));
  }

  return (
    <div className="list">
      <Orders />
    </div>
  );
}

const Table = ({ orders, handleClick }) => {
  if (orders?.length === 0) return <div>No orders available</div>;
  return (
    <div className="orders-table-wrapper">
      <table className="orders-table">
        <thead>
          <tr>
            <th></th>
            <th>Invoice Number</th>
            <th>Total</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((order, idx) => (
            <tr onClick={() => handleClick(order)}>
              <td>
                <div className="order-image">
                  <p>L</p>
                </div>
              </td>
              <td className="invoice-number">{order?.invoice_number}</td>
              <td className="total">{formatPriceWithCurrency(order?.sum)}</td>
              <td>
                <PaymentStatus paymentStatus={order?.payment_status} />
              </td>
              <td>
                <time className="order-date" dateTime={order.date_created}>
                  {formatDateTime(order.date_created)}
                </time>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const OrdersPage = ({ session }) => {
  const [orders, setOrders] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getOrderHistory = async () => {
      setLoading(true);
      const orders = await getOrderHistoryApi(session?.user?.id);
      setOrders(sortByDescendingDateOrder(orders));
      setLoading(false);
    };
    getOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      text: "Orders",
      type: "text",
    },
  ];
  const handleClick = (order) => {
    navigate(order?.number.toString());
  };
  return (
    <div className="orders-page">
      <div className="orders-page-header">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>All your orders</h1>
      </div>
      <div className="action-buttons">
        <Button href="/orders/outstanding">
          <Square2StackIcon />
          View Outstanding Orders & Checkout
        </Button>
      </div>
      <div className="orders-page-body">
        {isLoading ? (
          <LoadingIndicator center />
        ) : (
          <>
            <OrdersList orders={orders} />
            <Table orders={orders} handleClick={handleClick} />
          </>
        )}
      </div>
    </div>
  );
};

export { OrdersPage };
