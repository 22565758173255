import { supabase } from "@/modules/supabase";

/**
 * Returns an order.
 * Order = [
 * {
 *  ...fields
 * }]
 * @param {Integer} order_number
 * @returns
 */
const getOrderApi = async (order_number) => {
  const { data, error } = await supabase
    .from("order_detail_view")
    .select()
    .match({ order_number });
  console.log("get order detail", data);
  console.log("get order detail error", error);
  return data;
};

const getAllOrdersApi = async (customer_id) => {
  const { data, error } = await supabase
    .from("orders_view")
    .select()
    .match({ customer_id });
  console.log("get all orders", data);
  console.log("get all orders error", error);
  return data;
};

const getOrderHistoryApi = async (customer_id) => {
  const { data, error } = await supabase
    .from("order_history")
    .select()
    .match({ customer_id });
  console.log("get order history", data);
  console.log("get order history error", error);
  return data;
};

const insertOrderApi = async (cart_id, customer_id) => {
  const { data, error } = await supabase
    .from("orders")
    .insert({ cart_id, customer_id })
    .select();
  console.log("insert order", data);
  console.log("insert order error", error);
  return data?.[0];
};

const createPendingPaymentOrderApi = async (
  cart_id,
  user_id,
  shipping_address_id
) => {
  const url = process.env.REACT_APP_SERVER_URL + "order";
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      cart_id,
      user_id,
      shipping_address_id,
    }),
  });
  const data = await response.json();
  return data;
};

export {
  getOrderApi,
  getAllOrdersApi,
  getOrderHistoryApi,
  insertOrderApi,
  createPendingPaymentOrderApi,
};
