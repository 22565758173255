import { supabase } from "@/modules/supabase";

const deleteForms = async (user_id, applicationTables) => {
  let tables = applicationTables;

  for (const table of tables) {
    // Goes through every table and deletes any existing records that the customer has
    const { data, error } = await supabase
      .from(table)
      .delete()
      .eq("user_id", user_id)
      .select();

    if (data.length > 0) {
      console.log("Record deleted", table, data);
    } else {
      console.log("Record not deleted", table, error);
    }
  }
};

export { deleteForms };
