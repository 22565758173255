import { deleteProfileApi } from "@/modules/core/api/users";

async function deleteUser(event, id, email, onDeleteSuccess) {
  event.preventDefault();
  event.stopPropagation();
  // eslint-disable-next-line
  if (confirm(`Are you sure you want to delete ${email}?`)) {
    await deleteProfileApi(id);
    onDeleteSuccess();
  }
}

export { deleteUser };
