import { Route } from "react-router-dom";
import {
  AdminDashboardPage,
  ManageProductsPage,
  ManageApplicationsPage,
  EditProductPage,
  ReviewApplicationPage,
} from "@/modules/admin/pages";
import { AdminRouteLayout } from "../layouts";

const adminRoutes = ({ auth }) => {
  return [
    <>
      <Route path="admin" element={<AdminRouteLayout />}>
        <Route path="dashboard" element={<AdminDashboardPage />} />
        <Route path="manage-products">
          <Route
            index
            element={<ManageProductsPage profile={auth?.profile} />}
          />
          <Route
            path=":productCode"
            element={
              <EditProductPage
                session={auth?.session}
                profile={auth?.profile}
              />
            }
          />
        </Route>
        <Route path="manage-applications">
          <Route index element={<ManageApplicationsPage />} />
          <Route path=":customerId" element={<ReviewApplicationPage />} />
        </Route>
      </Route>
    </>,
  ];
};
export { adminRoutes };
