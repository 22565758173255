import { UncontrolledFieldGroup } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { TwoColumn } from "../../../components/layouts";

const BusinessDetailsSection = ({
  businessRegistration,
  setBusinessRegistration,
}) => {
  return (
    <Bubble>
      <BubbleHeader>
        <Title>
          <BuildingOfficeIcon />
          <h3>Business Details</h3>
        </Title>
      </BubbleHeader>
      <FieldList>
        <UncontrolledFieldGroup
          label="Type of Business"
          formElement={
            <select
              name="type_of_business"
              value={businessRegistration?.nature_of_business}
              onChange={(e) =>
                setBusinessRegistration({
                  ...businessRegistration,
                  type_of_business: e.target.value,
                })
              }
            >
              <option>Sole Proprietorship</option>
              <option>Partnership</option>
              <option>Private Limited Co.</option>
              <option>Public Limited Co.</option>
            </select>
          }
        />
        <UncontrolledFieldGroup
          label="Business Registration No. #"
          formElement={
            <input
              type="text"
              name="business_registration_number"
              onChange={(e) =>
                setBusinessRegistration({
                  ...businessRegistration,
                  business_registration_number: e.target.value,
                })
              }
              value={businessRegistration?.business_registration_number}
              placeholder="202005123456"
              required
            />
          }
        />
        <TwoColumn>
          <UncontrolledFieldGroup
            label="Date of Incorporation"
            formElement={
              <input
                type="text"
                name="date_incorporated"
                onChange={(e) =>
                  setBusinessRegistration({
                    ...businessRegistration,
                    date_incorporated: e.target.value,
                  })
                }
                value={businessRegistration?.date_incorporated}
                placeholder="27/11/1996"
                required
              />
            }
          />
          <UncontrolledFieldGroup
            label="GST Registration No. #"
            formElement={
              <input
                type="text"
                name="gst_registration_number"
                onChange={(e) =>
                  setBusinessRegistration({
                    ...businessRegistration,
                    gst_registration_number: e.target.value,
                  })
                }
                value={businessRegistration?.gst_registration_number}
                placeholder="STN-YYMM-XXXXXXXX"
              />
            }
          />
        </TwoColumn>
        <TwoColumn>
          <UncontrolledFieldGroup
            label="Authorised Capital in RM"
            formElement={
              <input
                type="text"
                name="authorised_capital"
                onChange={(e) =>
                  setBusinessRegistration({
                    ...businessRegistration,
                    authorised_capital: e.target.value,
                  })
                }
                value={businessRegistration?.authorised_capital}
                placeholder="XXXX.XX"
                required
                pattern="^(0|[1-9]\d*)(\.\d{2})?$"
                title="Please enter a valid amount (XXXX.XX)"
              />
            }
          />
          <UncontrolledFieldGroup
            label="Paid-up Capital in RM"
            formElement={
              <input
                type="text"
                name="paid_up_capital"
                onChange={(e) =>
                  setBusinessRegistration({
                    ...businessRegistration,
                    paid_up_capital: e.target.value,
                  })
                }
                value={businessRegistration?.paid_up_capital}
                placeholder="XXXX.XX"
                required
                pattern="^(0|[1-9]\d*)(\.\d{2})?$"
                title="Please enter a valid amount (XXXX.XX)"
              />
            }
          />
        </TwoColumn>
      </FieldList>
    </Bubble>
  );
};

export { BusinessDetailsSection };
