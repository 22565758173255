import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, PageTitle } from "../../components/ui";
import { getTermsAndConditionsApi } from "@/modules/registration/api/terms_and_conditions";
import { TermsAndConditionsForm } from "./terms-and-conditions-form";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";

const HorecaTermsAndConditionsPage = ({ profile }) => {
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getTermsAndConditions() {
      setLoading(true);
      const termsAndConditions = await getTermsAndConditionsApi(profile?.id);
      setTermsAndConditions(termsAndConditions);
      setLoading(false);
    }
    getTermsAndConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="registration-page">
      <PageTitle>
        <BuildingLibraryIcon />
        <h1>Terms and Conditions</h1>
      </PageTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="legal-page">
            <Blockquote>
              <div>
                <p>
                  1. &nbsp; All payments must be made in full on due date and/or
                  within the credit period stated in our
                  billing/invoice/delivery order
                </p>
                <p>
                  2. &nbsp; Interest charge at 1.5% per month will be charged on
                  the overdue invoices
                </p>
                <p>
                  3. &nbsp; The company reserves the right to suspend credit or
                  cancel the credit facilities granted without prior notice
                </p>
              </div>
            </Blockquote>
            <TermsAndConditionsForm
              termsAndConditions={termsAndConditions}
              profile={profile}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { HorecaTermsAndConditionsPage };
