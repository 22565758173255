import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApplicationLoadingIndicator,
  LoadingIndicator,
} from "@/modules/core/components/ui";
import { insertRetailInformationApi } from "@/modules/registration/api/retail_information";
import { DeliveryAddressSection } from "../delivery-address-section";
import { ContactInfoSection } from "../contact-info-section";
import { BusinessTypeSection } from "../business-type-section";
import { Divider, FormFooter } from "../../../components/ui";
import { useRetailInformation } from "../hooks/use-retail-information";

const KEYS = [
  "person_in_charge",
  "address_line_1",
  "address_line_2",
  "postal_code",
  "city",
  "state",
  "country",
  "phone_number",
  "nature_of_business",
  "specification",
];

const RetailInformationForm = ({ nextPage, profile }) => {
  const [isLoading, retailInformation, setRetailInformation] =
    useRetailInformation({ profile });
  const [isSaving, setSaving] = useState(false);
  const navigate = useNavigate();
  const onBackClick = () => {
    navigate("/application/introduction");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let map = {};
    KEYS.forEach((e) => (map[e] = event.target[e].value));
    map["user_id"] = profile?.id;
    console.log("map", map);
    async function insertRetailInformation(map) {
      setSaving(true);
      await insertRetailInformationApi(map);
      setTimeout(() => {
        setSaving(false);
        navigate(nextPage);
      }, 800);
    }
    insertRetailInformation(map);
  };

  if (isLoading) return <LoadingIndicator />;
  return (
    <form onSubmit={handleSubmit}>
      <DeliveryAddressSection
        retailInformation={retailInformation}
        setRetailInformation={setRetailInformation}
      />
      <Divider />
      <ContactInfoSection
        retailInformation={retailInformation}
        setRetailInformation={setRetailInformation}
      />
      <Divider />
      <BusinessTypeSection
        retailInformation={retailInformation}
        setRetailInformation={setRetailInformation}
      />
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
};

export { RetailInformationForm };
