import {
  Bubble,
  BubbleHeader,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import { HomeModernIcon } from "@heroicons/react/24/solid";

const ReviewDeliveryAddressSection = ({ retailInformation }) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <HomeModernIcon />
            <h4>Address</h4>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Address Line 1"
            value={retailInformation?.address_line_1}
          />
          <ReviewField
            label="Address Line 2"
            value={retailInformation?.address_line_2}
          />
          <TwoColumn>
            <ReviewField
              label="Postal Code"
              value={retailInformation?.postal_code}
            />
            <ReviewField label="City" value={retailInformation?.city} />
          </TwoColumn>
          <TwoColumn>
            <ReviewField label="State" value={retailInformation?.state} />
            <ReviewField label="Country" value={retailInformation?.country} />
          </TwoColumn>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { ReviewDeliveryAddressSection };
