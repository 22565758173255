import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { Divider, FormFooter, Title } from "../../../components/ui";
import { insertBillingInformationApi } from "@/modules/registration/api/billing_information";
import { PrimaryBillingContactSection } from "../primary-billing-contact-section";
import { PrimaryPaymentContactSection } from "../primary-payment-contact-section";
import { SecondaryBillingContactSection } from "../secondary-billing-contact-section";
import { SecondaryPaymentContactSection } from "../secondary-payment-contact-section";
import { BillingAndPaymentConditionsSection } from "../billing-and-payment-conditions-section";
import { UserIcon } from "@heroicons/react/24/solid";
import { CreditCardIcon } from "@heroicons/react/24/solid";
import config from "../config.json";

function HorecaBillingInformationForm({
  billingInformation,
  setBillingInformation,
  profile,
}) {
  const navigate = useNavigate();
  const [isSaving, setSaving] = useState(false);
  function onBackClick() {
    navigate("/application/horeca/banking-details");
  }
  function handleSubmit(event) {
    event.preventDefault();
    let map = {};
    config?.keys.forEach((e) => (map[e] = event.target[e].value));
    map["user_id"] = profile?.id;
    console.log("map", map);

    async function insertBillingInformation(map) {
      setSaving(true);
      await insertBillingInformationApi(map);
      setTimeout(() => {
        setSaving(false);
        navigate("/application/horeca/reference-information");
      }, 800);
    }
    insertBillingInformation(map);
  }
  return (
    <form onSubmit={handleSubmit}>
      <section>
        <Title>
          <UserIcon />
          <h2>Primary Contact</h2>
        </Title>
        <Divider />
        <PrimaryBillingContactSection
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
        />
        <Divider />
        <PrimaryPaymentContactSection
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
        />
      </section>
      <Divider />
      <section>
        <Title>
          <UserIcon />
          <h2>Secondary Contact</h2>
        </Title>
        <Divider />
        <SecondaryBillingContactSection
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
        />
        <Divider />
        <SecondaryPaymentContactSection
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
        />
      </section>
      <Divider />
      <section>
        <Title>
          <CreditCardIcon />
          <h2>Conditions</h2>
        </Title>
        <Divider />
        <BillingAndPaymentConditionsSection
          billingInformation={billingInformation}
          setBillingInformation={setBillingInformation}
        />
      </section>
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
}

export { HorecaBillingInformationForm };
