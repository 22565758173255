import { supabase } from "@/modules/supabase";

const getRetailStaffFormApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_retail_staff_form")
    .select()
    .match({ user_id });
  console.log("get forms_retail_staff_form api", data);
  console.log("get forms_retail_staff_form api error", error);
  return data?.[0];
};

const insertRetailStaffFormApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_retail_staff_form")
    .upsert(keys)
    .select();
  console.log("insert forms_retail_staff_form api", data);
  console.log("insert forms_retail_staff_form api error", error);
  return data?.[0];
};

export { getRetailStaffFormApi, insertRetailStaffFormApi };
