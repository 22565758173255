import { useState, useEffect } from "react";
import { getRetailInformationApi } from "@/modules/registration/api/retail_information";

const useRetailInformation = ({ profile }) => {
  const [retailInformation, setRetailInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getRetailInformation() {
      setLoading(true);
      const retailInformation = await getRetailInformationApi(profile?.id);
      setRetailInformation(retailInformation);
      setLoading(false);
    }
    getRetailInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [isLoading, retailInformation, setRetailInformation];
};

export { useRetailInformation };
