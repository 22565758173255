import { Link } from "react-router-dom";
import { Product } from "@/modules/core/components/gadgets";
import { Market } from "@/modules/img/public-pages";
import {
  BananaSpringRoll,
  BananaFritters,
  CocktailKaripap,
  Murtabak,
  RotiBoom,
  Samosa,
} from "@/modules/img/food/snacks";
import "../(misc)/public-misc.css";
import "./catalogue-page.css";

const CataloguePage = () => {
  return (
    <div className="catalogue-page page">
      <div className="page-header">
        <img src={Market} alt="Market" />
        <h1>Catalogue</h1>
      </div>
      <section className="page-body">
        <div className="food-list">
          <Product img={BananaSpringRoll} title="Spring Roll" />
          <Product img={CocktailKaripap} title="Karipap" />
          <Product img={Samosa} title="Samosa" />
          <Product img={BananaFritters} title="Banana Fritters" />
          <Product img={Murtabak} title="Murtabak" />
          <Product img={RotiBoom} title="Roti Boom" />
        </div>
      </section>
      <Link className="button" to="/signup">
        Sign up
      </Link>
    </div>
  );
};

export { CataloguePage };
