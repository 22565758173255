import { Field } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import { HomeModernIcon } from "@heroicons/react/24/solid";

const DeliveryAddressSection = ({
  retailInformation,
  setRetailInformation,
}) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <HomeModernIcon />
            <h3>Delivery Address</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="address_line_1"
            label="Address Line 1"
            onChangeFn={(e) =>
              setRetailInformation({
                ...retailInformation,
                address_line_1: e.target.value,
              })
            }
            input={retailInformation?.address_line_1}
            placeholder="No. 44, Jalan Desa Melur 4/1"
            required
          />
          <Field
            name="address_line_2"
            label="Address Line 2"
            onChangeFn={(e) =>
              setRetailInformation({
                ...retailInformation,
                address_line_2: e.target.value,
              })
            }
            input={retailInformation?.address_line_2}
            placeholder="Taman Bandar Connaught"
          />
          <TwoColumn className="two-column">
            <Field
              name="postal_code"
              label="Postal Code"
              onChangeFn={(e) =>
                setRetailInformation({
                  ...retailInformation,
                  postal_code: e.target.value,
                })
              }
              input={retailInformation?.postal_code}
              placeholder="56000"
              required
            />
            <Field
              name="city"
              label="City"
              onChangeFn={(e) =>
                setRetailInformation({
                  ...retailInformation,
                  city: e.target.value,
                })
              }
              input={retailInformation?.city}
              placeholder="Cheras"
              required
            />
          </TwoColumn>
          <TwoColumn className="two-column">
            <Field
              name="state"
              label="State"
              onChangeFn={(e) =>
                setRetailInformation({
                  ...retailInformation,
                  state: e.target.value,
                })
              }
              input={retailInformation?.state}
              placeholder="Kuala Lumpur"
              required
            />
            <Field
              name="country"
              label="Country"
              onChangeFn={(e) =>
                setRetailInformation({
                  ...retailInformation,
                  country: e.target.value,
                })
              }
              input={retailInformation?.country}
              placeholder="Malaysia"
              required
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { DeliveryAddressSection };
