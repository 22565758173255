import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import { getDeclarationApi } from "@/modules/registration/api/declaration";

const DeclarationSection = ({ customerId }) => {
  const [declaration, setDeclaration] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getDeclaration() {
      setLoading(true);
      const declaration = await getDeclarationApi(customerId);
      setDeclaration(declaration);
      setLoading(false);
    }
    getDeclaration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <Title>
          <BuildingLibraryIcon />
          <h2>Declaration</h2>
        </Title>
        <Button href="/application/horeca/declaration">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Bubble>
        <BubbleHeader>
          <Title>
            <PencilIcon />
            <h3>Signee Details</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <p style={{ paddingLeft: 12 }}>
            {declaration?.name}, {declaration?.position}, signed at{" "}
            {declaration?.date}
          </p>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { DeclarationSection };
