import { Route } from "react-router-dom";
import {
  RetailRegistrationLayout,
  HorecaRegistrationLayout,
  IntroductionPage,
  RetailInformationPage,
  RetailReviewPage,
  HorecaBusinessRegistrationPage,
  HorecaBusinessRegistrationUploadFormsPage,
  HorecaBankingDetailsPage,
  HorecaBillingInformationPage,
  HorecaReferenceInformationPage,
  HorecaOutletInformationPage,
  HorecaTermsAndConditionsPage,
  HorecaDataProtectionPage,
  HorecaDeclarationPage,
  HorecaReviewPage,
  ApplicationStatusPendingPage,
  ApplicationStatusRejectedPage,
} from "@/modules/registration/pages";
import { HomePage } from "@/modules/customer/pages";

const getRoutes = ({ auth, initializeAuth }) => {
  switch (auth?.profile?.status) {
    case "pending-review":
      return [
        <Route
          path="pending-approval"
          element={<ApplicationStatusPendingPage />}
        />,
      ];
    case "rejected":
      return [
        <Route
          path="rejected"
          element={
            <ApplicationStatusRejectedPage
              profile={auth?.profile}
              onUpdateStatusCallBack={initializeAuth}
            />
          }
        />,
      ];
    case "approved":
      return [<Route path="home" element={<HomePage />} />];
    default:
      return [
        <>
          <Route
            path="introduction"
            element={<IntroductionPage profile={auth?.profile} />}
          />
          <Route path="horeca" element={<HorecaRegistrationLayout />}>
            <Route
              path="business-registration"
              element={
                <HorecaBusinessRegistrationPage profile={auth?.profile} />
              }
            />
            <Route
              path="business-registration-upload-forms"
              element={
                <HorecaBusinessRegistrationUploadFormsPage
                  session={auth?.session}
                />
              }
            />
            <Route
              path="banking-details"
              element={<HorecaBankingDetailsPage profile={auth?.profile} />}
            />
            <Route
              path="billing-information"
              element={<HorecaBillingInformationPage profile={auth?.profile} />}
            />
            <Route
              path="reference-information"
              element={
                <HorecaReferenceInformationPage
                  previousPage="/application/horeca/billing-information"
                  nextPage="/application/horeca/outlet-information"
                  type="horeca"
                  profile={auth?.profile}
                />
              }
            />
            <Route
              path="outlet-information"
              element={
                <HorecaOutletInformationPage
                  nextPage="/application/horeca/terms-and-conditions"
                  type="horeca"
                  profile={auth?.profile}
                />
              }
            />
            <Route
              path="terms-and-conditions"
              element={
                <HorecaTermsAndConditionsPage
                  type="horeca"
                  profile={auth?.profile}
                />
              }
            />
            <Route
              path="data-protection"
              element={
                <HorecaDataProtectionPage
                  type="horeca"
                  profile={auth?.profile}
                />
              }
            />
            <Route
              path="declaration"
              element={
                <HorecaDeclarationPage type="horeca" profile={auth?.profile} />
              }
            />
            <Route
              path="review"
              element={
                <HorecaReviewPage
                  type="horeca"
                  profile={auth?.profile}
                  onReviewSuccessCallback={initializeAuth}
                />
              }
            />
          </Route>
          <Route path="retail" element={<RetailRegistrationLayout />}>
            <Route
              path="retail-information"
              element={
                <RetailInformationPage
                  nextPage="/application/retail/review"
                  type="retail"
                  profile={auth?.profile}
                />
              }
            />
            <Route
              path="review"
              element={
                <RetailReviewPage
                  type="retail"
                  profile={auth?.profile}
                  onReviewSuccessCallback={initializeAuth}
                />
              }
            />
          </Route>
        </>,
      ];
  }
};
const registrationRoutes = ({ auth, initializeAuth, ReRoute }) => {
  return [
    <Route path="application">
      <Route index element={<ReRoute />} />
      {getRoutes({ auth, initializeAuth })}
    </Route>,
  ];
};

export { registrationRoutes };
