import { Outlet } from "react-router-dom";
import { PublicNavBar } from "@/modules/core/components/gadgets";
import { Footer } from "@/modules/core/components/sections";
import "./public-layout.css";

function LandingPageRouteLayout() {
  return (
    <div className="landing-page-layout">
      <PublicNavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

export { LandingPageRouteLayout };
