/**
 * Removes the prefix "P_" if the price tag begins with "P_"
 *
 * @param {String} priceTagId A string that represents the price tag ID
 * @returns
 */

const PORTAL_TO_SQL_MAPPING = {
  "P_AGENT": "AGENT",
  "P_C.O.D": "CASH",
  "P_C14 Days": "CREDIT 14",
  "P_C30 Days": "CREDIT 30",
  "P_C7 Days": "CREDIT 7",
  "P_DIST": "DIST",
  "P_PROMO": "PROMO",
  "P_RETAIL": "RETAIL",
};

const formatPriceTag = (priceTagId) => {
  const priceTag = PORTAL_TO_SQL_MAPPING[priceTagId];
  return priceTag ?? "";
}    

export { formatPriceTag };
