import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, Divider, PageTitle, Title } from "../../components/ui";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { OutletInformation } from "./outlet-information";
import {
  insertOutletInformationApi,
  getOutletsApi,
  deleteAllOutletInformationForUser,
} from "@/modules/registration/api/outlet_information";
import "./horeca-outlet-information-page.css";

const HorecaOutletInformationPage = ({ nextPage, profile }) => {
  const [outlets, setOutlets] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [
    isBusinessRegistrationAddressSoleOutlet,
    setIsBusinessRegistrationAddressSoleOutlet,
  ] = useState(false);

  function onBackClick() {
    navigate("/application/horeca/reference-information");
  }
  function onNextClick() {
    navigate(nextPage);
  }
  async function getOutlets() {
    setLoading(true);
    const outlets = await getOutletsApi(profile?.id);
    setOutlets(outlets);
    setIsBusinessRegistrationAddressSoleOutlet(outlets?.length === 0);
    setLoading(false);
  }
  useEffect(() => {
    getOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function createNewOutlet() {
    await insertOutletInformationApi({ user_id: profile?.id });
    getOutlets();
  }

  async function onToggleBusinessRegistrationAddressAsSoleOutlet(e) {
    if (!isBusinessRegistrationAddressSoleOutlet) {
      if (
        outlets.length >= 1 &&
        // eslint-disable-next-line no-restricted-globals
        confirm(
          "Are you sure you want to use business registration address as sole outlet? This will delete all outlets filled up."
        )
      ) {
        await deleteAllOutletInformationForUser(profile?.id);
        setOutlets(null);
      }
    }
    setIsBusinessRegistrationAddressSoleOutlet(
      !isBusinessRegistrationAddressSoleOutlet
    );
  }

  return (
    <div className="outlet-information-page registration-page">
      <div className="outlet-information-page-body">
        <PageTitle>
          <BuildingOfficeIcon />
          <h1>Outlet Information</h1>
        </PageTitle>
        <Blockquote>
          <InformationCircleIcon />
          We require outlet information to allow us to properly assess your
          eligibility. Once your credit rankings and other criteria has been
          assessed, we will send you an email accordingly.
        </Blockquote>
        <Divider />
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          outlets?.map((outlet, idx) => (
            <>
              <Divider />
              <Title>
                <BuildingOfficeIcon />
                <h2>Outlet #{idx + 1}</h2>
              </Title>
              <Divider />
              <OutletInformation
                key={outlet?.outlet_information_id}
                outletId={outlet?.outlet_information_id}
                nextPage={nextPage}
                profile={profile}
                onDeleteSuccess={getOutlets}
              />
              <hr style={{ borderTop: "1px solid var(--secondary-color)" }} />
            </>
          ))
        )}
        <Divider />
        <div className="outlet-information-control">
          <fieldset>
            <legend>Outlet Options</legend>
            <label>
              <input
                type="checkbox"
                checked={isBusinessRegistrationAddressSoleOutlet}
                onChange={onToggleBusinessRegistrationAddressAsSoleOutlet}
              />
              Use business registration address as sole outlet
            </label>
          </fieldset>
          <button
            onClick={createNewOutlet}
            disabled={isBusinessRegistrationAddressSoleOutlet}
          >
            Add New Outlet
          </button>
        </div>
        <Divider />
        <div className="form-footer">
          <button className="prev-button" type="button" onClick={onBackClick}>
            Previous
          </button>
          <button className="next-button" type="submit" onClick={onNextClick}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export { HorecaOutletInformationPage };
