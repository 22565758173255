import { useNavigate } from "react-router-dom";
import { Banner } from "@/modules/core/components/ui";

const BannerSection = () => {
  const navigate = useNavigate();
  const onBannerButtonClick = () => {
    navigate("/market");
  };
  return (
    <Banner
      message="Exclusive offers at our store today! Take 15% off!"
      buttonDisplayText="Shop Now"
      onButtonClick={onBannerButtonClick}
    />
  );
};

export { BannerSection };
