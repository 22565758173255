import { supabase } from "@/modules/supabase";

const getAllUserShippingAddressesByUserId = async (user_id) => {
  const { data, error } = await supabase
    .from("users_shipping_addresses_view")
    .select("*")
    .eq("user_id", user_id)
    .eq("is_deleted", false);
  console.log("get all user addresses by user id api", data);
  console.log("get all user addresses by user id api error", error);
  return data;
};

const getOneShippingAddressByAddressId = async (address_id) => {
  const { data, error } = await supabase
    .from("users_shipping_addresses_view")
    .select()
    .eq("address_id", address_id);
  console.log("get one shipping address by address id api", data);
  console.log("get one shipping address by address id api error", error);
  return data?.[0];
};

const insertOneShippingAddress = async (map) => {
  const { data, error } = await supabase
    .from("users_shipping_addresses")
    .upsert(map)
    .select();
  console.log("insert one shipping address api", data);
  console.log("insert one shipping address api error", error);
  return data;
};

const deleteOneShippingAddressByAddressId = async (address_id) => {
  const { data, error } = await supabase
    .from("users_shipping_addresses")
    .update({ is_deleted: true, deleted_at: new Date() })
    .eq("address_id", address_id)
    .select();
  console.log("delete one shipping address by address id api", data);
  console.log("delete one shipping address by address id api error", error);
  return data;
};

export {
  getAllUserShippingAddressesByUserId,
  getOneShippingAddressByAddressId,
  insertOneShippingAddress,
  deleteOneShippingAddressByAddressId,
};
