import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getApplicationApi,
  insertApplicationApi,
  updateApplicationCategoryApi,
} from "../../api/application";
import {
  hasExistingRecordsInTables,
  deleteForms,
  hasExistingInitiatedApplication,
} from "../../utils";
import "./introduction-page.css";

const HORECA_FORM_TABLES = [
  "forms_business_registration_information",
  "forms_form9",
  "forms_form24",
  "forms_form49",
  "forms_banking_details",
  "forms_billing_information",
  "forms_reference_information",
  "forms_outlet_information",
  "forms_terms_and_conditions",
  "forms_data_protection",
  "forms_declaration",
];

const RETAIL_FORM_TABLES = ["forms_retail_information"];

const IntroductionPage = ({ profile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickApply = async (
    user_id,
    category,
    confirmMessage,
    tableToCheck,
    tableToDelete,
    navigateTo
  ) => {
    const hasTableRecords = await hasExistingRecordsInTables(
      user_id,
      tableToCheck
    );
    if (hasTableRecords) {
      if (
        // eslint-disable-next-line
        confirm(confirmMessage)
      ) {
        setIsLoading(true);
        // Deletes all previously filled tables and updates the user's application category.
        await Promise.all([
          deleteForms(user_id, tableToDelete),
          updateApplicationCategoryApi(user_id, category),
        ]);
        setIsLoading(false);
        navigate(navigateTo);
      }
    } else {
      setIsLoading(true);
      // Case where user has created an application under "initiated" but has not filled any tables/forms.
      const hasInitiatedApplication =
        await hasExistingInitiatedApplication(user_id);
      if (hasInitiatedApplication) {
        const userApplication = await getApplicationApi(user_id, category);
        if (!userApplication) {
          // If the user does not have an application under "category", update it to match the new category.
          // Eg: If user just created a new "initiated" application under HORECA and switches to Retail,
          //     the application category is changed to Retail.
          await updateApplicationCategoryApi(user_id, category);
        }
      } else {
        // If no existing application exists, create a new one.
        await insertApplicationApi(user_id, category, "initiated");
      }
      setIsLoading(false);
      navigate(navigateTo);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="center-container">
          <LoadingIndicator offTimeout type="tertiary" />
        </div>
      ) : (
        <div className="application-layout-body" style={{ display: "initial" }}>
          <div className="introduction-page" style={{ textAlign: "center" }}>
            <blockquote>
              <h1 className="title">Registration</h1>
              <div>
                <p>
                  Welcome to the Lotus Consulting Group registration process.
                </p>
                <p>
                  Please fill out the information requested to be considered as
                  one of our partners.
                </p>
                <p>
                  Once the forms are filled, a staff member will contact you
                  shortly.
                </p>
              </div>
            </blockquote>
            <div className="actions">
              <Button
                onClick={() =>
                  handleClickApply(
                    profile?.id,
                    "horeca",
                    "Are you sure want to start applying as horeca? All entered retail information will be deleted.",
                    RETAIL_FORM_TABLES,
                    RETAIL_FORM_TABLES,
                    "/application/horeca/business-registration"
                  )
                }
              >
                Apply as HORECA (Hotel/ Restaurant/ Cafe/ Bistro/ Bar)
              </Button>
              <Button
                onClick={() =>
                  handleClickApply(
                    profile?.id,
                    "retail",
                    "Are you sure want to start applying as retail? All entered horeca information will be deleted.",
                    HORECA_FORM_TABLES,
                    HORECA_FORM_TABLES,
                    "/application/retail/retail-information"
                  )
                }
              >
                Apply as Retail (Individual/ Enterprise/ Home Business)
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { IntroductionPage };
