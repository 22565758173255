import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfilePlaceholderLarge } from "@/modules/core/components/ui";
import { CameraIcon } from "@heroicons/react/24/solid";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { Bubble, FieldList } from "@/modules/registration/components/ui";
import {
  getOneProfileById,
  updateOneProfileById,
} from "@/modules/core/api/users";
import { EditProfileField } from "./field-components/field-components";
import PhoneInput from "react-phone-number-input";
import styles from "./edit-profile-page.module.css";

const EditProfilePage = ({ session, profile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editProfileForm, setEditProfileForm] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (column, value) => {
    setEditProfileForm({ ...editProfileForm, [column]: value });
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    await updateOneProfileById(profile?.id, editProfileForm);
    setIsLoading(false);
    navigate("/profile");
  };

  useEffect(() => {
    async function getProfileFormDetails() {
      setIsLoading(true);
      const profileDetails = await getOneProfileById(profile?.id);
      setEditProfileForm({
        first_name: profileDetails?.first_name,
        last_name: profileDetails?.last_name,
        personal_phone: profileDetails?.personal_phone,
      });
      setIsLoading(false);
    }
    getProfileFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingIndicator center />
      ) : (
        <div className={styles["edit-profile-page"]}>
          <h1>Edit Profile</h1>
          <div className={styles["edit-profile-body"]}>
            <UserProfilePlaceholderLarge />
            <button
              className={styles["camera-button"]}
              onClick={() => console.log("changing avatar")}
            >
              <CameraIcon />
            </button>
            <div className={styles["edit-profile-fields"]}>
              <Bubble>
                <FieldList>
                  <EditProfileField
                    label="First Name"
                    formElement={
                      <input
                        type="text"
                        name="first_name"
                        value={editProfileForm?.first_name}
                        onChange={(e) =>
                          handleInputChange("first_name", e.target.value)
                        }
                        placeholder="John"
                      />
                    }
                  />
                  <EditProfileField
                    label="Last Name"
                    formElement={
                      <input
                        type="text"
                        name="last_name"
                        value={editProfileForm?.last_name}
                        onChange={(e) =>
                          handleInputChange("last_name", e.target.value)
                        }
                        placeholder="Smith"
                      />
                    }
                  />
                  <EditProfileField
                    label="Phone"
                    formElement={
                      <PhoneInput
                        name="personal_phone"
                        value={editProfileForm?.personal_phone}
                        onChange={(value) =>
                          handleInputChange("personal_phone", value)
                        }
                        defaultCountry="MY"
                        limitMaxLength
                        international
                        countryCallingCodeEditable={false}
                      />
                    }
                  />
                </FieldList>
              </Bubble>
              <div className={styles["buttons"]}>
                <Button wide onClick={() => navigate("/profile")}>
                  Cancel
                </Button>
                <Button color="green" wide onClick={() => handleSaveClick()}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { EditProfilePage };
