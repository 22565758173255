import styles from "./review-field.module.css";

const ReviewField = ({ label, value }) => {
  return (
    <div className={styles["review-field"]}>
      <span className={styles["label"]}>{label}</span>
      <span className={styles["value"]}>{value}</span>
    </div>
  );
};

export { ReviewField };
