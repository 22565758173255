import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, LoadingIndicator } from "@/modules/core/components/ui";
import { getAllStatementOfAccountsApi } from "@/modules/core/api/statement_of_accounts";
import { getMonthFromNumber } from "@/modules/core/utils/time";
import "./statement-of-accounts-page.css";

function StatementOfAccount({ month, year }) {
  return (
    <div className="statement-of-account">
      <Link to={month.toString() + "-" + year.toString()}>
        <div>
          {getMonthFromNumber(month)} {year}
        </div>
      </Link>
    </div>
  );
}
const StatementOfAccountsPage = ({ session }) => {
  const [statementOfAccounts, setStatementOfAccounts] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  async function getStatementOfAccounts() {
    setLoading(true);
    const statementOfAccounts = await getAllStatementOfAccountsApi(
      session?.user?.id
    );
    setStatementOfAccounts(statementOfAccounts);
    setLoading(false);
  }
  useEffect(() => {
    getStatementOfAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      text: "Statement of Accounts",
      type: "text",
    },
  ];
  let years = Array.from(
    new Set(statementOfAccounts?.map((e) => e.year_issued))
  ).sort((a, b) => b - a);
  return (
    <div className="statement-of-accounts-page">
      <h1>Statement of Accounts</h1>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {isLoading ? (
        <LoadingIndicator center />
      ) : (
        <div>
          <div className="list">
            {years?.map((year) => (
              <ul className="year" key={year}>
                <h2>{year}</h2>
                <hr />
                <div className="months">
                  {statementOfAccounts
                    ?.filter((e) => e.year_issued === year)
                    ?.sort((a, b) => b.month_issued - a.month_issued)
                    ?.map((e) => (
                      <StatementOfAccount
                        key={`${e.month_issued}-${e.year_issued}`}
                        month={e.month_issued}
                        year={e.year_issued}
                      />
                    ))}
                </div>
              </ul>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { StatementOfAccountsPage };
