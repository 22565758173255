import { supabase } from "@/modules/supabase";

const getRejectedRemarkApi = async (user_id, staffFormTable) => {
  const { data, error } = await supabase
    .from(staffFormTable)
    .select("remark")
    .eq("user_id", user_id);
  console.log("get rejected remark", data);
  console.log("get rejected remark error", error);
  return { data: data?.[0], error: error };
};

export { getRejectedRemarkApi };
