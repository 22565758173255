import { FilterList } from "./filter-list";

function FilterPanel({ filters }) {
  return (
    <section>
      {filters?.map((filter) => (
        <FilterList
          key={filter.title}
          type={filter.type}
          title={filter.title}
          elements={filter.elements}
          filterState={filter.filterState}
          filterTrigger={filter.filterTrigger}
        />
      ))}
    </section>
  );
}

export { FilterPanel };
