import { supabase } from "@/modules/supabase";

const getRetailInformationApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_retail_information")
    .select()
    .match({ user_id });
  console.log("get forms.retail_information api", data);
  console.log("get forms.retail_information api error", error);
  return data?.[0];
};

const insertRetailInformationApi = async ({
  person_in_charge,
  address_line_1,
  address_line_2,
  postal_code,
  city,
  state,
  country,
  phone_number,
  nature_of_business,
  specification,
  user_id,
}) => {
  const { data, error } = await supabase
    .from("forms_retail_information")
    .upsert({
      person_in_charge,
      address_line_1,
      address_line_2,
      postal_code,
      city,
      state,
      country,
      phone_number,
      nature_of_business,
      specification,
      user_id,
    })
    .select();
  console.log("insert forms.retail_information api", data);
  console.log("insert forms.retail_information api error", error);
  return data?.[0];
};

export { getRetailInformationApi, insertRetailInformationApi };
