import {
  LoadingIndicator,
  TransitionWrapper,
} from "@/modules/core/components/ui";
import { NumItemsFoundMessage } from "./num-items-found-message";
import { ProductLink } from "../product-link";
import "./main-panel.css";

function PictureAggregator({ products, managementMode }) {
  return products?.map((product, idx) => (
    <TransitionWrapper index={idx}>
      <ProductLink
        key={product?.CODE}
        product={product}
        managementMode={managementMode}
      />
    </TransitionWrapper>
  ));
}

function MainPanel({ products, isLoading, managementMode }) {
  return isLoading ? (
    <LoadingIndicator type="message" start />
  ) : (
    <div className="picture-grid">
      <NumItemsFoundMessage numItems={products?.length} />
      <PictureAggregator products={products} managementMode={managementMode} />
    </div>
  );
}

export { MainPanel };
