import {
  BusinessRegistration,
  FormList,
  BankingDetails,
  BillingInformation,
  ReferenceInformation,
  Outlets,
  TermsAndConditions,
  DataProtection,
  Declaration,
  HORECAStaffForm,
} from "./components";
import { Header, Status, Divider, Footer } from "../common";

const HORECADocument = ({ application, customerId, onFormSubmitCallback }) => {
  return (
    <>
      <Header application={application} />
      <Status application={application} />
      <Divider />
      <BusinessRegistration customerId={customerId} />
      <Divider />
      <FormList customerId={customerId} />
      <Divider />
      <BankingDetails customerId={customerId} />
      <Divider />
      <BillingInformation customerId={customerId} />
      <Divider />
      <ReferenceInformation customerId={customerId} />
      <Divider />
      <Outlets customerId={customerId} />
      <Divider />
      <TermsAndConditions customerId={customerId} />
      <Divider />
      <DataProtection customerId={customerId} />
      <Divider />
      <Declaration customerId={customerId} />
      <Divider />
      <HORECAStaffForm
        application={application}
        customerId={customerId}
        onFormSubmitCallback={onFormSubmitCallback}
      />
      <Footer />
    </>
  );
};

export { HORECADocument };
