import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { Blockquote, PageTitle } from "../../components/ui";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";
import { getDataProtectionApi } from "@/modules/registration/api/data_protection";
import { DataProtectionForm } from "./data-protection-form";

const HorecaDataProtectionPage = ({ profile }) => {
  const [dataProtection, setDataProtection] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getDataProtection() {
      setLoading(true);
      const dataProtection = await getDataProtectionApi(profile?.id);
      setDataProtection(dataProtection);
      setLoading(false);
    }
    getDataProtection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="registration-page">
      <PageTitle>
        <BuildingLibraryIcon />
        <h1>Data Protection</h1>
      </PageTitle>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="legal-page">
          <Blockquote>
            <div>
              <p>
                We will make search with a Credit Reference Agency, which will
                keep a record of that search and will share that information
                with us and other businesses.
              </p>
              <p>
                In some instances we may also make a search on the personal
                credit file of principal director(s).
              </p>
              <p>
                We may also pass or share your information with carefully
                selected third parties for the purposes of account opening,
                credit vetting and account management.
              </p>
              <p>
                By submitting information on this form, you confirm that you
                have the consent of all relevant individuals to the processing
                of their personal data for the purposes stated, including but
                not limited to partners, directors and/or shareholders whose
                details may be obtained as a result of checks against the
                address you provide.
              </p>
            </div>
          </Blockquote>
          <DataProtectionForm
            dataProtection={dataProtection}
            profile={profile}
          />
        </div>
      )}
    </div>
  );
};

export { HorecaDataProtectionPage };
