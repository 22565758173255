import { Link } from "react-router-dom";
import { AboutUs } from "@/modules/img/public-pages";
import "../(misc)/public-misc.css";

const AboutUsPage = () => {
  return (
    <div className="about-us-page page">
      <div className="page-header">
        <img src={AboutUs} alt="About Us" />
        <h1>About Us</h1>
      </div>
      <section>
        <h2>Who We Are</h2>
        <p>
          Established in 1992, Lotus Consulting Group is a leading frozen food
          distribution company, specializing in a wide range of frozen products
          to HORECA in the Klang Valley.
        </p>
      </section>
      <section>
        <h2>What We Do</h2>
        <p>
          We specialize in providing value-added food products that are made in
          accordance with strict local and international food safety standards
          such as HALAL, GMP, HACCP and ISO.
        </p>
      </section>
      <section>
        <h2>What We Practice</h2>
        <p>
          Our commitment to quality is reflected in everything we do, with a
          dedicated logistics team and freezer compliant trucks to ensure
          professional deliveries every time.
        </p>
      </section>
      <section>
        <h2>What We Accomplished</h2>
        <p>
          In recent years, the company developed two home grown brands to meet
          the ever-changing needs of our customers and provide them with the
          best possible value.
        </p>
      </section>
      <section>
        <h2>Join Us</h2>
        <p>
          Choose us for your product development, manufacturing, and
          distribution needs and experience the difference that quality and
          service can make.
        </p>
      </section>
      <Link className="button" to="/signup">
        Sign up
      </Link>
    </div>
  );
};

export { AboutUsPage };
