const INITIAL_VALUE = {
  account_number: "",
  sales_person_code: "",
  submitted_by: "",
  approved_by: "",
  date_submitted: "",
  remark: "",
  price_tag: "P_C.O.D",
  agent: "",
  term: "P_Cash",
};

export { INITIAL_VALUE };
