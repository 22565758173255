async function sendEmail({ submitterName }) {
  try {
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + `send?submitterName=${submitterName}`
    );
    console.log("sendEmail response", response);
  } catch (error) {
    console.error(error);
  }
}

export { sendEmail };
