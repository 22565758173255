import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { FormFooter } from "../../../components/ui";
import { insertDataProtectionApi } from "@/modules/registration/api/data_protection";

const DataProtectionForm = ({ dataProtection, profile }) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(dataProtection?.is_signed);
  const [isSaving, setSaving] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const onBackClick = () => {
    navigate("/application/horeca/terms-and-conditions");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    async function submitDataProtection() {
      setSaving(true);
      await insertDataProtectionApi(profile?.id, true);
      setTimeout(() => {
        setSaving(false);
        navigate("/application/horeca/declaration");
      }, 800);
    }
    if (checked) {
      submitDataProtection();
    } else {
      toast.warning("Please verify the checkbox requirements.");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="terms">
        <label htmlFor="agree">
          <input
            type="checkbox"
            id="agree"
            checked={checked}
            onChange={handleChange}
          />
          I have read the data protection conditions and have fully understood.
        </label>
      </div>
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
};

export { DataProtectionForm };
