import React, { useState, useEffect } from "react";
import { toast } from "sonner";
import { LoadingIndicator, FoodPlaceholder, TransitionWrapper } from "../../ui";
import {
  getProductImageUrlApi,
  upsertOneProduct,
  getProductPictureUrlByProductCode,
} from "@/modules/core/api/products";
import { upsertImageToStorage } from "@/modules/core/api/storage";
import "./product-picture.css";

/**
 * A Picture with the option to upload
 *
 * @param {String} productCode A code used to represent the identity of a product
 * @param {Boolean} allowUpload A boolean value used to to indicate if a picture can be uploaded.
 * @returns A React Component
 */
const ProductPicture = ({
  productCode,
  allowUpload,
  width,
  height,
  quality,
}) => {
  // Check if picture exists.
  // If picture does not exist, show placeholder.
  // If picture does exist, show picture.

  // Check if picture exists
  // Retrieve picture using GET call from the Supabase Database API

  // Placeholder.
  // If placeholder exists, show upload file button.
  // If upload file button is clicked, open file browser and allow upload.
  // Once upload is completed, save picture as a storage item in Supabase Storage.
  // Once picture is saved as a storage item, retrieve the picture_url from the return value of the Supabase Storage API.
  // Use picture_url and the product id to save an entry into the table 'productCode_pictureUrl'
  // Once the entry is saved, refresh the component to display the new image.
  const [pictureUrl, setPictureUrl] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    getProductPicture(productCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode]);

  /**
   * Retrieves the picture URL from the product images table.
   * Then, uses the URL to download the image into the user's browser.
   * The local path is then used to display the image.
   *
   * @param {Integer} productCode The unique identifier for the product.
   */
  async function getProductPicture(productCode) {
    const getPictureUrl = async () => {
      const { data, error } =
        await getProductPictureUrlByProductCode(productCode);

      if (error) throw error;
      if (data?.length > 0) {
        await downloadImage(data[0].picture_url);
      }
    };
    try {
      setIsDownloading(true);
      await getPictureUrl();
    } catch (error) {
      console.log("Error retrieving row: ", error);
    } finally {
      setIsDownloading(false);
    }
  }

  /**
   * Downloads an image blob from Supabase Storage.
   * Sets the local image url that points to the blob to local state.
   *
   * @param {String} path The name of the image in the storage bucket.
   */
  async function downloadImage(path) {
    const url = await getProductImageUrlApi(path, width, height, quality);
    setPictureUrl(url);
  }

  /**
   * Upsert file and filename to Supabase Storage
   * Upsert product ID and filename combination to Supabase Database table
   *
   * @param {Object} event The browser event
   */
  async function uploadImage(event) {
    const getFileInfo = (event) => {
      const file = event.target.files[0];
      const fileName = file?.name;
      return [fileName, file];
    };
    const upsertProductToStorage = async (fileName, file) => {
      const { error } = await upsertImageToStorage(
        "product-images",
        fileName,
        file
      );
      if (error) {
        throw error;
      }
    };
    const upsertProductToDatabase = async (fileName) => {
      const columns = {
        product_code: productCode,
        picture_url: fileName,
        date_modified: new Date(),
      };
      const { error } = await upsertOneProduct(columns);
      if (error) {
        throw error;
      }
    };
    try {
      setIsUploading(true);
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }
      const [fileName, file] = getFileInfo(event);
      await upsertProductToStorage(fileName, file);
      await upsertProductToDatabase(fileName);
    } catch (error) {
      toast.info("Sorry, there was a problem with the upload.");
      console.error(error);
    } finally {
      setIsUploading(false);
      await getProductPicture(productCode);
    }
  }

  function DisplayItem() {
    if (isDownloading) {
      return <LoadingIndicator center />;
    } else if (isUploading) {
      return <LoadingIndicator center type="secondary" />;
    } else if (!pictureUrl) {
      return <FoodPlaceholder />;
    } else {
      return (
        <img className="product-picture" src={pictureUrl} alt={pictureUrl} />
      );
    }
  }

  return (
    <div
      className={`product-picture-with-upload ${allowUpload && "product-picture-hover"}`}
    >
      <DisplayItem />
      {allowUpload && (
        <TransitionWrapper>
          <div className="upload-controls">
            <label className="button primary block" htmlFor="single">
              {!isUploading && !isDownloading && (
                <div className="button-text">
                  <span>Upload Image</span>
                </div>
              )}
            </label>
            {allowUpload && (
              <input
                className="input"
                type="file"
                id="single"
                accept="image/*"
                onChange={uploadImage}
                disabled={isUploading || isDownloading}
              />
            )}
          </div>
        </TransitionWrapper>
      )}
    </div>
  );
};

export { ProductPicture };
