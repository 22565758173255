import { UserProfilePlaceholder } from "..";
import "./user-profile-placeholder-tiny.css";

const UserProfilePlaceholderTiny = () => {
  return (
    <div className="user-profile-placeholder-tiny-wrapper">
      <UserProfilePlaceholder />
    </div>
  );
};

export { UserProfilePlaceholderTiny };
