import { supabase } from "@/modules/supabase";

const getCartApi = async (user_id) => {
  const { data, error } = await supabase
    .from("cart")
    .select()
    .match({ user_id })
    .is("date_completed", null);
  console.log("cart", data);
  console.log("cart error", error);
  return data?.[0];
};

const insertCartApi = async (user_id) => {
  const { data, error } = await supabase
    .from("cart")
    .insert({ user_id })
    .select();
  console.log("insert cart", data);
  console.log("cart error", error);
  return data?.[0];
};

const getActiveCartApi = async (user_id) => {
  const cart = await getCartApi(user_id);
  console.log("active cart", cart);
  return cart;
};

const setCartApi = async (id, date_completed) => {
  const { data, error } = await supabase
    .from("cart")
    .update({ date_completed })
    .match({ id })
    .select();
  console.log("cart", data);
  console.log("cart error", error);
};

const getCartItemsApi = async (cartId) => {
  const { data, error } = await supabase
    .from("cart_items")
    .select()
    .eq("cart_id", cartId);
  console.log("cart items", data);
  console.log("cart items error", error);
  return data;
};

const getCartItemsViewApi = async (cartId) => {
  const { data, error } = await supabase
    .from("cart_items_view")
    .select()
    .eq("cart_id", cartId)
    .is("is_out_of_stock", false);
  console.log("cart items view", data);
  console.log("cart items view error", error);
  return data;
};

const setCartItemsApi = async (cart_id, product_id, quantity) => {
  const { data, error } = await supabase
    .from("cart_items")
    .upsert({ cart_id, product_id, quantity })
    .select();
  console.log("cart items", data);
  console.log("cart items error", error);
};

const deleteCartItemsApi = async (cart_id, product_id) => {
  const { data, error } = await supabase
    .from("cart_items")
    .delete()
    .match({ cart_id, product_id });
  console.log("cart items", data);
  console.log("cart items error", error);
};

export {
  getCartApi,
  getActiveCartApi,
  insertCartApi,
  setCartApi,
  getCartItemsApi,
  getCartItemsViewApi,
  setCartItemsApi,
  deleteCartItemsApi,
};
