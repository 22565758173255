import "./num-items-found-message.css";

const PositiveItemsMessage = ({ numItems }) => {
  return (
    <span>
      We found <b>{numItems}</b> items for you.
    </span>
  );
};

const NoItemsMessage = () => {
  return <span>Sorry, we couldn't find any items.</span>;
};

const getMessage = (numItems) => {
  if (numItems > 0) {
    return <PositiveItemsMessage numItems={numItems} />;
  }
  return <NoItemsMessage />;
};

const NumItemsFoundMessage = ({ numItems }) => {
  return <div className="num-items-found-message">{getMessage(numItems)}</div>;
};

export { NumItemsFoundMessage };
