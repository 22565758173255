const ApplicationStatusPendingPage = () => {
  return (
    <div>
      <blockquote>
        Your application is pending approval. You will be notified of your
        application status in the coming weeks. Thank you.
      </blockquote>
    </div>
  );
};

export { ApplicationStatusPendingPage };
