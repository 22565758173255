import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, LoadingIndicator } from "@/modules/core/components/ui";
import { getApplicationViewApi } from "@/modules/registration/api/application";
import { HORECADocument } from "./horeca-document";
import { RetailDocument } from "./retail-document";
import "./review-application-page.css";

function Content({ application, customerId, onFormSubmitCallback }) {
  switch (application?.category?.toLowerCase()) {
    case "horeca":
      return (
        <HORECADocument
          application={application}
          customerId={customerId}
          onFormSubmitCallback={onFormSubmitCallback}
        />
      );
    case "retail":
      return (
        <RetailDocument
          application={application}
          customerId={customerId}
          onFormSubmitCallback={onFormSubmitCallback}
        />
      );
    default:
      return "Unable to detect application type. Please consult an admin.";
  }
}
function ReviewApplicationDocument({
  application,
  customerId,
  onFormSubmitCallback,
}) {
  const breadcrumbs = [
    {
      pathname: "/admin/manage-applications",
      text: "Manage Applications",
      type: "link",
    },
    {
      text: `${application?.customer_email} #${application?.application_id}`,
      type: "text",
    },
  ];
  return (
    <div className="review-application-page">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Content
        application={application}
        customerId={customerId}
        onFormSubmitCallback={onFormSubmitCallback}
      />
    </div>
  );
}
const ReviewApplicationPage = () => {
  const [application, setApplication] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { customerId } = useParams();

  async function getApplication() {
    setLoading(true);
    const application = await getApplicationViewApi(customerId);
    setApplication(application);
    setLoading(false);
  }
  useEffect(() => {
    getApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <ReviewApplicationDocument
      application={application}
      customerId={customerId}
      onFormSubmitCallback={getApplication}
    />
  );
};

export { ReviewApplicationPage };
