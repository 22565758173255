import { useState, useEffect } from "react";
import { LoadingIndicator } from "@/modules/core/components/ui";
import { getBillingInformationApi } from "@/modules/registration/api/billing_information";
import { Field } from "../../../common";

const BillingInformation = ({ customerId }) => {
  const [billingInformation, setBillingInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getBillingInformation() {
      setLoading(true);
      const billingInformation = await getBillingInformationApi(customerId);
      setBillingInformation(billingInformation);
      setLoading(false);
    }
    getBillingInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <h2>Billing Information</h2>
      <h3>Main Contact</h3>
      <h4>Main contact for billing enquiries</h4>
      <Field label="Name" value={billingInformation?.billing_contact_1_name} />
      <Field
        label="Telephone No."
        value={billingInformation?.billing_contact_1_phone_number}
      />
      <Field
        label="Email"
        value={billingInformation?.billing_contact_1_email}
      />
      <h4>Main contact for payment enquiries</h4>
      <Field label="Name" value={billingInformation?.payment_contact_1_name} />
      <Field
        label="Telephone No."
        value={billingInformation?.payment_contact_1_phone_number}
      />
      <Field
        label="Email"
        value={billingInformation?.payment_contact_1_email}
      />
      <h3>Secondary Contact</h3>
      <h4>Secondary contact for billing enquiries</h4>
      <Field label="Name" value={billingInformation?.billing_contact_2_name} />
      <Field
        label="Telephone No."
        value={billingInformation?.billing_contact_2_phone_number}
      />
      <Field
        label="Email"
        value={billingInformation?.billing_contact_2_email}
      />
      <h4>Main contact for payment enquiries</h4>
      <Field label="Name" value={billingInformation?.payment_contact_2_name} />
      <Field
        label="Telephone No."
        value={billingInformation?.payment_contact_2_phone_number}
      />
      <Field
        label="Email"
        value={billingInformation?.payment_contact_2_email}
      />
      <h3>Billing and Payment Conditions</h3>
      <Field
        label="Billing and Payment Conditions"
        value={billingInformation?.billing_and_payment_conditions}
      />
    </section>
  );
};

export { BillingInformation };
