import { RetailInformationForm } from "./retail-information-form";
import { Blockquote, Divider, PageTitle } from "../../components/ui";
import { HomeIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const RetailInformationPage = ({ nextPage, profile }) => {
  return (
    <div className="registration-page">
      <div className="retail-information-page-header">
        <PageTitle>
          <HomeIcon />
          <h1>Retail Information</h1>
        </PageTitle>
        <Blockquote>
          <InformationCircleIcon />
          We require retail information to allow us to properly assess your
          eligibility. Once your credit rankings and other criteria has been
          assessed, we will send you an email accordingly.
        </Blockquote>
        <Divider />
        <RetailInformationForm nextPage={nextPage} profile={profile} />
      </div>
    </div>
  );
};

export { RetailInformationPage };
