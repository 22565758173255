import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { CreditCardIcon } from "@heroicons/react/24/solid";
import { UserIcon } from "@heroicons/react/24/solid";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import {
  Bubble,
  Divider,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { getBillingInformationApi } from "@/modules/registration/api/billing_information";
import { PrimaryBillingContact } from "./primary-billing-contact";
import { PrimaryPaymentContact } from "./primary-payment-contact";
import { SecondaryBillingContact } from "./secondary-billing-contact";
import { SecondaryPaymentContact } from "./secondary-payment-contact";

const BillingInformationSection = ({ customerId }) => {
  const [billingInformation, setBillingInformation] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getBillingInformation() {
      setLoading(true);
      const billingInformation = await getBillingInformationApi(customerId);
      setBillingInformation(billingInformation);
      setLoading(false);
    }
    getBillingInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <Title>
          <CreditCardIcon />
          <h2>Billing Information</h2>
        </Title>
        <Button href="/application/horeca/billing-information">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Title>
        <UserIcon />
        <h3>Primary Contact</h3>
      </Title>
      <Divider />
      <PrimaryBillingContact billingInformation={billingInformation} />
      <Divider />
      <PrimaryPaymentContact billingInformation={billingInformation} />
      <Divider />
      <Title>
        <UserIcon />
        <h3>Secondary Contact</h3>
      </Title>
      <Divider />
      <SecondaryBillingContact billingInformation={billingInformation} />
      <Divider />
      <SecondaryPaymentContact billingInformation={billingInformation} />
      <Divider />
      <Title>
        <CreditCardIcon />
        <h3>Billing and Payment Conditions</h3>
      </Title>
      <Divider />
      <Bubble>
        <FieldList>
          <ReviewField
            label="Billing and Payment Conditions"
            value={billingInformation?.billing_and_payment_conditions}
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { BillingInformationSection };
