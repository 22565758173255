import "./delivery-options.css";

const Option = ({ id, selected, icon, label, onClick }) => {
  let className = "option";
  if (selected) className += " selected";
  return (
    <div className={className} onClick={() => onClick(id)}>
      {icon}
      {label}
    </div>
  );
};

const DeliveryOptions = ({ isSelected, setIsSelected, options }) => {
  return (
    <div className="delivery-options">
      <div className="title">Delivery Option</div>
      <div className="options">
        {options?.map((option) => (
          <Option
            {...option}
            selected={isSelected === option?.id}
            onClick={(id) => setIsSelected(id)}
          />
        ))}
      </div>
    </div>
  );
};

export { DeliveryOptions };
