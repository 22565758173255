import "./banner.css";
const Banner = ({ message, buttonDisplayText, onButtonClick }) => {
  return (
    <div className="banner">
      <span className="message">{message}</span>
      <div className="button" onClick={onButtonClick}>
        {buttonDisplayText}
      </div>
    </div>
  );
};

export { Banner };
