import { ShippingAndFulfilmentPolicy } from "@/modules/img/public-pages";
import "../public-misc.css";

const ShippingAndFulfilmentPolicyPage = () => {
  return (
    <article className="page">
      <div className="page-header">
        <img
          src={ShippingAndFulfilmentPolicy}
          alt="Shipping and Fulfilment Policy"
        />
        <h1>Shipping & Fulfilment</h1>
      </div>
      <section>
        <h2>Shipping & Fulfilment</h2>
        <h3>Goods Delivery Policy</h3>
        <p>
          <i>
            Currently, LOTUSOXFORD offer products to the Klang Valley area only.
          </i>
        </p>
        <h4>Cut Off Time</h4>
        <p>
          The cut off time for ordering is{" "}
          <b>3pm Monday - Saturday (excluding public holidays)</b>.
          <br />
          Orders placed after the cut off time shall be processed the following
          working day. LOTUSOXFORD will have 2 working days from the processing
          day to deliver your order to you.
        </p>
        <h4>Delivery Charges</h4>
        <p>
          There may be delivery charges applied with your orders, subject to the
          Minimum Order Quantity (MOQ). Should your order quantity be below the
          MOQ, there will be an additional RM10 of delivery charges. After your
          order has been placed successfully and payment has been confirmed (if
          applicable), LOTUSOXFORD will process your order and attempt to
          deliver to you within 2 working days.
        </p>
        <h4>Exceptions</h4>
        <p>
          In the event that your order is placed on a public holiday which falls
          on a working day, your order will be processed the following business
          day. As such, LOTUSOXFORD will have 2 working days from the processing
          day to deliver your order to you.
        </p>
      </section>
    </article>
  );
};

export { ShippingAndFulfilmentPolicyPage };
