import { Field, PhoneNumberField } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import { UserIcon } from "@heroicons/react/24/solid";

const SecondaryBillingContactSection = ({
  billingInformation,
  setBillingInformation,
}) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <UserIcon />
            <h3>Secondary contact for billing enquiries</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="billing_contact_2_name"
            label="Name"
            onChangeFn={(e) =>
              setBillingInformation({
                ...billingInformation,
                billing_contact_2_name: e.target.value,
              })
            }
            input={billingInformation?.billing_contact_2_name}
            placeholder="Billing Contact 2"
          />
          <TwoColumn>
            <PhoneNumberField
              label="Telephone No."
              name="billing_contact_2_phone_number"
              onChange={(value) =>
                setBillingInformation({
                  ...billingInformation,
                  billing_contact_2_phone_number: value,
                })
              }
              value={billingInformation?.billing_contact_2_phone_number}
            />
            <Field
              name="billing_contact_2_email"
              label="Email"
              onChangeFn={(e) =>
                setBillingInformation({
                  ...billingInformation,
                  billing_contact_2_email: e.target.value,
                })
              }
              input={billingInformation?.billing_contact_2_email}
              placeholder="name2@gmail.com"
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { SecondaryBillingContactSection };
