import { Breadcrumbs } from "@/modules/core/components/ui";
import { ProductsInterface } from "@/modules/core/components/gadgets";
import "./market-page.css";

const MarketPage = ({ session, profile }) => {
  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      text: "Market",
      type: "text",
    },
  ];
  return (
    <div className="market-page">
      <div className="market-page-header">
        <div className="market-page-breadcrumbs">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
      <div className="market-page-body">
        <ProductsInterface profile={profile} />
      </div>
    </div>
  );
};

export { MarketPage };
