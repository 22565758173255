import { UncontrolledFieldGroup } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";

const BusinessInfoSection = ({
  businessRegistration,
  setBusinessRegistration,
}) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h3>Business Info</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <UncontrolledFieldGroup
            label="Business Name"
            formElement={
              <input
                name="business_name"
                onChange={(e) =>
                  setBusinessRegistration({
                    ...businessRegistration,
                    business_name: e.target.value,
                  })
                }
                value={businessRegistration?.business_name}
                placeholder="The Best Bakery Group"
                required
              />
            }
          />
          <TwoColumn>
            <UncontrolledFieldGroup
              label="Email Address"
              formElement={
                <input
                  type="email"
                  name="business_email"
                  onChange={(e) =>
                    setBusinessRegistration({
                      ...businessRegistration,
                      business_email: e.target.value,
                    })
                  }
                  value={businessRegistration?.business_email}
                  placeholder="thebestbakerygroup@gmail.com"
                  required
                />
              }
            />
            <UncontrolledFieldGroup
              label="Website"
              formElement={
                <input
                  type="text"
                  name="website"
                  onChange={(e) =>
                    setBusinessRegistration({
                      ...businessRegistration,
                      website: e.target.value,
                    })
                  }
                  value={businessRegistration?.website}
                  placeholder="thebestbakerygroup.com"
                />
              }
            />
          </TwoColumn>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { BusinessInfoSection };
