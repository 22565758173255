import {
  Bubble,
  BubbleHeader,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";

const ReviewBusinessTypeSection = ({ retailInformation }) => {
  return (
    <section className="type">
      <Bubble>
        <BubbleHeader>
          <Title>
            <BuildingOfficeIcon />
            <h4>Business Type</h4>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Nature of Business"
            value={retailInformation?.nature_of_business}
          />
          <ReviewField
            label="If 'Others', Please Specify"
            value={retailInformation?.specification}
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { ReviewBusinessTypeSection };
