import { FiTrash as DeleteIcon } from "react-icons/fi";

const DeleteButton = ({ id, email, deleteUser, onDeleteSuccess }) => {
  return (
    <div
      className="action"
      onClick={(event) => deleteUser(event, id, email, onDeleteSuccess)}
    >
      <DeleteIcon />
      <span>Delete</span>
    </div>
  );
};

export { DeleteButton };
