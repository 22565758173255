const cleanUsers = (users) =>
  users.map((user) => ({
    ...user,
    status: user.status || "Pending Submission",
    role: user.role || "Customer",
    category: user.category || "N/A",
  }));

const sortUsers = (users) =>
  users.sort((a, b) => new Date(b.date_submitted) - new Date(a.date_submitted));

export { cleanUsers, sortUsers };
