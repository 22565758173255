import { supabase } from "@/modules/supabase";

const getCurrentSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  console.log("get supabase_auth_session", data);
  if (error) console.log("supabase_auth_session error", error);
  return data?.session;
};

const resetPasswordForEmail = async (email) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: process.env.REACT_APP_CLIENT_URL + "profile/update-password",
  });
  return { data, error };
};

const signInWithPassword = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  return { data, error };
};

const signUpWithEmailAndPassword = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
  });
  return { data, error };
};

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  return { error };
};

const updateCurrentUser = async (password) => {
  const { data, error } = await supabase.auth.updateUser({ password });
  return { data, error };
};

export {
  getCurrentSession,
  resetPasswordForEmail,
  signInWithPassword,
  signUpWithEmailAndPassword,
  signOut,
  updateCurrentUser,
};
