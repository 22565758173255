import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Field,
  ApplicationLoadingIndicator,
} from "@/modules/core/components/ui";
import {
  Bubble,
  BubbleHeader,
  FieldList,
  FormFooter,
  Title,
} from "../../../components/ui";
import { insertDeclarationApi } from "@/modules/registration/api/declaration";
import config from "../config.json";
import { PencilIcon } from "@heroicons/react/24/solid";

const DeclarationForm = ({ declaration, setDeclaration, profile }) => {
  const navigate = useNavigate();
  const [isSaving, setSaving] = useState(false);
  const onBackClick = () => {
    navigate("/application/horeca/data-protection");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let map = {};
    config?.keys.forEach((e) => (map[e] = event.target[e].value));
    map["user_id"] = profile?.id;
    console.log("map", map);
    async function insertDeclaration(map) {
      setSaving(true);
      await insertDeclarationApi(map);
      setTimeout(() => {
        setSaving(false);
        navigate("/application/horeca/review");
      }, 800);
    }
    insertDeclaration(map);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Bubble>
        <BubbleHeader>
          <Title>
            <PencilIcon />
            <h3>Signee Details</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="name"
            label="Name"
            onChangeFn={(e) =>
              setDeclaration({ ...declaration, name: e.target.value })
            }
            input={declaration?.name}
            placeholder="Your Name"
            required
          />
          <Field
            name="position"
            label="Position"
            onChangeFn={(e) =>
              setDeclaration({ ...declaration, position: e.target.value })
            }
            input={declaration?.position}
            placeholder="Your Position"
            required
          />
          <Field
            name="date"
            label="Date"
            onChangeFn={(e) =>
              setDeclaration({ ...declaration, date: e.target.value })
            }
            input={declaration?.date}
            placeholder={new Date().toLocaleDateString()}
            required
          />
        </FieldList>
      </Bubble>
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
};

export { DeclarationForm };
