function RadioList({ title, radios, filterState, filterTrigger }) {
  function handleChange(e) {
    console.log("trigger", e.target.value);
    filterTrigger(e.target.value);
  }
  return (
    <div className="filter-list">
      <h3 className="filter-list-title">{title}</h3>
      <div className="filter-list-radios">
        {radios?.map((radio) => (
          <div className="filter-list-radio" key={radio.id}>
            <input
              type="radio"
              name={radio.name}
              id={radio.id}
              value={radio.value}
              onChange={handleChange}
              checked={filterState === radio.value}
            />
            <label htmlFor={radio.id}>{radio.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export { RadioList };
