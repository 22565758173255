import { supabase } from "@/modules/supabase";

/**
 * Returns a list of favorite products for a user.
 * Response format: [{ user_id, product_code }]
 * @param {UUID} user_id A uuid representing a user's identity.
 * @returns
 */
async function getAllUserProductFavoritesApi(user_id) {
  const { data, error } = await supabase
    .from("user_product_favorites")
    .select()
    .match({ user_id });
  console.log("getAllUserProductFavoritesApi", data);
  console.log("getAllUserProductFavoritesApi error", error);
  return data;
}

async function getUserProductFavoriteForAProductApi(user_id, product_code) {
  const { data, error } = await supabase
    .from("user_product_favorites")
    .select()
    .match({ user_id, product_code });
  console.log("getUserProductFavoriteForAProductApi", data);
  console.log("getUserProductFavoriteForAProductApi error", error);
  return data;
}
/**
 * Creates a new row in the table for a user's favorite products.
 * @param {UUID} user_id A uuid representing a user's identity
 * @param {String}} product_code A string representing a product's identity
 */
async function insertUserProductFavoriteApi(user_id, product_code) {
  const { data, error } = await supabase
    .from("user_product_favorites")
    .insert({ user_id, product_code })
    .select();
  console.log("insertUserProductFavoriteApi", data);
  console.log("insertUserProductFavoriteApi error", error);
  return data?.[0];
}

/**
 * Deletes a row from the table for a user's favorite products.
 * @param {UUID} user_id A uuid representing a user's identity
 * @param {String} product_code A string representing a product's identity
 */
async function deleteUserProductFavoriteApi(user_id, product_code) {
  const { error } = await supabase
    .from("user_product_favorites")
    .delete()
    .match({ user_id, product_code });
  console.log("deleteUserProductFavoriteApi error", error);
}

export {
  getAllUserProductFavoritesApi,
  getUserProductFavoriteForAProductApi,
  insertUserProductFavoriteApi,
  deleteUserProductFavoriteApi,
};
