import { clsx } from "clsx";
import styles from "./address.module.css";

const Address = ({
  name,
  address_line_1,
  address_line_2,
  postalCode,
  city,
  state,
  country,
  icon,
  bgColor,
}) => {
  return (
    <div className={clsx(styles["address"], bgColor && styles[bgColor])}>
      <div className={styles["header"]}>
        {icon}
        <div className={styles["name"]}>{name}</div>
      </div>
      <div className={styles["body"]}>
        <div className={styles["address-line-1"]}>{address_line_1}</div>
        <div>{address_line_2}</div>
        <div>{postalCode}</div>
        <div>
          {city}, {state}
        </div>
        <div>{country}</div>
      </div>
    </div>
  );
};

export { Address };
