import { useState, useEffect } from "react";
import { SearchBar } from "./search-bar";
import { MainPanel } from "./main-panel";
import { SidePanel } from "./side-panel";
import {
  getProductImageUrlApi,
  getAllProductsApi,
  getAllProductsWithPriceTagApi,
  getAllProductStockGroups,
} from "@/modules/core/api/products";
import { getAllUserProductFavoritesApi } from "@/modules/core/api/user_product_favorites";
import {
  sort,
  filter,
  initializeCategoryFilterElements,
  initializeStockGroupState,
  formatPriceTag,
  getSmallestUOMProducts,
} from "./helpers";
import config from "./config.json";
import "./products-interface.css";

function ProductsInterface({ profile, managementMode }) {
  const { MODES, priceFilterElements } = config;
  const { SORT_MODE, CATEGORY_MODES } = MODES;
  const [sortMode, setSortMode] = useState(SORT_MODE.RECOMMENDED);
  const [categoryMode, setCategoryMode] = useState(
    Object.values(CATEGORY_MODES).map((category) => ({
      name: category,
      value: false,
    }))
  );
  const [isLoadingFilters, setLoadingFilters] = useState(false);
  const [categoryFilterElements, setCategoryFilterElements] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hasLoadedAllImages, setHasLoadedAllImages] = useState(false);

  useEffect(() => {
    /**
     * Retrieves a list of product stock groups from the database.
     */
    async function getFilters() {
      setLoadingFilters(true);
      const stockgroups = await getAllProductStockGroups();
      setCategoryFilterElements(initializeCategoryFilterElements(stockgroups));
      setCategoryMode(initializeStockGroupState(stockgroups));
      setLoadingFilters(false);
    }
    getFilters();
  }, []);

  let filters = [
    {
      type: "radio",
      title: "Sort By",
      elements: [
        {
          name: "sort_by",
          id: "alphabetical-a-to-z",
          value: "alphabetical a to z",
          label: "A - Z",
        },
        {
          name: "sort_by",
          id: "alphabetical-z-to-a",
          value: "alphabetical z to a",
          label: "Z - A",
        },
        ...(managementMode === "admin" ? [] : priceFilterElements),
        {
          name: "sort_by",
          id: "recommended",
          value: "recommended",
          label: "Recommended",
        },
      ],
      filterState: sortMode,
      filterTrigger: setSortMode,
    },
    {
      type: "checkbox",
      title: "Category",
      elements: categoryFilterElements,
      filterState: categoryMode,
      filterTrigger: setCategoryMode,
    },
  ];

  useEffect(() => {
    const loadProducts = async () => {
      async function assignFavorites(items) {
        const favorites = await getAllUserProductFavoritesApi(profile?.id);
        favorites.forEach((favorite) => {
          const item = items.find(
            (item) => item?.CODE === favorite?.product_code
          );
          if (item) {
            item.is_favorite = item ? true : false;
          }
        });
        return items;
      }
      async function loadPicture(items) {
        items.forEach(async (item, idx) => {
          const url = await getProductImageUrlApi(
            item?.picture_url,
            264, // product_image_dimension * 1.5 ~= 176 * 1.5 = 264
            264,
            80
          );
          item.img = url;
          if (idx === items.length - 1) setHasLoadedAllImages(true);
        });
        return items;
      }
      async function fetch() {
        if (managementMode === "admin") {
          return await getAllProductsApi();
        } else {
          const products = await getAllProductsWithPriceTagApi(
            formatPriceTag(profile?.price_tag)
          );
          return getSmallestUOMProducts(products);
        }
      }
      setLoading(true);
      const items = await fetch();
      const itemsWithPictures = await loadPicture(items);
      const itemsWithFavorites = await assignFavorites(itemsWithPictures);
      setProducts(itemsWithFavorites);
      setCurrentProducts(itemsWithFavorites);
      setTimeout(() => {
        setLoading(false);
      }, 1200);
    };
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const items = [...products];
    const filteredItems = filter(items, categoryMode, searchFilter);
    const filteredAndSortedItems = sort(filteredItems, sortMode);
    setCurrentProducts(filteredAndSortedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryMode, sortMode, searchFilter, hasLoadedAllImages]);

  return (
    <div className="products-interface-wrapper">
      <SearchBar input={searchFilter} setInput={setSearchFilter} />
      <div className="products-interface">
        {isLoadingFilters ? null : (
          <>
            <SidePanel filters={filters} />
            <MainPanel
              products={currentProducts}
              isLoading={isLoading}
              managementMode={managementMode}
            />
          </>
        )}
      </div>
    </div>
  );
}

export { ProductsInterface };
