import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { OrdersList } from "../../../customer/pages/orders-page";
import {
  Breadcrumbs,
  LoadingIndicator,
  UserProfilePlaceholderLarge,
} from "@/modules/core/components/ui";
import { getOneProfileById } from "@/modules/core/api/users";
import { getOrderHistoryApi } from "@/modules/core/api/order";
import "./user-detail-page.css";

// eslint-disable-next-line no-unused-vars
function OrderHistory({ userId }) {
  const [orderHistory, setOrderHistory] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getOrderHistory() {
      setLoading(true);
      const orderHistory = await getOrderHistoryApi(userId);
      setOrderHistory(orderHistory);
      setLoading(false);
    }
    getOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="order-history">
      {isLoading ? (
        <LoadingIndicator center />
      ) : (
        <div>{JSON.stringify(orderHistory, null, 2)}</div>
      )}
    </div>
  );
}
function Field({ label, value }) {
  return (
    <div className="field">
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
}
function UserInfo({ userId }) {
  const [user, setUser] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getUser() {
      setLoading(true);
      const user = await getOneProfileById(userId);
      setUser(user);
      setLoading(false);
    }
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <LoadingIndicator center />
  ) : (
    <div className="user-info">
      <section className="user-image-section">
        <UserProfilePlaceholderLarge />
      </section>
      <Field label="First Name" value={user?.first_name || "N/A"} />
      <Field label="Last Name" value={user?.last_name || "N/A"} />
      <Field label="Email" value={user?.email || "N/A"} />
      <div>Phone: </div>
      <div>Address: </div>
      <hr />
      <div>
        <Field label="Application Category" value={user?.category || "N/A"} />
        <Field label="Application Status" value={user?.status || "N/A"} />
        <Field
          label="Action"
          value={
            <Link to={`/manage-applications/${userId}`}>
              Review Application
            </Link>
          }
        />
      </div>
    </div>
  );
}
const UserDetailPage = () => {
  const { userId } = useParams();

  const breadcrumbs = [
    {
      pathname: "/dashboard",
      text: "Admin Dashboard",
      type: "link",
    },
    {
      pathname: "/manage-users",
      text: "Manage Users",
      type: "link",
    },
    {
      text: userId,
      type: "text",
    },
  ];
  return (
    <div className="user-detail-page">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <section className="profile">
        <UserInfo userId={userId} />
      </section>
      <hr />
      <section>
        <h2>Statement of Accounts</h2>
        <div>
          <Link to="/statement-of-accounts">View Statement of Accounts</Link>
        </div>
        <div>
          <Link to="/invoices">View Invoices</Link>
        </div>
      </section>
      {/* <OrderHistory userId={userId} /> */}
    </div>
  );
};

export { UserDetailPage };
