import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiTrash as DeleteIcon } from "react-icons/fi";
import {
  TransitionWrapper,
  LoadingIndicator,
  FoodPlaceholder,
  EmptyCartMessage,
} from "../../../components/ui";
import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import { getProductImageUrlApi } from "@/modules/core/api/products";
import "./checkout-list.css";

const CheckoutDescription = ({ item }) => {
  return (
    <div className="checkout-list-item-description">
      <span>Qty: {item?.quantity}</span>
      <span className="per-quantity-value">
        {formatPriceWithCurrency(item?.STOCKVALUE)} each
      </span>
    </div>
  );
};

const CartDescription = ({
  item,
  onIncrementClick,
  onDecrementClick,
  onRemoveClick,
}) => {
  return (
    <div className="checkout-list-item-description">
      <div className="checkout-list-item-description-counter">
        <span>
          <button
            className="counter-button"
            onClick={(e) => {
              e.preventDefault();
              onDecrementClick(item.product_id);
            }}
          >
            -
          </button>
          <span className="quantity">
            <span>{item?.quantity}</span>
          </span>
          <button
            className="counter-button"
            onClick={(e) => {
              e.preventDefault();
              onIncrementClick(item?.product_id);
            }}
          >
            +
          </button>
        </span>
        <span
          className="remove-button"
          onClick={(e) => {
            e.preventDefault();
            onRemoveClick(item?.product_id);
          }}
        >
          <DeleteIcon /> Remove
        </span>
      </div>
      <span className="per-quantity-value">
        {formatPriceWithCurrency(item?.STOCKVALUE)} each
      </span>
    </div>
  );
};

const CheckoutListItemDescription = ({
  item,
  onIncrementClick,
  onDecrementClick,
  onRemoveClick,
  type,
}) => {
  if (type === "cart") {
    return (
      <CartDescription
        item={item}
        onIncrementClick={onIncrementClick}
        onDecrementClick={onDecrementClick}
        onRemoveClick={onRemoveClick}
      />
    );
  } else if (type === "checkout") {
    return <CheckoutDescription item={item} />;
  } else {
    return null;
  }
};

function CheckoutListItem({
  item,
  onIncrementClick,
  onDecrementClick,
  onRemoveClick,
  type,
}) {
  const [pictureUrl, setPictureUrl] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPicture() {
      setLoading(true);
      // product_image_dimension * 1.5 ~= 96 * 1.5 = 144
      const url = await getProductImageUrlApi(item?.picture_url, 144, 144, 80);
      setPictureUrl(url);
      setLoading(false);
    }
    loadPicture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link className="checkout-list-item" to={`/market/${item?.CODE}`}>
      <div className="checkout-list-item-picture">
        {isLoading ? (
          <LoadingIndicator center />
        ) : pictureUrl ? (
          <img src={pictureUrl} alt={item?.DESCRIPTION} />
        ) : (
          <FoodPlaceholder size="small" />
        )}
      </div>
      <div className="checkout-list-item-body">
        <div className="checkout-list-item-title">
          <span>
            {item?.DESCRIPTION} ({item?.UOM})
          </span>
          <span className="total-price">
            {formatPriceWithCurrency(item?.STOCKVALUE * item?.quantity)}
          </span>
        </div>
        <CheckoutListItemDescription
          item={item}
          onIncrementClick={onIncrementClick}
          onDecrementClick={onDecrementClick}
          onRemoveClick={onRemoveClick}
          type={type}
        />
      </div>
    </Link>
  );
}

function CheckoutList({
  cartId,
  cartItems,
  incrementCartItem,
  decrementCartItem,
  deleteCartItem,
  reloadCart,
  type,
}) {
  const onIncrementClick = (productId) => {
    cartItems.map(async (item) => {
      if (Number(item?.product_id) === Number(productId)) {
        if (item.quantity < 999) item.quantity += 1;
        await incrementCartItem(cartId, productId, item.quantity);
        reloadCart();
      }
      return item;
    });
  };
  const onDecrementClick = (productId) => {
    cartItems.map(async (item) => {
      if (Number(item?.product_id) === Number(productId)) {
        if (item.quantity > 1) item.quantity -= 1;
        await decrementCartItem(cartId, productId, item.quantity);
        reloadCart();
      }
      return item;
    });
  };
  const onRemoveClick = async (productId) => {
    await deleteCartItem(cartId, productId);
    reloadCart();
  };

  return (
    <ul className="checkout-list">
      {cartItems?.length > 0 ? (
        cartItems.map((item, idx) => (
          <li key={item.product_id}>
            <TransitionWrapper index={idx}>
              <CheckoutListItem
                item={item}
                onIncrementClick={onIncrementClick}
                onDecrementClick={onDecrementClick}
                onRemoveClick={onRemoveClick}
                type={type}
              />
              {idx !== cartItems.length - 1 && <hr />}
            </TransitionWrapper>
          </li>
        ))
      ) : (
        <EmptyCartMessage />
      )}
    </ul>
  );
}
export { CheckoutList };
