import { Link } from "react-router-dom";
import { Blueberry } from "@/modules/img/auth-pages";
import "./hero-section.css";

const Poster = () => {
  return (
    <div className="poster">
      <img src={Blueberry} alt="cover" />
      <div className="overlay">
        <div className="text-block">
          <h2>Lotus</h2>
          <p>delivering quality products near you</p>
        </div>
        <div className="action-buttons">
          <Link className="button" to="/signup">
            Sign up
          </Link>
          <Link className="button tertiary-btn" to="/contact">
            Contact sales
          </Link>
        </div>
      </div>
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="hero" id="hero">
      <Poster />
    </section>
  );
};

export { HeroSection };
