import { clsx } from "clsx";
import PhoneInput from "react-phone-number-input";
import "./field-group.css";
import "react-phone-number-input/style.css";

function Field({
  type,
  name,
  label,
  onChangeFn,
  input,
  placeholder,
  required,
  disabled,
}) {
  return (
    <div className="field">
      <label className={clsx(required && "required-field")}>{label}</label>
      <input
        type={type}
        name={name}
        onChange={onChangeFn}
        value={input}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}

function ControlledFieldGroup({
  title,
  type,
  label,
  onChangeFn,
  input,
  placeholder,
}) {
  return (
    <div className="field-group">
      <h3>{title}</h3>
      <Field
        type={type}
        label={label}
        onChangeFn={onChangeFn}
        input={input}
        placeholder={placeholder}
      />
    </div>
  );
}

function UncontrolledFieldGroup({ title, label, formElement }) {
  return (
    <div className="field-group">
      <h3>{title}</h3>
      <div className="field">
        <label
          className={clsx(formElement?.props?.required && "required-field")}
        >
          {label}
        </label>
        {formElement}
      </div>
    </div>
  );
}

function PhoneNumberField({ label, name, onChange, value, required }) {
  return (
    <div className="field-group">
      <div className="field">
        <label className={clsx(required && "required-field")}>{label}</label>
        <PhoneInput
          name={name}
          onChange={onChange}
          value={value}
          required={required}
          defaultCountry="MY"
          limitMaxLength
          international
          countryCallingCodeEditable={false}
        />
      </div>
    </div>
  );
}

export {
  Field,
  ControlledFieldGroup,
  UncontrolledFieldGroup,
  PhoneNumberField,
};
