import { Outlet } from "react-router-dom";
import NavigationBar from "./navigation-bar";
import "./layout.css";

const PAGES = [
  {
    id: 0,
    href: "/application/introduction",
    text: "Introduction",
  },
  {
    id: 1,
    href: "/application/retail/retail-information",
    text: "Retail Information",
  },
  {
    id: 2,
    href: "/application/retail/review",
    text: "Review",
  },
];

const RetailRegistrationLayout = () => {
  return (
    <div className="application-layout-body">
      <NavigationBar pages={PAGES} />
      <Outlet />
    </div>
  );
};

export { RetailRegistrationLayout };
