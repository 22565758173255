import { useState, useEffect } from "react";
import { Button } from "@/modules/core/components/ui";
import { supabase } from "@/modules/supabase";
import { createSignedUrl } from "@/modules/core/api/storage";
import { CloudArrowDownIcon } from "@heroicons/react/24/solid";
import styles from "./business-form.module.css";

const BusinessForm = ({ userId, storageBucketName, tableName }) => {
  const [form, setForm] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isGettingSignedUrl, setIsGettingSignedUrl] = useState(false);

  useEffect(() => {
    getForm(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieves the picture URL from the product images table.
   * Then, uses the URL to download the image into the user's browser.
   * The local path is then used to display the image.
   *
   * @param {Integer} id The unique identifier for the product.
   */
  async function getForm(id) {
    const getFormUrl = async () => {
      const { data, error } = await supabase
        .from(tableName)
        .select()
        .eq("user_id", userId);

      if (error) throw error;
      if (data?.length > 0) {
        setForm(data[0]);
      }
    };
    try {
      setLoading(true);
      await getFormUrl();
    } catch (error) {
      console.log("Error retrieving row: ", error);
    } finally {
      setLoading(false);
    }
  }

  async function downloadForm(formName, storageBucketName) {
    try {
      setIsGettingSignedUrl(true);
      const { data, error } = await createSignedUrl(
        storageBucketName,
        formName
      );
      console.log("downloadForm success", data);
      console.log("downloadForm error", error);
      window.open(data.signedUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGettingSignedUrl(false);
    }
  }
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return isLoading ? (
    "..."
  ) : (
    <div className={styles["business-form"]}>
      {form ? (
        <span>
          <b>File Name:</b> {form?.form_url.split("_")[1]}
        </span>
      ) : null}
      {form ? (
        <div>
          <b>Submitted:</b>{" "}
          <span>
            {new Date(form?.modified_at).toLocaleString("en-US", options)}
          </span>
        </div>
      ) : null}
      <div>
        {form ? (
          <Button
            onClick={
              isGettingSignedUrl
                ? null
                : () => downloadForm(form?.form_url, storageBucketName)
            }
          >
            <CloudArrowDownIcon />
            {isGettingSignedUrl ? "Downloading..." : <>Download</>}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export { BusinessForm };
