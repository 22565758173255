import { ShoppingCartIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { TransitionWrapper } from "../";
import "./empty-cart-message.css";

const EmptyCartMessage = () => {
  return (
    <TransitionWrapper>
      <div className="empty-cart-message">
        <div className="empty-cart-content">
          <div className="cart-icon">
            <ShoppingCartIcon />
          </div>
          <p>Empty cart? Let's fill it up!</p>
        </div>
        <Link to="/market">Start adding favorites now</Link>
      </div>
    </TransitionWrapper>
  );
};

export { EmptyCartMessage };
