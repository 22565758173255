import { FilterPanel } from "./filter-panel";
import "./side-panel.css";

function SidePanel({ filters }) {
  return (
    <div className="market-side-panel">
      <h2>Market</h2>
      <FilterPanel filters={filters} />
    </div>
  );
}

export { SidePanel };
