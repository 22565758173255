import { Link } from "react-router-dom";
import { Product as ProductItem } from "@/modules/core/components/gadgets";
import { Tomato } from "@/modules/img/public-pages";
import {
  BananaFritters,
  CocktailKaripap,
  Murtabak,
  RotiBoom,
  Samosa,
  BananaSpringRoll,
} from "@/modules/img/food/snacks";
import "./catalogue-section.css";

const Product = ({ img, title, description }) => {
  return (
    <Link to="/catalogue">
      <ProductItem img={img} title={title} description={description} />
    </Link>
  );
};

const CatalogueSection = () => {
  return (
    <section className="catalogue-section" id="catalogue">
      <div
        className="catalogue-header"
        style={{ backgroundImage: `url(${Tomato})` }}
      >
        <div className="text-layer">
          <h2>Catalogue</h2>
          <p>Elevate every meal from ordinary to extraordinary.</p>
        </div>
      </div>
      <div className="catalogue-body">
        <div className="food-list">
          <Product title="Banana Spring Roll" img={BananaSpringRoll} />
          <Product title="Cocktail Karipap" img={CocktailKaripap} />
          <Product title="Samosa" img={Samosa} />
          <Product title="Banana Fritters" img={BananaFritters} />
          <Product title="Murtabak" img={Murtabak} />
          <Product title="Roti Boom" img={RotiBoom} />
        </div>
        <Link className="button" to="/catalogue">
          Explore Food
        </Link>
      </div>
    </section>
  );
};

export { CatalogueSection };
