import { Field } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { TwoColumn } from "../../../components/layouts";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";

const BusinessAddressSection = ({
  businessRegistration,
  setBusinessRegistration,
}) => {
  return (
    <Bubble>
      <BubbleHeader>
        <Title>
          <BuildingOfficeIcon />
          <h3>Business Address</h3>
        </Title>
      </BubbleHeader>
      <FieldList>
        <Field
          name="address_line_1"
          label="Address Line 1"
          onChangeFn={(e) =>
            setBusinessRegistration({
              ...businessRegistration,
              address_line_1: e.target.value,
            })
          }
          input={businessRegistration?.address_line_1}
          placeholder="No. 44, Jalan Desa Melur 4/1"
          required
        />
        <Field
          name="address_line_2"
          label="Address Line 2"
          onChangeFn={(e) =>
            setBusinessRegistration({
              ...businessRegistration,
              address_line_2: e.target.value,
            })
          }
          input={businessRegistration?.address_line_2}
          placeholder="Taman Bandar Connaught"
        />
        <TwoColumn>
          <Field
            name="postal_code"
            label="Postal Code"
            onChangeFn={(e) =>
              setBusinessRegistration({
                ...businessRegistration,
                postal_code: e.target.value,
              })
            }
            input={businessRegistration?.postal_code}
            placeholder="56000"
            required
          />
          <Field
            name="city"
            label="City"
            onChangeFn={(e) =>
              setBusinessRegistration({
                ...businessRegistration,
                city: e.target.value,
              })
            }
            input={businessRegistration?.city}
            placeholder="Cheras"
            required
          />
        </TwoColumn>
        <TwoColumn>
          <Field
            name="state"
            label="State"
            onChangeFn={(e) =>
              setBusinessRegistration({
                ...businessRegistration,
                state: e.target.value,
              })
            }
            input={businessRegistration?.state}
            placeholder="Kuala Lumpur"
            required
          />
          <Field
            name="country"
            label="Country"
            onChangeFn={(e) =>
              setBusinessRegistration({
                ...businessRegistration,
                country: e.target.value,
              })
            }
            input={businessRegistration?.country}
            placeholder="Malaysia"
            required
          />
        </TwoColumn>
      </FieldList>
    </Bubble>
  );
};

export { BusinessAddressSection };
