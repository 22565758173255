import { supabase } from "@/modules/supabase";

const getHORECAStaffFormApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_horeca_staff_form")
    .select()
    .match({ user_id });
  console.log("get forms_horeca_staff_form api", data);
  console.log("get forms_horeca_staff_form api error", error);
  return data?.[0];
};

const insertHORECAStaffFormApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_horeca_staff_form")
    .upsert(keys)
    .select();
  console.log("insert forms_horeca_staff_form api", data);
  console.log("insert forms_horeca_staff_form api error", error);
  return data?.[0];
};

const getSQLAccountingMasterCustomerList = async () => {
  const { data, error } = await supabase.from("Customer_Master").select();
  console.log("getSQLAccountingMasterCustomerList api", data);
  console.log("getSQLAccountingMasterCustomerList api error", error);
  return data;
};

const getSQLAccountingTerms = async () => {
  const { data, error } = await supabase.from("Terms").select();
  console.log("getSQLAccountingTerms api", data);
  console.log("getSQLAccountingTerms api error", error);
  return data;
};

const getSQLAccountingAgents = async () => {
  const { data, error } = await supabase.from("Agents").select();
  console.log("getSQLAccountingAgents api", data);
  console.log("getSQLAccountingAgents api error", error);
  return data;
};

const getSQLAccountingPriceTags = async () => {
  const { data, error } = await supabase.from("Price_Tags").select();
  console.log("getSQLAccountingPriceTags api", data);
  console.log("getSQLAccountingPriceTags api error", error);
  return data;
};

export {
  getHORECAStaffFormApi,
  insertHORECAStaffFormApi,
  getSQLAccountingMasterCustomerList,
  getSQLAccountingTerms,
  getSQLAccountingAgents,
  getSQLAccountingPriceTags,
};
