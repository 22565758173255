import { formatDate } from "../../../helpers";

import "./index.css";

const Date = ({ date_submitted }) => {
  return (
    <div className="date">
      {date_submitted ? `${formatDate(date_submitted)}` : "N/A"}
    </div>
  );
};

export { Date };
