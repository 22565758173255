import { LoadingIndicator } from "..";
import styles from "./application-loading-indicator.module.css";

const ApplicationLoadingIndicator = () => {
  return (
    <div className={styles["application-loading-indicator"]}>
      <LoadingIndicator offTimeout type="auth" />
      <span>Saving your data...</span>
    </div>
  );
};

export { ApplicationLoadingIndicator };
