import { useState, useEffect, useRef } from "react";
import { getHashKey, getHashValue } from "@/modules/core/api/ghl_v2.9t";
import {
  insertPaymentPayNowApi,
  insertPaymentCreditApi,
} from "@/modules/core/api/payment";

export const FORM_VALUES = {
  Password: process.env.REACT_APP_PASSWORD,
  ServiceID: process.env.REACT_APP_SERVICE_ID,
  MerchantReturnURL: process.env.REACT_APP_CLIENT_URL + "checkout",
  MerchantCallbackURL: process.env.REACT_APP_SERVER_URL + "callback",
  MerchantApprovalURL: process.env.REACT_APP_SERVER_URL,
  MerchantUnApprovalURL: process.env.REACT_APP_SERVER_URL + "unapproval",
  CurrencyCode: "MYR",
};

const CREDIT_CALLBACK_URL = "approval-credit";
const PAYNOW_CALLBACK_URL = "approval-paynow";

/**
 * Dynamically creates a form by extracting necessary details.
 * Once the form has been populated, the form is automatically submitted
 * which causes the user to be redirected to e-GHL's payment portal.
 * @returns HTML Form Element
 */
function EGHLForm({
  Password,
  ServiceID,
  PaymentID,
  MerchantReturnURL,
  MerchantCallbackURL,
  MerchantApprovalURL,
  MerchantUnApprovalURL,
  Amount,
  CurrencyCode,
  TransactionType,
  PymtMethod,
  OrderNumber,
  PaymentDesc,
  Param6,
  Param7,
  CustName,
  CustEmail,
  CustPhone,
  cart,
  session,
  paymentType,
  orders,
  savedAddressId,
}) {
  const [formFields, setFormFields] = useState({});
  const formRef = useRef();
  useEffect(() => {
    async function getData(cart, session) {
      async function createPaymentPayNow(session, cart) {
        const payment = await insertPaymentPayNowApi(
          session?.user?.id,
          cart?.id
        );
        return `PAYTEST` + `${payment?.number}`.padStart(5, "0");
      }
      async function createPaymentCredit(session, orders) {
        const payment = await insertPaymentCreditApi(session?.user?.id, orders);
        return `PAYTEST` + `${payment?.number}`.padStart(5, "0");
      }
      function getCreditForm(payment_id, hash_value) {
        const formFields = {
          ServiceID,
          PaymentID: payment_id,
          MerchantReturnURL,
          MerchantCallbackURL,
          MerchantApprovalURL: MerchantApprovalURL + CREDIT_CALLBACK_URL,
          MerchantUnApprovalURL,
          Amount,
          CurrencyCode: "MYR",
          HashValue: hash_value,
          TransactionType: "SALE",
          OrderNumber: payment_id,
          PaymentDesc:
            PaymentDesc || `CREDIT/USER${session?.user?.id}/${orders}`,
          Param6: Param6 || orders,
          Param7:
            Param7 ||
            ["SHIP", savedAddressId, "|", "USER", session?.user?.id].join(""),
        };
        return formFields;
      }
      function getPayNowForm(payment_id, hash_value) {
        const formFields = {
          ServiceID,
          PaymentID: payment_id,
          MerchantReturnURL,
          MerchantCallbackURL,
          MerchantApprovalURL: MerchantApprovalURL + PAYNOW_CALLBACK_URL,
          MerchantUnApprovalURL,
          Amount,
          CurrencyCode: "MYR",
          HashValue: hash_value,
          TransactionType: "SALE",
          OrderNumber: payment_id,
          PaymentDesc:
            PaymentDesc || `PAYNOW/USER${session?.user?.id}/CART${cart?.id}`,
          Param6: Param6 || "CART" + cart?.id,
          Param7:
            Param7 ||
            ["SHIP", savedAddressId, "|", "USER", session?.user?.id].join(""),
        };
        return formFields;
      }
      let formFields;
      if (paymentType === "credit") {
        const payment_id = await createPaymentCredit(session, orders);
        console.log("Created payment: Payment ID #", payment_id);
        const hash_value = await getHashValue(
          getHashKey({
            Password,
            ServiceID,
            PaymentID: payment_id,
            MerchantReturnURL,
            MerchantCallbackURL,
            MerchantApprovalURL: MerchantApprovalURL + CREDIT_CALLBACK_URL,
            MerchantUnApprovalURL,
            Amount,
            CurrencyCode,
          })
        );
        formFields = getCreditForm(payment_id, hash_value);
      } else if (paymentType === "pay-now") {
        const payment_id = await createPaymentPayNow(session, cart);
        console.log("Created payment: Payment ID #", payment_id);
        const hash_value = await getHashValue(
          getHashKey({
            Password,
            ServiceID,
            PaymentID: payment_id,
            MerchantReturnURL,
            MerchantCallbackURL,
            MerchantApprovalURL: MerchantApprovalURL + PAYNOW_CALLBACK_URL,
            MerchantUnApprovalURL,
            Amount,
            CurrencyCode,
          })
        );
        formFields = getPayNowForm(payment_id, hash_value);
      } else {
        console.error("No payment type found.");
      }
      console.log("Created form fields: ", JSON.stringify(formFields));
      setFormFields(formFields);
    }
    getData(cart, session);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (Object.keys(formFields).length > 0) {
      formRef.current.submit();
    }
  }, [formFields]);
  return (
    <form
      name="frmPayment"
      ref={formRef}
      method="post"
      action="https://pay.e-ghl.com/IPGSG/Payment.aspx"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Object.keys(formFields).map((key) => (
        <input type="hidden" key={key} name={key} value={formFields[key]} />
      ))}
      <button
        className="payment-button primary-btn loading-btn"
        style={{ gap: 8 }}
        type="submit"
        disabled
      >
        <div className="payment-button-title">Processing...</div>
        <div className="payment-button-description">
          Hold on, e-GHL will be with you soon.
        </div>
      </button>
    </form>
  );
}

export { EGHLForm };
