import {
  UncontrolledFieldGroup,
  PhoneNumberField,
} from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { TwoColumn } from "../../../components/layouts";

const ContactInformationSection = ({
  businessRegistration,
  setBusinessRegistration,
}) => {
  return (
    <Bubble>
      <BubbleHeader>
        <Title>
          <PhoneIcon />
          <h3>Contact Information</h3>
        </Title>
      </BubbleHeader>
      <FieldList>
        <UncontrolledFieldGroup
          label="Name of M.D or G.M"
          formElement={
            <input
              type="text"
              name="name_of_md_or_gm"
              onChange={(e) =>
                setBusinessRegistration({
                  ...businessRegistration,
                  name_of_md_or_gm: e.target.value,
                })
              }
              value={businessRegistration?.name_of_md_or_gm}
              placeholder="Managing Director or General Manager"
              required
            />
          }
        />
        <UncontrolledFieldGroup
          label="Name of Purchasing Manager"
          formElement={
            <input
              type="text"
              name="name_of_purchasing_manager"
              onChange={(e) =>
                setBusinessRegistration({
                  ...businessRegistration,
                  name_of_purchasing_manager: e.target.value,
                })
              }
              value={businessRegistration?.name_of_purchasing_manager}
              placeholder="Purchasing Manager"
              required
            />
          }
        />
        <UncontrolledFieldGroup
          label="Email Address"
          formElement={
            <input
              type="email"
              name="email_of_md_or_gm"
              onChange={(e) =>
                setBusinessRegistration({
                  ...businessRegistration,
                  email_of_md_or_gm: e.target.value,
                })
              }
              value={businessRegistration?.email_of_md_or_gm}
              placeholder="contact@gmail.com"
              required
            />
          }
        />
        <TwoColumn>
          <PhoneNumberField
            label="Fax Number"
            name="fax_of_md_or_gm"
            onChange={(value) =>
              setBusinessRegistration({
                ...businessRegistration,
                fax_of_md_or_gm: value,
              })
            }
            value={businessRegistration?.fax_of_md_or_gm}
            required
          />
          <PhoneNumberField
            label="Telephone Number"
            name="phone_number_of_md_or_gm"
            onChange={(value) =>
              setBusinessRegistration({
                ...businessRegistration,
                phone_number_of_md_or_gm: value,
              })
            }
            value={businessRegistration?.phone_number_of_md_or_gm}
            required
          />
        </TwoColumn>
      </FieldList>
    </Bubble>
  );
};

export { ContactInformationSection };
