import { toast } from "sonner";

/* Returns true if all fields were entered appropriately.
   Otherwise, show pop warning the user to enter all fields. */
const verifyFields = (keys, staffForm) => {
  let missingField = false;
  keys.forEach((e) => {
    if (!staffForm?.[e]) {
      missingField = true;
      return;
    }
  });
  if (missingField) {
    toast.info("Please fill out all fields.");
    return false;
  } else {
    return true;
  }
};

/* Returns true if remark field was filled appropriately.
   Otherwise, warn user to add a remark. */
const verifyRemarkField = (staffForm) => {
  if (!staffForm?.remark) {
    toast.info("Please leave a remark as to why applicant was rejected.");
    return false;
  } else {
    return true;
  }
};

/* Returns a map of data for each column of the staff form table based on the applicant's status. */
const createMap = (
  status,
  keys,
  staffForm,
  customerId,
  getRejectedMapValues
) => {
  let map = {};
  if (status === "approved") keys.forEach((e) => (map[e] = staffForm?.[e]));
  else if (status === "rejected" || status === "pending-review")
    keys.forEach((e) => (map[e] = getRejectedMapValues(e, staffForm)));
  map["user_id"] = customerId;
  console.log("map", map);
  console.log("status", status);
  return map;
};

/* Returns the appropriate placeholder text for a field depending on the applicant's status. */
const getPlaceholder = (status, text) => {
  return status === "rejected" ? "Application has been rejected." : text;
};

/* Returns the appropriate placeholder text for the remark field depending on the applicant's status. */
const getRemarkPlaceholder = (status, staffForm, text) => {
  return status === "rejected" ? staffForm?.remark : text;
};

export {
  verifyFields,
  verifyRemarkField,
  createMap,
  getPlaceholder,
  getRemarkPlaceholder,
};
