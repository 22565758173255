import { Link } from "react-router-dom";
import { FiX as CloseIcon } from "react-icons/fi";
import { signOut } from "@/modules/core/api/auth";
import "./drop-down-menu.css";

function DropDownMenu({ profile, isOpen, setOpen, menuRef, initializeAuth }) {
  const handleLogOutClick = async () => {
    setOpen(false);
    const { error } = await signOut();
    console.log("sign out error", error);
    await initializeAuth({ byPassTimeout: true });
    if (error) console.error(error);
  };
  return (
    <nav
      className="drop-down-menu"
      style={isOpen ? { width: "30vw" } : { width: 0, minWidth: 0 }}
      ref={menuRef}
    >
      <div className="close-button" onClick={() => setOpen(false)}>
        <CloseIcon className="close-button-icon" />
      </div>
      <ul className="menu">
        {profile?.status === "approved" && !profile?.isAdmin && (
          <>
            <li>
              <Link to="/profile" onClick={() => setOpen(false)}>
                <span>Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/orders" onClick={() => setOpen(false)}>
                <span>Orders</span>
              </Link>
            </li>
            <li>
              <Link to="/addresses" onClick={() => setOpen(false)}>
                <span>Addresses</span>
              </Link>
            </li>
            <li>
              <Link to="/statement-of-accounts" onClick={() => setOpen(false)}>
                <span>Statement of Accounts</span>
              </Link>
            </li>
          </>
        )}
        {profile?.status === "approved" && profile?.isAdmin && (
          <>
            <li>
              <Link to="/admin/dashboard" onClick={() => setOpen(false)}>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/admin/manage-applications"
                onClick={() => setOpen(false)}
              >
                <span>Applications</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/manage-products" onClick={() => setOpen(false)}>
                <span>Products</span>
              </Link>
            </li>
          </>
        )}
        <li onClick={handleLogOutClick}>
          <Link to="/login">
            <span>Log out</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export { DropDownMenu };
