import { Link } from "react-router-dom";
import { Nature } from "@/modules/img/customer-pages";
import CATEGORIES from "./config.json";
import "./categories-page.css";

const CategoriesPage = () => {
  function List({ alphabet, categories }) {
    return (
      <div className="categories-list-alphabet">
        <h3>{alphabet}</h3>
        <div className="categories-list-alphabet-items">
          {categories?.map((category) => (
            <span key={category}>
              <Link to={`/categories/${category}`}>{category}</Link>
            </span>
          ))}
        </div>
      </div>
    );
  }
  return (
    <section className="categories-page">
      <div className="categories-page-header">
        <img src={Nature} alt="Nature" />
        <h1>Categories</h1>
      </div>
      <div className="categories-page-body">
        <div className="categories-list">
          {Object.keys(CATEGORIES).map((e) => (
            <List key={e} alphabet={e} categories={CATEGORIES[e]} />
          ))}
        </div>
      </div>
    </section>
  );
};

export { CategoriesPage };
