import { UserProfilePlaceholderTiny } from "@/modules/core/components/ui";
import { InfoList } from "../info-list";

import "./index.css";

const UserCell = ({ email }) => {
  return (
    <div className="user-cell">
      <div className="user-cell-photo">
        <UserProfilePlaceholderTiny />
      </div>
      <InfoList primaryText="John Doe" secondaryText={email} />
    </div>
  );
};

export { UserCell };
