import { PrivacyPolicy } from "@/modules/img/public-pages";
import "../public-misc.css";

const PrivacyPolicyPage = () => {
  return (
    <article className="page">
      <div className="page-header">
        <img src={PrivacyPolicy} alt="Privacy Policy" />
        <h1>Privacy Policy</h1>
      </div>
      <section>
        <h2>Privacy Policy</h2>
        <p>
          Thank you for visiting us online at https://lotusoxford.com/
          (“Website”). If you continue to browse and use the Website, you are
          deemed to have agreed to this Privacy Policy.
        </p>
        <p>
          The website is operated by Lotus Consulting Sdn. Bhd. (“LOTUSOXFORD”).
          LOTUSOXFORD respects visitors to the Website and treats your Personal
          Data seriously. This Privacy Policy serves to guide you on how
          LOTUSOXFORD collects, process, use, disclose, transfer and store your
          Personal Data. Please peruse this Privacy Policy carefully and
          understand your rights on the matter.
        </p>
        <p>
          The terms “we”, “us” and “our” shall refer to Lotus Oxford. The term
          "Lotus Oxford Companies" shall mean reference to LOTUSOXFORD and its
          affiliated and related companies. The term “you” refers to the
          customer visiting the Website and/or order through us via the Website
          or through our customer service agents.
        </p>
        <p>
          Unless stated otherwise, this Privacy Policy applies to all
          information that we have about you and your account. For the purpose
          of this Privacy Policy, the reference to Personal Data includes
          “personally identifiable information.” If you choose to visit the
          Website, your visit and any dispute over privacy is subject to this
          Privacy Policy and the Terms of Use on the Website.
        </p>
        <h3>Collection and Use of Personal Data</h3>
        <p>
          Pursuant to the Personal Data Protection Act 2010, this Privacy Policy
          outlines the rights, interests, obligations and duties of all parties
          involved in the collection, recording, holding, storing or processing,
          disclosure of information that we may collect about you through the
          Website or sub-domains of personal information and data as may be
          protected by the Act.
        </p>
        <p>
          We will not collect any Personal Data about you unless you voluntarily
          choose to provide us with it (e.g. by registering yourself as a
          LOTUSOXFORD member, accessing the Website, taking a survey,
          communication with our Customer Service etc.), provide your explicit
          consent, or unless otherwise permitted by applicable laws and
          regulations for the protection of your Personal Data.
        </p>
        <h3>Personal Data that we may collect</h3>
        <p>
          LOTUSOXFORD may collect the personal information from you when you
          visit the Website, registers, places an order online, contacts
          LOTUSOXFORD or participates in LOTUSOXFORD promotions or
          questionnaires, including but not limiting to the following:{" "}
        </p>
        <ul>
          <li>Company name;</li>
          <li>Company address;</li>
          <li>Name;</li>
          <li>Gender;</li>
          <li>Identity card number;</li>
          <li>Outlet name;</li>
          <li>Postal address;</li>
          <li>Telephone number;</li>
          <li>Email address;</li>
          <li>Credit card or bank account information; and</li>
          <li>
            Other personal data relevant to registration and/or provisioning of
            your account, surveys, promotions and/or other purposes.
          </li>
        </ul>
        <p>
          In all cases, the details requested via the Website are necessary to
          provide an optimal service to you. In cases where you do not provide
          the requested details, we cannot guarantee that the information and
          services provided will fully satisfy your needs.
        </p>
        <h3>How are we using the Personal Data?</h3>
        <p>
          Information collected will be used to improve the content of the
          Website by incorporating information that is relevant to you, to
          notify you our updates, to contact you for marketing and sales
          purposes that are related specifically to your needs and interests and
          to communicate with our partners and/or agents. This information is
          not shared with other organizations for any reason, commercial or
          business, without your prior explicit consent.
        </p>
        <p>
          By accessing the Website and/or clicking the corresponding box, you
          expressly, freely and unequivocally accept that your details will be
          treated/used by us for the following purposes:
        </p>
        <ul>
          <li>Improve the shopping experience.</li>
          <li>
            For marketing and promotional activities, market surveys, trend and
            statistical analysis
          </li>
          <li>
            Process your online orders, including the sending of e-mails and/or
            text messages to inform you of delivery status.
          </li>
          <li>Process order returns and refunds.</li>
          <li>Manage your account.</li>
          <li>
            Contact you if there is any problem with any of your orders or for
            other logistical reasons.
          </li>
          <li>Respond to your queries.</li>
          <li>
            Send you commercial information by e-mail to inform you of our new
            products, new store locations, special offers and news relating to
            the Website and/or LOTUSOXFORD Companies in general.
          </li>
          <li>
            Meeting any legal or regulatory requirements relating to our
            provision of services and products and to make disclosure under the
            requirements of any applicable law, regulation, direction, court
            order, by-law, guideline, circular, code applicable to LOTUSOXFORD
            Companies.
          </li>
          <li>For the maintenance and upkeep of customer records.</li>
          <li>
            For credit assessments, financial and background investigation as
            and when deemed necessary.
          </li>
          <li>
            To administer participation in any contest/program related to the
            public organized by us.
          </li>
          <li>
            For security and crime prevention purposes, risks management,
            safeguarding LOTUSOXFORD Companies in the event of any claim,
            litigation, suits etc.
          </li>
          <li>
            For handling the report for lost item, customer complaints and
            taking appropriate action relating thereto.
          </li>
        </ul>
      </section>
    </article>
  );
};

export { PrivacyPolicyPage };
