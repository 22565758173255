import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, LoadingIndicator } from "@/modules/core/components/ui";
import { getStatementOfAccountsByMonthApi } from "@/modules/core/api/statement_of_accounts";
import formatPriceWithCurrency from "@/modules/core/utils/formatPriceWithCurrency";
import { getMonthFromNumber } from "@/modules/core/utils/time";
import "./statement-of-account-page.css";

function StatementOfAccount({ statementOfAccount }) {
  function getTotalDue() {
    return statementOfAccount?.reduce(
      (acc, curr) =>
        (acc += curr?.payment_status === "pending-payment" ? curr.sum : 0),
      0
    );
  }
  function getTotalReceived() {
    return statementOfAccount?.reduce(
      (acc, curr) => (acc += curr?.payment_status === "paid" ? curr.sum : 0),
      0
    );
  }
  return (
    <div>
      <h2>Statement of Accounts for XYZ Company</h2>
      <div className="payments-due">
        <div>Payments Due:</div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Invoice #</th>
                <th>Date</th>
                <th>Description</th>
                <th>Amount Due</th>
              </tr>
            </thead>
            <tbody>
              {statementOfAccount
                ?.filter?.((e) => e?.payment_status === "pending-payment")
                ?.map((e) => (
                  <tr key={`amount-due-${e.invoice_number}`}>
                    <td>
                      <Link to={`/orders/${e.number}`}>{e.invoice_number}</Link>
                    </td>
                    <td>
                      {new Date(e.date_created).toLocaleString("en-UK", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      })}
                    </td>
                    <td>{e.invoice_number}</td>
                    <td>{formatPriceWithCurrency(e.sum)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div>Total Due: {formatPriceWithCurrency(getTotalDue())}</div>
        </div>
      </div>
      <hr />
      <div className="payments-received">
        <div>Payments Received:</div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Invoice #</th>
                <td>Date</td>
                <td>Description</td>
                <td>Amount Received</td>
              </tr>
            </thead>
            <tbody>
              {statementOfAccount
                ?.filter?.((e) => e?.payment_status === "paid")
                ?.map((e) => (
                  <tr key={`amount-received-${e.invoice_number}`}>
                    <td>
                      <Link to={`/orders/${e.number}`}>{e.invoice_number}</Link>
                    </td>
                    <td>
                      {new Date(e.date_created).toLocaleString("en-UK", {
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      })}
                    </td>
                    <td>{e.invoice_number}</td>
                    <td>{formatPriceWithCurrency(e.sum)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div>
            Total Received: {formatPriceWithCurrency(getTotalReceived())}
          </div>
        </div>
        <hr />
        <div className="summary">
          <div>
            Balance Due:{" "}
            {formatPriceWithCurrency(
              Math.abs(getTotalDue() - getTotalReceived())
            )}
          </div>
          <p>
            Please let us know if you have any questions regarding this
            statement of accounts or if you need further assistance. Thank you
            for your business.
          </p>
        </div>
      </div>
    </div>
  );
}
const StatementOfAccountPage = ({ session }) => {
  const [statementOfAccount, setStatementOfAccount] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const { statementOfAccountId } = useParams();
  const [month_issued, year_issued] = statementOfAccountId?.split("-");

  useEffect(() => {
    async function getStatementOfAccount() {
      setLoading(true);
      const statementOfAccount = await getStatementOfAccountsByMonthApi(
        session?.user?.id,
        month_issued,
        year_issued
      );
      setStatementOfAccount(statementOfAccount);
      setLoading(false);
    }
    getStatementOfAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    {
      pathname: "/home",
      text: "Home",
      type: "link",
    },
    {
      pathname: "/statement-of-accounts",
      text: "Statement of Accounts",
      type: "link",
    },
    {
      text: `${getMonthFromNumber(month_issued)} ${year_issued}`,
      type: "text",
    },
  ];

  return (
    <div className="statement-of-account-page">
      <h1>Statement of Account</h1>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {isLoading ? (
        <LoadingIndicator center />
      ) : (
        <StatementOfAccount statementOfAccount={statementOfAccount} />
      )}
    </div>
  );
};

export { StatementOfAccountPage };
