import { Link } from "react-router-dom";
import "./contact-us-section.css";

const ContactUsSection = () => {
  return (
    <section className="contact-us-section" id="contact-us">
      <div className="contact-us-details">
        <h2 className="title">Unlock Culinary Delights</h2>
        <p className="paragraph">
          Sign up today to unlock a world of culinary wonders! Join us on a
          journey where every click leads to a delicious discovery. Your taste
          adventure awaits - sign up now!
        </p>
        <div className="action-buttons">
          <Link className="button" to="/signup">
            Sign up
          </Link>
          <Link className="button tertiary-btn" to="/contact">
            Contact sales
          </Link>
        </div>
      </div>
    </section>
  );
};

export { ContactUsSection };
