import { supabase } from "@/modules/supabase";

const getBillingInformationApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_billing_information")
    .select()
    .match({ user_id });
  console.log("get forms_billing_information api", data);
  console.log("get forms_billing_information api error", error);
  return data?.[0];
};

const insertBillingInformationApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_billing_information")
    .upsert(keys)
    .select();
  console.log("insert forms_billing_information api", data);
  console.log("insert forms_billing_information api error", error);
  return data?.[0];
};

export { getBillingInformationApi, insertBillingInformationApi };
