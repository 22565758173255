import { Divider } from "../../../components/ui";
import { UploadComponent } from "../upload-component";

const UploadList = ({ session }) => {
  return (
    <div className="upload-list">
      <UploadComponent
        title="Form 9"
        id="form-9"
        userId={session?.user?.id}
        storageBucketName="form-9"
        tableName="forms_form9"
      />
      <Divider />
      <UploadComponent
        title="Form 24"
        id="form-24"
        userId={session?.user?.id}
        storageBucketName="form-24"
        tableName="forms_form24"
      />
      <Divider />
      <UploadComponent
        title="Form 49"
        id="form-49"
        userId={session?.user?.id}
        storageBucketName="form-49"
        tableName="forms_form49"
      />
    </div>
  );
};

export { UploadList };
