import styles from "./overlay.module.css";

const Overlay = ({ children, onClick }) => {
  return (
    <div className={styles["overlay"]} onClick={onClick}>
      {children}
    </div>
  );
};

export { Overlay };
