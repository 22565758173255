const formatDateTime = (date) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: "true",
  };
  return new Date(date).toLocaleString("en-UK", options);
};

const formatDate = (date) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleString("en-UK", options);
};


function getMonthFromNumber(month) {
  if (isNaN(month) || month < 1 || month > 12) return "N/A";
  const date = new Date();
  date.setMonth(month - 1);
  return date.toLocaleString([], { month: "long" });
}

export { formatDateTime, getMonthFromNumber, formatDate};
