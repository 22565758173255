import {
  Bubble,
  BubbleHeader,
  FieldList,
  ReviewField,
  Title,
} from "../../../components/ui";
import { PhoneIcon } from "@heroicons/react/24/solid";

const ReviewContactInfoSection = ({ retailInformation }) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <PhoneIcon />
            <h4>Contact Details</h4>
          </Title>
        </BubbleHeader>
        <FieldList>
          <ReviewField
            label="Person in Charge"
            value={retailInformation?.person_in_charge}
          />
          <ReviewField
            label="Phone Number"
            value={retailInformation?.phone_number}
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { ReviewContactInfoSection };
