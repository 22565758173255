// eslint-disable-next-line no-unused-vars
const url = "https://pay.e-ghl.com/IPGSG/Payment.aspx";
async function getAPI({
  Password,
  ServiceID,
  PaymentID,
  MerchantReturnURL,
  MerchantCallbackURL,
  Amount,
  CurrencyCode,
  TransactionType,
  PymtMethod,
  OrderNumber,
  PaymentDesc,
  CustName,
  CustEmail,
  CustPhone,
}) {
  const hash_value = await getHashValue(
    getHashKey({
      Password,
      ServiceID,
      PaymentID,
      MerchantReturnURL,
      MerchantCallbackURL,
      Amount,
      CurrencyCode,
    })
  );
  const searchParams = {
    ServiceID,
    PaymentID,
    MerchantReturnURL,
    MerchantCallbackURL,
    Amount,
    CurrencyCode,
    HashValue: hash_value,
    TransactionType: "SALE",
    PymtMethod: "CC",
    OrderNumber: "123",
    PaymentDesc: "Coffee",
    CustName: "Adam Smith",
    CustEmail: "code.marktoh@gmail.com",
    CustPhone: "+60126213707",
  };
  const response = await fetch(
    "https://pay.e-ghl.com/IPGSG/Payment.aspx?" +
      new URLSearchParams(searchParams)
  );
  console.log("response", response);
  const data = await response.text();
  console.log("data", data);
  return data;
}

/**
 * Hash Key = Password + ServiceID + PaymentID + MerchantReturnURL + MerchantApprovalURL + MerchantUnApprovalURL + MerchantCallBackURL + Amount + CurrencyCode + CustIP + PageTimeout + CardNo + Token + RecurringCriteria
 *
 * If Token value is provided in Token field, Token value to be put in the above
 * Hash Key string needs to be in clear format and not in encoded format. For example, if Token value is “2r8j/OsME hxI99PZcHwVg==”, then Token value to be put in Hash Key is “2r8j/OsME hxI99PZcHwVg==” instead of “2r8j%2FOsME%20hxI99PZcHwVg%3D%3D”.
 * If CardNo / Token / MerchantApprovalURL / MerchantUnApprovalURL / MerchantCallBackURL are not provided in Payment request, then these fields in the above Hash Key should be just left empty.
 *
 * Hash Key Example abc123S22PAYTEST123https://www.shop.com/success.asp12.34MYR113.210.6.1509004444333322221111
 * Hash Value (SHA256) 28010d7207bdbd6e8ae3890fdb56c541c552c10b609b978fd69428fbb7a4fbc2
 */
function getHashKey({
  Password = "",
  ServiceID = "",
  PaymentID = "",
  MerchantReturnURL = "",
  MerchantApprovalURL = "",
  MerchantUnApprovalURL = "",
  MerchantCallbackURL = "",
  Amount = "",
  CurrencyCode = "",
  CustIP = "",
  PageTimeout = "",
  CardNo = "",
  Token = "",
  RecurringCriteria = "",
}) {
  const hashKey =
    Password +
    ServiceID +
    PaymentID +
    MerchantReturnURL +
    MerchantApprovalURL +
    MerchantUnApprovalURL +
    MerchantCallbackURL +
    Amount +
    CurrencyCode +
    CustIP +
    PageTimeout +
    CardNo +
    Token +
    RecurringCriteria;
  console.log("hash_key", hashKey);
  return hashKey;
}

/**
 * Given a key, return a hash after using a SHA-256 algorithm.
 * @param {*} message
 * @returns
 */
async function getHashValue(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  console.log("hash_hex", hashHex);
  return hashHex;
}

export { getAPI, getHashKey, getHashValue };
