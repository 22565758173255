import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ApplicationLoadingIndicator } from "@/modules/core/components/ui";
import { FormFooter } from "../../../components/ui";
import { insertTermsAndConditionsApi } from "@/modules/registration/api/terms_and_conditions";

const TermsAndConditionsForm = ({ termsAndConditions, profile }) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(termsAndConditions?.is_signed);
  const [isSaving, setSaving] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const onBackClick = () => {
    navigate("/application/horeca/outlet-information");
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    async function submitTermsAndConditions() {
      setSaving(true);
      await insertTermsAndConditionsApi(profile?.id, true);
      setTimeout(() => {
        setSaving(false);
        navigate("/application/horeca/data-protection");
      }, 800);
    }
    if (checked) {
      submitTermsAndConditions();
    } else {
      toast.warning("Please verify the checkbox requirements.");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="terms">
        <label htmlFor="agree">
          <input
            id="agree"
            type="checkbox"
            checked={checked}
            onChange={handleChange}
          />
          I have read the terms and conditions and have fully understood.
        </label>
      </div>
      <FormFooter onBackClick={onBackClick} />
      {isSaving && <ApplicationLoadingIndicator />}
    </form>
  );
};

export { TermsAndConditionsForm };
