import { supabase } from "@/modules/supabase";

const getBusinessRegistrationApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_business_registration_information")
    .select()
    .match({ user_id });
  console.log("get forms_business_registration_information api", data);
  console.log("get forms_business_registration_information api error", error);
  return data?.[0];
};

const insertBusinessRegistrationApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_business_registration_information")
    .upsert(keys)
    .select();
  console.log("insert forms_business_registration_information api", data);
  console.log(
    "insert forms_business_registration_information api error",
    error
  );
  return { data: data?.[0], error: error };
};

export { getBusinessRegistrationApi, insertBusinessRegistrationApi };
