import { useState, useEffect } from "react";
import { FiEdit3 as EditIcon } from "react-icons/fi";
import { Button, LoadingIndicator } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import { getTermsAndConditionsApi } from "@/modules/registration/api/terms_and_conditions";
import { formatDateTime } from "@/modules/core/utils/time";

const TermsAndConditionsSection = ({ customerId }) => {
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function getTermsAndConditions() {
      setLoading(true);
      const termsAndConditions = await getTermsAndConditionsApi(customerId);
      setTermsAndConditions(termsAndConditions);
      setLoading(false);
    }
    getTermsAndConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <section>
      <div className="heading">
        <Title>
          <BuildingLibraryIcon />
          <h2>Terms and Conditions</h2>
        </Title>
        <Button href="/application/horeca/terms-and-conditions">
          <EditIcon />
          Edit
        </Button>
      </div>
      <Bubble>
        <BubbleHeader>
          <Title>
            <PencilIcon />
            <h3>Signee Details</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <p style={{ paddingLeft: 12 }}>
            {termsAndConditions?.is_signed
              ? `Signed on ${formatDateTime(termsAndConditions?.date_signed)}`
              : "Not Signed"}
          </p>
        </FieldList>
      </Bubble>
    </section>
  );
};

export { TermsAndConditionsSection };
