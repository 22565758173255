import { Field, PhoneNumberField } from "@/modules/core/components/ui";
import { Bubble, BubbleHeader, FieldList, Title } from "../../../components/ui";
import { PhoneIcon } from "@heroicons/react/24/solid";

const ContactInfoSection = ({ retailInformation, setRetailInformation }) => {
  return (
    <section>
      <Bubble>
        <BubbleHeader>
          <Title>
            <PhoneIcon />
            <h3>Contact Info</h3>
          </Title>
        </BubbleHeader>
        <FieldList>
          <Field
            name="person_in_charge"
            label="Person in Charge"
            onChangeFn={(e) =>
              setRetailInformation({
                ...retailInformation,
                person_in_charge: e.target.value,
              })
            }
            input={retailInformation?.person_in_charge}
            placeholder="Adam Smith"
            required
          />
          <PhoneNumberField
            label="Mobile Number"
            name="phone_number"
            onChange={(value) =>
              setRetailInformation({
                ...retailInformation,
                phone_number: value,
              })
            }
            value={retailInformation?.phone_number}
            required
          />
        </FieldList>
      </Bubble>
    </section>
  );
};

export { ContactInfoSection };
