import { TermsOfUse } from "@/modules/img/public-pages";
import "../public-misc.css";

const TermsOfUsePage = () => {
  return (
    <article className="page">
      <div className="page-header">
        <img src={TermsOfUse} alt="Terms of Use" />
        <h1>Terms of Use</h1>
      </div>
      <section>
        <h2>Terms of Use</h2>
        <p>
          The website located at LOTUSOXFORD.com (the "Site") is copyrighted
          work belonging to Lotus Consulting Sdn. Bhd. ("Company", "us", "our",
          and "we"). The company markets, sells, stores and delivers frozen food
          items to customers via its own fleet of refrigerated trucks. Certain
          features of the Services or Site may be subject to additional
          guidelines, terms, conditions or rules, which will be posted on the
          Service or Site in connection with such features. All such additional
          terms, guidelines, and rules are incorporated by reference into this
          Agreement.
        </p>
        <p>
          These Terms of Use ("Agreement") sets forth the legally binding terms
          for your use of the Site and Services. By accessing or using the Site
          or Services, you are accepting this Agreement (on behalf of yourself
          or the entity that you represent) and you represent and warrant that
          you have the right, authority, and capacity to enter into this
          Agreement (on behalf of yourself or the entity that you represent).
          You may not access or use the Site or Services or accept the Agreement
          if you are not at least 18 years old. If you do not agree with all of
          the provisions of this Agreement, do not access and/or use the Site or
          Services.
        </p>
        <h3>Accounts</h3>
        <ul>
          <li>
            <b>Account Creation.</b> In order to use certain features of the
            Site (e.g., to use the Services), you must register for an account
            with Lotus Consulting Group Sdn. Bhd. ("Company Account"). You
            represent and warrant that: (a) all required registration
            information you submit is complete, truthful and accurate; (b) you
            will maintain the accuracy of such information as such may change
            from time to time.
          </li>
          <li>
            <b>Account Responsibilities.</b> You are responsible for maintaining
            the confidentiality of your Account login information and are fully
            responsible for all activities that occur under your Account. You
            agree to immediately notify Lotus Consulting Group Sdn. Bhd. of any
            unauthorized use, or suspected unauthorized use of your Account or
            any other breach of security. Lotus Consulting Group Sdn. Bhd.
            cannot and will not be liable for any unauthorized use of your
            Account or loss or damage arising from your failure to comply with
            the above requirements.
          </li>
        </ul>
        <h3>Payment Terms</h3>
        <h4>Ordering Services</h4>
        <p>
          You may order any of the Products offered following the directions on
          the Site. LOTUSOXFORD may change the pricing for any portion Products
          (from time to time in its sole discretion) by updating the Site and
          without any additional notice to you.
        </p>
        <h4>Payment Terms</h4>
        <p>
          If you order Products, you agree to pay the then-current applicable
          Service fee or price per product and any applicable shipping charges
          all as listed on the Site. You hereby authorize LOTUSOXFORD to bill
          your credit card as described above. Fees are not inclusive of all
          taxes, levies, or duties imposed by taxing authorities. If there are
          taxes applicable, we will impose it at checkout. If any fee cannot be
          charged to your credit card for any reason, LOTUSOXFORD may provide,
          via email, notice of such non-payment and a link for you to update
          your payment information. If such non-payment is not remedied within
          five (5) days after receiving such notice of non-payment, then we may
          immediately terminate the applicable Product.
        </p>
        <h4>Site</h4>
        <p>
          License: Subject to the terms of this Agreement, LOTUSOXFORD grants
          you and your personnel a limited, non-transferable, non-exclusive,
          revocable, license to use the Site and Services.
        </p>
      </section>
    </article>
  );
};

export { TermsOfUsePage };
